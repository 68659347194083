import React, { Component } from 'react';
import { observer } from 'mobx-react';
import jrQrcode from 'jr-qrcode';
import { RouteComponentProps, Link } from 'react-router-dom';
import ShareStore from '../../stores/shareStore';
import DoctorInfoService from '@/services/doctorInfoService';
import Loader from '../../components/loader';
import html2canvas from 'html2canvas';
import styles from './index.module.css';
import downIcon from '../../static/images/down.png';

import shareTitleImg from './img/share-title.png';
import shareCardImg1 from './img/share-card1.png';
import shareCardImg2 from './img/share-card2.png';
import shareAuthImg from './img/share-auth.png';
import shareDiamondImg from './img/share-diamond.png';
import shareHonor from './img/share-honor.png';
import shareBook from './img/share-book.png';
import shareOImg from './img/share-o.png';
import shareBottomImg from './img/share-bottom.png';
import shareCodeImg from './img/share-code.png';
import { ShareDoctor } from '@/services/doctorInfoService';
import { Modal, Toast } from 'antd-mobile';

interface State {
  loadFlag: string;
  doctor: ShareDoctor;
  poster: string;
}
interface RouteParams {
  id: string;
}
@observer
class Share extends Component<RouteComponentProps<RouteParams>, State> {
  store: ShareStore;
  service: DoctorInfoService;
  methods: any;

  constructor(props) {
    super(props);
    this.store = new ShareStore();
    this.service = new DoctorInfoService();
    this.methods = this.getMethods();
    this.state = {
      loadFlag: '0',
      doctor: {},
      poster: ''
    };
  }

  UNSAFE_componentWillMount(){
    //window.TEA('主页', 'sharing');
  }
  
  async componentDidMount() {
    document.title = '保存图片';
    const { id } = this.props.match.params;
    const shareDoctor = await this.service.getShareDoctor(id);
    this.setState({
      loadFlag: '1',
      doctor: shareDoctor
    })
    setTimeout(() => {
      Toast.loading('图片生成中');
      this.methods.createPoster()
    }, 0)
  }

  getMethods() {
    return {
      createPoster: () => {
        window.scrollTo(0,0);
        const doctorPoster = document.getElementById('doctorPoster');
        const _this = this;
        html2canvas(doctorPoster as HTMLElement, {
          useCORS: true,
          allowTaint: false
        }).then(function(canvas) {
          const src = canvas.toDataURL('image/png');
          Modal.alert('长按海报保存', <><img src={src} alt="" /></>)
          Toast.hide();
        });
      }
    }
  }

  render() {
    const { doctor, poster } = this.state;
    const { 
      institution_name = '', //推荐机构名称
      headimg_url = '', //头像
      doctor_name = '', //医生名称
      department_name = '', //科室名称
      grade_name = '', //医生职级
      hospital_name = '', //医院名称
      title_tags = [],  //称号
      specialize = '', //专业擅长
      designation = [], //荣誉称号
      doctor_doc = [], //科普作品
      qr_url = '', //二维码图片
    } = doctor

    const QRErrorCorrectLevel = {
      L : 1,
      M : 0,
      Q : 3,
      H : 2
    };
    // 分享二维码
    const qrCodeBase64 = jrQrcode.getQrBase64(qr_url, {
      padding       : 10,   // 二维码四边空白（默认为10px）
      width         : 256,  // 二维码图片宽度（默认为256px）
      height        : 256,  // 二维码图片高度（默认为256px）
      correctLevel  : QRErrorCorrectLevel.L,    // 二维码容错level（默认为高）配置为L 因为图片展示中一般不存在二维码破损或者变脏
      reverse       : false,        // 反色二维码，二维码颜色为上层容器的背景颜色
      background    : "#ffffff",    // 二维码背景颜色（默认白色）
      foreground    : "#000000"     // 二维码颜色（默认黑色）
    });

    return (
      <>
        <div 
          id='doctorPoster' 
          className={styles.share_page}
          onTouchStart={()=>{
            //window.TEA('分享名片', 'save_picture');
          }}>
            <img className={styles.share_title} src={shareTitleImg} alt="健康中国医者名片" />
            <img className={styles.share_bg_bottom} src={shareBottomImg} alt="" />
            
            {/* 医生信息卡片 */}
            <div className={title_tags?.length ? styles.share_card : styles.share_card2}>
              <img className={styles.share_card_bg} src={title_tags?.length ? shareCardImg1 : shareCardImg2} alt="" />
              <img className={styles.share_card_auth} src={shareAuthImg} alt="" />
              <div className={styles.share_card_content}>
                <div className={styles.share_card_avatar}><img src={headimg_url} alt="" /></div>
                <div className={styles.share_card_text}>
                  <p className={styles.share_card_name}>{doctor_name}</p>
                  <p className={styles.share_card_describe}>{department_name}｜{grade_name}</p>
                  <p className={styles.share_card_hosp}>{hospital_name}</p>
                  <p className={styles.share_card_tags}>
                    {title_tags?.map((tag) => {
                      return <div className={styles.share_card_tag}><span>{tag}</span></div>
                    })}
                  </p>
                </div>
              </div>
              {/* <img crossOrigin="anonymous" src={`https://jkzgapp-api.lvsongguo.com/mobile/qrCode?source=0&path=pages/index/index?t=${Math.random()}`} alt="" /> */}
              <div className={styles.share_card_title}>
                <p>{institution_name}</p>
              </div>
            </div>

            {/* 专业擅长 */}
            <div className={styles.share_info}>
              <div className={styles.share_info_item}>
                  <div className={styles.share_info_title}>
                    <div className={styles.share_info_icon} >
                      <img src={shareDiamondImg} alt="" />
                    </div>
                    <p>专业擅长</p>
                  </div>
                  {
                    specialize && (
                      <div className={styles.share_info_content}>
                        <div className={styles.share_info_icon}>
                          <img src={shareOImg} alt="" />
                        </div>
                        <p className={styles.share_info_p}>{specialize}</p>
                      </div> 
                    )
                  }
              </div>

              {/* 荣誉称号 */}
              {designation?.length ? <div className={styles.share_info_item}>
                    <div className={styles.share_info_title}>
                      <div className={styles.share_info_icon} >
                        <img src={shareHonor} alt="" />
                      </div>
                      <p>荣誉称号</p>
                    </div>
                    {
                      designation?.map((text) => {
                        return (
                          <div className={styles.share_info_content}>
                            <div className={styles.share_info_icon}>
                              <img src={shareOImg} alt="" />
                            </div>
                            <p className={styles.share_info_p}>{text}</p>
                          </div> 
                        )
                      })
                    }
                </div> : null 
              }

              {/* 科普作品 */}
              {doctor_doc?.length ? <div className={styles.share_info_item}>
                    <div className={styles.share_info_title}>
                      <div className={styles.share_info_icon} >
                        <img src={shareBook} alt="" />
                      </div>
                      <p>科普作品</p>
                    </div>
                    {
                      doctor_doc?.map((text) => {
                        return (
                          <div className={styles.share_info_content}>
                            <div className={styles.share_info_icon}>
                              <img src={shareOImg} alt="" />
                            </div>
                            <p className={styles.share_info_p}>{text}</p>
                          </div> 
                        )
                      })
                    }
                </div> : null
              }
            </div>

            <div className={styles.share_code} >
              <img src={shareCodeImg} alt="" />
              <div className={styles.share_qr_code}>
                <img id='qrcode' src={qrCodeBase64} alt="" />
              </div>
              <p className={styles.share_code_title}>扫 / 码 / 查 / 看 / 更 / 多 / 介 / 绍</p>
            </div>
          </div>
        <div className={styles.btm_save} onClick={this.methods.createPoster}>
          {/* <img src={downIcon} className={styles.dwon_icon} alt="down" /> */}
          <span className={styles.dwon_title}>生成海报</span>
        </div>
      </>
    );
  }
}

export default Share;
