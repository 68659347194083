import { observable, action } from 'mobx';
// import message from '../components/message';
import message from '@/components/message';
import ContentsService, { VoiceQuestionList } from '../services/contentsService';

type Page = VoiceQuestionList;
interface SelectedQuestion {
  pageIndex?: number;
  index?: number;
  id?: string;
}
interface Tab {
  type: string;
  text: string;
  pages: Page[];
  firstPageLoaded: boolean;
  hasMore: boolean;
  updating: boolean;
}

class VoiceAskStore {
  service: ContentsService;

  loaded = false;

  @observable
  tabs: Tab[] = [];

  @observable
  showDialog = false;

  @observable
  selectedQuestion: SelectedQuestion = {
    pageIndex: undefined,
    index: undefined,
    id: undefined
  };

  @observable
  selectedReason: number[] = [];

  @observable
  activeTab = 0;

  constructor() {
    this.service = new ContentsService();
    this.init();
  }

  @observable
  reasons = [
    { text: '不适合本人领域', checked: false },
    { text: '不擅长此类问题', checked: false },
    { text: '问题表述不清楚', checked: false },
    { text: '需详细描述病情才可回复', checked: false },
    { text: '其他原因', checked: false }
  ];

  @action
  init = async () => {
    try {
      const tabData = await this.service.getVoiceQuestionTabs();
      tabData.forEach((item) => {
        this.tabs.push({
          pages: [],
          firstPageLoaded: false,
          hasMore: true,
          updating: false,
          type: encodeURIComponent(item),
          text: item
        });
      });
      this.tabs.forEach((_tab, index) => {
        this.service
          .getVoiceQuestionList(_tab.type)
          .then((list) => {
            if (list && list.length > 0) {
              this.tabs[index].pages.push(list);
            } else {
              this.tabs[index].hasMore = false;
            }
            this.tabs[index].firstPageLoaded = true;
          })
          .catch(() => {
            this.tabs[index].firstPageLoaded = true;
            this.tabs[index].hasMore = false;
          });
      });
    } catch (error) {
      console.log(error);
    }
  };

  @action
  handleClickTab = (index: number) => {
    this.activeTab = index;
  };

  @action
  updatePage = (index: number) => {
    console.log('update', index);
    const { type, pages } = this.tabs[index];
    this.service
      .getVoiceQuestionList(type, pages.length + 1)
      .then((list) => {
        if (list && list.length > 0) {
          this.tabs[index].pages.push(list);
        } else {
          this.tabs[index].hasMore = false;
        }
      })
      .catch(() => {
        this.tabs[index].firstPageLoaded = true;
        this.tabs[index].hasMore = false;
      });
  };

  @action
  block = (pageIndex: number, index: number, id: string) => {
    console.log('block:', id);
    this.selectedQuestion.pageIndex = pageIndex;
    this.selectedQuestion.index = index;
    this.selectedQuestion.id = id;
    this.openDialog();
  };

  @action
  openDialog = () => {
    this.showDialog = true;
  };

  @action
  closeDialog = () => {
    this.showDialog = false;
    this.selectedQuestion = {
      pageIndex: undefined,
      index: undefined,
      id: undefined
    };
    this.selectedReason = [];
    this.reasons.forEach((_reason, index) => {
      this.reasons[index].checked = false;
    });
  };

  @action
  selectReason = (index: number) => {
    this.reasons[index].checked = !this.reasons[index].checked;
    const tempArr: number[] = [];

    this.reasons.forEach((reason, reasonIndex) => {
      if (reason.checked) {
        tempArr.push(reasonIndex + 1);
      }
    });
    this.selectedReason = tempArr;
    console.log(tempArr, this.selectedReason);
  };

  @action
  submitBlockReason = () => {
    const { pageIndex, index, id } = this.selectedQuestion;
    if (pageIndex !== undefined && index !== undefined) {
      // const oldPage = this.tabs[this.activeTab].pages[pageIndex];
      this.service
        .blockVoiceQuestion(id || '', this.selectedReason)
        .then(() => {
          message.success('屏蔽成功');
          this.closeDialog();
          this.tabs[this.activeTab].pages[pageIndex].splice(index, 1);
        })
        .catch(() => {
          message.info('屏蔽失败，请稍后重试');
          this.closeDialog();
        });
    }
  };
}

export default VoiceAskStore;
