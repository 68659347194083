import { observable, action } from 'mobx';
import message from '../components/message';
import DoctorInfoService from '../services/doctorInfoService';
import Doctor, { IdentityCard } from '../types/doctor';
import util from '../utils';

const EDUGRADE_MAP = ['助教', '讲师', '副教授', '教授'];
class EditStore {
  service: DoctorInfoService;

  @observable
  doctor: Doctor | undefined;

  @observable
  agreementOpen = false;

  @observable
  xhAgreementOpen = false;

  @observable
  oldUserNeedAuth = false;

  @observable
  doctorId = '';

  @observable
  showCertCodeHint = false;

  constructor() {
    this.service = new DoctorInfoService();
    this.getDoctorInfo();
  }

  @action
  showAgreement = () => {
    this.xhAgreementOpen = false;
    this.agreementOpen = true;
  };

  @action
  closeAgreement = () => {
    this.agreementOpen = false;
  };

  @action
  showXhAgreement = () => {
    this.agreementOpen = false;
    this.xhAgreementOpen = true;
  };

  @action
  closeXhAgreement = () => {
    this.xhAgreementOpen = false;
  };

  @action
  getDoctorInfo(): void {
    this.service
      .getUserInfo()
      .then((res) => {
        if (res[0] && res[0].doctor_id) {
          const doctorId = res[0].doctor_id;

          this.service.getDoctorInfo().then((doctor) => {
            const {
              idcard_img = {},
              is_edit_extra = ''
            }: { idcard_img: IdentityCard | any; is_edit_extra: string } = doctor || {};
            const { front, obverse } = idcard_img || {}; // 身份证信息
            doctor.idcard_img_front = front || '';
            doctor.idcard_img_obverse = obverse || '';
            this.oldUserNeedAuth = doctor.state == '2' && is_edit_extra == '1'; // 旧用户，未上传身份证信息的
            this.doctor = doctor;
          });
        } else {
          // message.warning('医生信息未认证');
          window.location.href = '/';
        }
      })
      .catch(() => {
        window.location.href = '/';
      });
  }

  @action
  upload(formData: FormData, key) {
    this.service
      .uploadImage(formData)
      .then((res) => {
        if (res.data && res.data.code === 200 && res.data?.data?.url && this.doctor) {
          const imgUrl = res.data.data.url;
          this.doctor[key] = imgUrl;
          this.doctor = { ...this.doctor };
        }
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  @action
  showCertHint = () => {
    this.showCertCodeHint = true;
  };

  @action
  hideCertHint = () => {
    this.showCertCodeHint = false;
  };

  submit(): void {
    // console.log(this.doctor);
    if (this.doctor) {
      let {
        doctor_id,
        name,
        hospital,
        headimg_url,
        grade_name,
        specialty,
        edugrade_name,
        id_num,
        cert_code,
        specialize,
        intro,
        multi_working_hospital,
        work_experience,
        designation,
        idcard_img_front,
        idcard_img_obverse,
        cert_img,
        grade_img,
        state
      } = this.doctor;
      const edugradeIndex = EDUGRADE_MAP.indexOf(edugrade_name);
      let infoMsg = '';
      const idNumReg18 = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/; // 18位身份证
      const idNumReg15 = /^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$/; // 15位身份证
      switch (true) {
        case !headimg_url:
          infoMsg = '请上传头像';
          break;
        case !name:
          infoMsg = '请填写姓名';
          break;
        case !grade_name:
          infoMsg = '请填写聘用职称';
          break;
        case !cert_code:
          infoMsg = '请填写医师执业证书';
          break;
        case !id_num:
          infoMsg = '请填写身份证号';
          break;
        case !!id_num && !(idNumReg18.test(id_num) || idNumReg15.test(id_num)):
          infoMsg = '请填写正确格式身份证号';
          break;
        case !intro:
          infoMsg = '请填写个人简介';
          break;
        case !specialize:
          infoMsg = '请填写专业擅长';
          break;
        // case !idcard_img_front:
        //   infoMsg = '请填上传身份证人像面';
        //   break;
        // case !idcard_img_obverse:
        //   infoMsg = '请填上传身份证国徽面';
        //   break;
        // case !cert_img:
        //   infoMsg = '请填上传医师资格证书照片（人像面）';
        //   break;
        // case !grade_img:
        //   infoMsg = '请填上传职称证书照片（人像面）';
        //   break;
        // case !hospital.name:
        //   infoMsg = '请填写所在医院';
        //   break;
        // case !hospital.hospital_location || !hospital.hospital_location.province:
        //   infoMsg = '请选择医院所在地';
        //   break;
        // case !hospital.department || !hospital.department.name:
        //   infoMsg = '请填写行政科室';
        //   break;
        // case !specialty:
        //   infoMsg = '请填写专业方向';
        //   break;
        default:
          infoMsg = '';
      }
      if (infoMsg) {
        message.info(infoMsg);
        return;
      }
      if (cert_code && cert_code.length < 15) {
        message.info('医师执业证书号码信息错误，请重新输入');
        return;
      }
      // 基础分固定39 执业机构 荣誉称号 执业履历 各5分
      let score = 39;
      // if (name) {
      //   score += 3;
      // }
      // if (headimg_url) {
      //   score += 3;
      // }
      // if (hospital && hospital.name) {
      //   score += 3;
      // }
      // if (hospital && hospital.department) {
      //   score += 3;
      // }
      // if (grade_name) {
      //   score += 3;
      // }
      // if (cert_code) {
      //   score += 3;
      // }
      // if (edugrade_name) {
      //   score += 3;
      // }
      // if (specialty) {
      //   score += 3;
      // }
      // if (edugrade_name) {
      //   score += 3;
      // }
      // if (intro && intro !== '') {
      //   score += 5;
      // }
      
      // 清理掉空的项
      designation = (designation && designation.length > 0) ? designation.filter((item) => {
        return item.description
      }) : []
      work_experience = (work_experience && work_experience.length > 0) ? work_experience.filter((item) => {
        return item.description
      }) : []
      multi_working_hospital = (multi_working_hospital && multi_working_hospital.length > 0) ? multi_working_hospital.filter((item) => {
        return item.name
      }) : []

      if (designation && designation.length > 0) {
        score += 5;
      }
      if (work_experience && work_experience.length > 0) {
        score += 5;
      }
      if (multi_working_hospital && multi_working_hospital.length > 0) {
        score += 5;
      }

      // c端链接
      let baseHref = 'https://c-test-card.jkzg2030.cn';
      let href = `${baseHref}/doctor/${doctor_id}?from=doctor_jkb&source=doctor_jkb`;
      if (util.isProd) {
        href = `https://c-card.jkzg2030.cn/doctor/${doctor_id}?from=doctor_jkb&source=doctor_jkb`;
      }
      this.service
        .updateDoctorInfo({
          name,
          hospital,
          headimg_url,
          grade_name,
          edugrade_name,
          edugrade_id: edugradeIndex > -1 ? edugradeIndex + 1 : '',
          specialty,
          id_num,
          cert_code,
          doctor_id,
          specialize,
          intro,
          multi_working_hospital,
          work_experience,
          designation,
          login_source: 9,
          flag: 'extra',
          idcard_img: {
            front: idcard_img_front, // 身份证正面
            obverse: idcard_img_obverse // 身份证反面
          },
          cert_img,
          grade_img
        })
        .then(() => {
          message.success(`发布成功，获得${score}积分`);
          setTimeout(() => {
            window.location.href = href;
          }, 1500);
        })
        .catch(() => {
          window.location.href = href;
        });
    }
  }
}

export default EditStore;
