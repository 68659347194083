import React from 'react';
import { renderRoutes, RouteConfigComponentProps } from 'react-router-config';
import './App.css';
// import SlardarWeb from'@slardar/web'

// const initSlardar = (config = {}) => {
//   let scmVersion = '';
//   if (process.env.BUILD_VERSION) {
//     scmVersion = process.env.BUILD_VERSION;
//   }
//   try {
//     SlardarWeb('init', {
//       bid: 'doctor_card_tod',
//       pid: window.location.pathname || '',
//       env: window.location.host === 'd-card.jkzg2030.cn' ? 'production' : process.env.BUILD_TYPE,
//       release: scmVersion,
//       ...config
//     });
//     SlardarWeb('start')
//     console.log('slardar监控开启成功');
//   } catch (error) {
//     console.error('slardar监控开启失败：', error);
//   }
// }
// initSlardar()

const App: React.FC<RouteConfigComponentProps> = ({ route }) => (
  <div className="App">{route && renderRoutes(route.routes)}</div>
);

export default App;
