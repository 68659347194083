import axios from 'axios';
import Token from './token';
import util from '../utils';

  let requestUrl = '';

  const defaultTags = {
    refer: window.location.href,
    pathname: window.location.pathname,
    ua: navigator && navigator.userAgent,
  // client: isLSGAppMain ? 'lsgapp' : 'community',
  // utm_source: getUtm().utm_source || '',
  // utm_medium: getUtm().utm_medium || '',
  // webid: Cookies.get('MONITOR_WEB_ID') || ''
  };

  const sendLog = (name = '', params) => {
    if (util.isProd) {
      (window as any).vemars('emit', 'custom', {
        event_name: name,
        metrics: {
          time: Date.now() - params?.request_time,
        },
        tags: {
          ...defaultTags,
          ...params,
        },
      });
    }
  };
  const sendLogId = (logid = '', level = 'info') => {
    if (util.isProd) {
      (window as any).vemars('emit', 'log', {
        value: logid,
        level,
        tags: {
          logid,
        },
      });
    }
  };

  const getUrlParams = (url = '') => {
    let result = {
      pathname: '',
      query: '',
    };
    try {
      const urlObj = new URL(url);
      result = {
        pathname: urlObj.pathname || '',
        query: urlObj.search || '',
      };
    } catch (error) {
      console.log(error);
    }

    return result;
  };

axios.interceptors.request.use(
  config => {
    const token =  Token.getToken();
    if (token ) { // 判断是否存在token，如果存在的话，则每个http header都加上token
      config.headers[Token.reqKey] = token  //请求头加上token
    }
    // 监控
    requestUrl = config.url || '';
    const startTime = Date.now();
    const newConfig = {
      ...config,
      axStart: startTime,
      axStartHigh: 0,
    };
    try {
      newConfig.axStartHigh = window.performance.now();
      if (!(window as any).axStore) {
        (window as any).axStore = {};
      }
    } catch (e) {
      console.error(e);
      // do nothing
    }
    // 监控end
    return newConfig
  },
  error => {
    try {
      let urlParams = {
        pathname: '',
        query: '',
      };
      if (error?.request?.config?.url) {
        urlParams = getUrlParams(error?.request?.config?.url);
      }
      sendLog(
        'custom_request_error',
        {
          request_stage: 'request',
          request_path: urlParams?.pathname,
          request_query: urlParams?.query,
          request_url: error?.request?.config?.url,
          message: JSON.stringify(error),
          method: error?.request?.config?.method,
          request_time: `${error?.response?.config?.axStart}`,
          response_status: `${error?.response?.status}`,
        }
      );
    } catch (custom_request_error) {
      console.error('custom_request_error', custom_request_error);
    }

    return Promise.reject(error)
  })

// axios拦截器
axios.interceptors.response.use(
  response => {
    console.log('response', response);
    //拦截响应，做统一处理 
    if (response.data.code) {
      switch (response.data.code) {
        case 10008:
          window.location.replace('/login');
      }
    }
    if (response.headers[Token.resNewKey]) {
      Token.setToken(response.headers[Token.resNewKey]);
    }
    // 监控
    try {
      const { headers, data, request, config } = response;
      const logid = headers?.['x-tt-logid'] || '';
      // 上报logid
      // 接口code上报
      sendLog(
        'custom_request_code',
        {
          request_stage: 'response',
          request_url: request?.responseURL,
          request_path: request?._url,
          method: request?.method,
          request_time: `${(config as any)?.axStart}`,
          response_status: `${request?.status}`,
          response_code: `${data?.code}`,
          logid,
        },
      );
      sendLogId(logid);
    } catch (error) {
      console.error('custom_request_code', error);
    }
    // 监控 end

    return response
  },
  //接口错误状态处理，也就是说无响应时的处理
  error => {
    try {
      let urlParams = {
        pathname: '',
        query: '',
      };
      if (requestUrl) {
        urlParams = getUrlParams(requestUrl);
      }
      // 接口请求返回报错上报
      sendLog(
        'custom_request_error',
        {
          request_type:
              error === undefined || error?.code === 'ECONNABORTED'
                ? 'timeout'
                : 'error',
          request_stage: 'response',
          request_path: urlParams?.pathname,
          request_query: urlParams?.query,
          request_url: error?.response?.responseURL,
          method: error?.response?._method,
          request_time: `${error?.response?.config?.axStart}`,
          response_status: `${error?.response?.status}`,
          logid: error?.response?.headers?.['x-tt-logid'] || '',
          message: error?.response?.data?.message || error?.message,
        },
      );
    } catch (custom_request_error) {
      console.error('custom_request_error', custom_request_error);
    }

    return Promise.reject(error) // 返回接口返回的错误信息
  })

export default axios;
