import React from 'react';
import classNames from 'classnames/bind';

import styles from './timerProgress.module.css';

const cx = classNames.bind(styles);

interface Props {
  playing: boolean;
  direction: string;
}

const TimerProgress: React.FC<Props> = ({ playing, direction }) => {
  const arr = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
  return (
    <div className={cx('progressContainer', { isPlaying: playing }, direction)}>
      <div className={styles.staticContainer}>
        {arr.map((_stick, index) => {
          // eslint-disable-next-line react/no-array-index-key
          return <div className={styles.stick} key={index} />;
        })}
      </div>
      <div className={styles.motionContainer}>
        {arr.map((_stick, index) => {
          // eslint-disable-next-line react/no-array-index-key
          return <div className={styles.stick} key={index} />;
        })}
      </div>
    </div>
  );
};

export default TimerProgress;

/* const RemindCard extends Component<RemindCardProps> {
  @observable
  fold = true;

  @action
  handleToggleClick = () => {
    this.fold = !this.fold;
  };

  render() {
    const { index, title, content, className } = this.props;
    return (
      <div
        className={cx('card', className, {
          fold: this.fold
        })}
      >
        <div className={styles.cardHead}>
          <div className={styles.cardIndex}>样例参考{index + 1}</div>
          <div className={styles.cardTitle}>{title}</div>
        </div>
        <div className={styles.cardBody}>{content}</div>
        <div className={styles.toggle} onClick={this.handleToggleClick}>
          <span>{this.fold ? '展开' : '收起'}全部内容</span>
          <span className={cx('arrow', { up: !this.fold })} />
        </div>
      </div>
    );
  }
}

export default RemindCard;
 */
