import React, { Component } from 'react';
import { observer } from 'mobx-react';
import homeIcon from '../../static/images/icons/home.png';
import shareIcon from '../../static/images/icons/share.png';
import rzlogo from '../../static/images/rzlogo.png';
import authrz from '../../static/images/icons/auth-icon.png';
import scICon from '../../static/images/icons/hos-dia.png';
import ryIcon from '../../static/images/icons/hos-honor.png';
import perIcon from '../../static/images/icons/per.png';
import cirIcon from '../../static/images/icons/hos-cir.png';
import arrowIcon from '../../static/images/icons/jtu-d.png';
import doctor_head_bg_img from '../../static/images/6577017494068791.png';
import styles from './index.module.css';

@observer
class Doctor extends Component {
  //   constructor(props: Readonly<{}>) {
  //     super(props);
  //   }
  componentDidMount() {}

  render() {
    return (
      <div>
        <div className={styles.doctor_head}>
          <img
            className={styles.doctor_head_bg}
            src={doctor_head_bg_img}
            alt="head"
          />
          <div className={styles.doctor_head_btn}>
            <div className={styles.icon_btn}>
              <img src={homeIcon} alt="home" />
              <span>主页</span>
            </div>
            <div className={styles.icon_btn}>
              <img src={shareIcon} alt="home" />
              <span>分享</span>
            </div>
          </div>
          <div className={styles.doctor_code}>
            <span>执业证书编号 0129384849202</span>
          </div>

          <img
            className={styles.avatar}
            src="https://file.youlai.cn/cnkfile1/M00/15/F2/ooYBAFmJbWyAKQfyAABnqA6pzOU745.jpg"
            alt="tx"
          />
        </div>
        <div className={styles.doctor_info}>
          <div className={styles.info_base}>
            <span>延庆霞</span>
            <span>主任医师</span>
            <span>副教授</span>
          </div>
          <div className={styles.info_item}>
            <span>科室：内分泌科</span>
          </div>
          <div className={styles.info_item}>
            <span>医院：中国人民解放军第一医院</span>
          </div>
          <div className={styles.info_item}>
            <span> 推荐机构：中国人民解放军第一医院</span>
          </div>
        </div>
        <div className={styles.doctor_rz}>
          <img src={authrz} className={styles.rzicon} alt="rz" />
          <span className={styles.doctor_rz_title}>健康中国医者名片认证</span>
          <img src={rzlogo} alt="logo" className={styles.rzlogo} />
        </div>
        <div className={styles.part}>
          <div className={styles.parthead}>
            <img className={styles.particon} src={scICon} alt="sc" />
            <div className={styles.parttitle}>个人擅长</div>
          </div>
          <div className={styles.partbody}>
            <div className={styles.partitem}>
              <i className={styles.crcIcon} />
              <div className={styles.partcontent}>
                全国骨科优质医院女性乳腺是由皮肤全国骨科优质医院女性乳腺是由皮肤全国骨科优质医院女性乳腺是由皮肤、纤维组织、乳腺腺体和脂肪组成的，乳腺癌是发生在乳腺腺上皮组织的恶性肿瘤。乳腺癌中
              </div>
            </div>
          </div>
          <div className={styles.partbtn}>
            <div className={styles.updown}>
              <span>查看更多内容</span>
              <img src={arrowIcon} alt="jt" />
            </div>
          </div>
        </div>
        <div className={styles.bline} />
        <div className={styles.part}>
          <div className={styles.parthead}>
            <img className={styles.particon} src={perIcon} alt="sc" />
            <div className={styles.parttitle}>个人简介</div>
          </div>
          <div>
            <div className={styles.partbody}>
              <div className={styles.partitem}>
                <i className={styles.crcIcon} />
                <div className={styles.partcontent}>全国骨科优质医院女性</div>
              </div>
              <div className={styles.partitem}>
                <i className={styles.crcIcon} />
                <div className={styles.partcontent}>全国骨科优质医院女性</div>
              </div>
              <div className={styles.partitem}>
                <i className={styles.crcIcon} />
                <div className={styles.partcontent}>全国骨科优质医院女性</div>
              </div>
            </div>
          </div>
          <div className={styles.partbtn}>
            <div className={styles.updown}>
              <span>查看更多内容</span>
              <img src={arrowIcon} alt="jt" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Doctor;
