import React, {Component} from 'react';
import {Link, RouteComponentProps} from 'react-router-dom';
import {observer} from 'mobx-react';
import {Modal, Icon} from "antd-mobile"

import banner from "../../static/images/activity_banner.png"
import style from "./index.module.css"
import "./index.module.css"
import message from '../../components/message';
import ActivityStore from "../../stores/activityStore"
import ActivityService from "@/services/activityService";
import liveLogo from "@/static/images/live_logo.png"
import unActiveLiveLogo from  "@/static/images/live_logo_2.png"
import unActiveOnlineLogo from  "@/static/images/online_logo_2.png"
import unActiveScienceLogo from  "@/static/images/science_logo_2.png"
import onlineLogo from "@/static/images/online_logo.png"
import scienceLogo from "@/static/images/science_logo.png"
import emptyImg from  "@/static/images/activity_empty.png"

@observer
class Activity extends React.Component<RouteComponentProps<any>, { activeTab: Number, modalVisible: boolean, isLiveChecked: boolean, isOnlineChecked: boolean, isScienceChecked: boolean, cardStatus: Number }> {
  store: ActivityStore

  service: ActivityService

  currStartTime: Array<any>

  constructor(props) {
    super(props);
    this.service = new ActivityService();
    this.store = new ActivityStore(this.service);
    this.currStartTime = [];
    this.state = {
      activeTab: 0,
      modalVisible: false,
      isLiveChecked: false, //权威直播是否选中
      isOnlineChecked: false, //在线义诊是否选中
      isScienceChecked: false, //健康科普是否选中
      cardStatus: 0 //卡片状态 0:立即报名态， 1：敬请期待态， 2：报名状态态
    }
  }

  domEvents = {
    closeModal: () => {
      this.setState({
        modalVisible: false,
        isLiveChecked: false,
        isOnlineChecked: false,
        isScienceChecked: false,
      })
    },
    signUp: (key) => {
      const params = {};
      params[key] = !this.state[key];
      this.setState(params)
    },
    toAddress: (e, address) => {
      e.stopPropagation();
      if(address){
        window.open(address)
      }
    },
    showActivityDetail: (id, text, startTime) => {
      this.currStartTime = [...startTime]
      this.getCardStatus(text);
      this.store.getActivityInfo(id)
      this.setState({
        modalVisible: true
      })
    },
    toInfoPage: (activity_id, contentId, title) => {
      if(!this.state.isLiveChecked && !this.state.isOnlineChecked && !this.state.isScienceChecked){
        message.info("选择参加的内容为空");
        return
      }
      const params = {
        liveId: this.state.isLiveChecked ? contentId.liveId : "",
        onlineId: this.state.isOnlineChecked ? contentId.onlineId : "",
        scienceId: this.state.isScienceChecked ? contentId.scienceId : ""
      };
      this.props.history.push({
        pathname: "/commitactivity",
        state: {
          activity_id,
          ...params,
          startTime: this.currStartTime,
          title
        }
      })
    }
  }

  getAttr = (index) => {
    switch (index) {
      case 0:
        return "isLiveChecked";
      case 1:
        return "isOnlineChecked";
      case 2:
        return "isScienceChecked";
      default:
        return ""
    }
  }

  getLogoUrl = (index, isFull) => {
    switch (index) {
      case 0:
        return !isFull ? liveLogo : unActiveLiveLogo;
      case 1:
        return !isFull ? onlineLogo : unActiveOnlineLogo;
      case 2:
        return !isFull ? scienceLogo : unActiveScienceLogo;
      default:
        return ""
    }
  }

  getSignStatus = (status) => {
    switch (status) {
      case 0:
        return "待审核";
      case 1:
        return "审核中";
      case 2:
        return "报名成功";
      case 3:
        return "报名失败";
      default:
        return "活动已下线"
    }
  }

  getCardStatus = (text) => {
    switch (text){
      case "立即报名":
        this.setState({
          cardStatus: 0,
        })
        return;
      case "敬请期待":
        this.setState({
          cardStatus: 1,
        })
        return;
      default:
        this.setState({
          cardStatus: 2,
        })
        return;
    }
  }

  isFullPeople = (status, applicants, total) => {
    return status === 0 && applicants === total
  }

  // @ts-ignore
  turnDateFormat = (date) => {
    if(date < 10){
      return "0" + date
    } else {
      return date
    }
  }

  componentDidMount() {
    this.store.getActiveList()
  }

  render() {
    const {
      activeTab,
      cardStatus,
    } = this.state
    const {
      activityList,
      finishActivityList,
      activityInfo
    } = this.store
    return (
      <div style={{
        overflow: "hidden"
      }}>
        <div className={style.header}>
          <img className={style.banner} src={banner} alt=""/>
          <div className={style.textContainer}>
            <p className={style.text}>为进一步践行健康中国行动，助力“互联网+医疗健康”政策落地，健康中国医者名片联合小荷健康围绕2021年50个科普日，推出“健康科普公益行”活动，通过权威直播、在线义诊、健康科普文章征集等活动，传播健康知识，提升公众健康素养。参与活动的医生将获得千万级曝光，更有阳光收入等鼓励....欢迎广大医生前来报名。</p>
            <div className={style.tag}>
              报名须知
            </div>
            <div className={style.colorDecoration}/>
            <p className={style.text}>1. 权威直播：三甲医院副主任医师及以上职称医生，有直播经验优先，直播时长1小时；</p>
            <p className={style.text}>2. 在线义诊：三甲医院主治医师及以上职称、相关学科医生，在线义诊1小时，回答至少10位患者提问，每名患者不少于50字；</p>
            <p className={style.text}>3. 健康科普：三甲医院主治医师及以上职称、相关学科医生，文章要求1000字以上；</p>
            <p className={style.text}>4. 报名成功后，将会通过短信通知及工作人员联系到您。</p>
          </div>
        </div>
        <div className={style.contentContainer}>
          <div className={style.tabContainer}>
            <div>
              <div className={activeTab === 0 ? style.activeTab : style.tab} onClick={() => {
                this.setState({
                  activeTab: 0
                })
              }}>
                进行中
                <div className={style.decoration} style={{
                  display: activeTab === 0 ? "block" : "none"
                }}/>
              </div>
              <div className={activeTab === 1 ? style.activeTab : style.tab} onClick={() => {
                this.setState({
                  activeTab: 1
                })
              }}>
                已结束
                <div className={style.decoration} style={{
                  display: activeTab === 1 ? "block" : "none"
                }}/>
              </div>
            </div>
          </div>
          {
            (activeTab === 0 ? activityList : finishActivityList).map((listItem, index1) => {
              return listItem.list.length > 0 ? <div key={index1} className={style.activityCardBox}>
                <div className={style.date}>
                  <div className={style.colorBlock}/>
                  {listItem.date[0] + "." + this.turnDateFormat(listItem.date[1] + 1)}
                </div>
                {
                  listItem.list.map((item, index2) => {
                    const currTime = new Date().getTime();
                    let signUpText = "";
                    if(activeTab === 0) {
                      if(item.is_sign){
                        signUpText = "报名状态"
                      } else {
                        if(currTime < item.sign_start_time * 1000) {
                          signUpText = "敬请期待"
                        } else if (currTime > item.sign_end_time * 1000) {
                          signUpText = "报名截止"
                        } else {
                          signUpText = "立即报名"
                        }
                      }
                    }
                    return <div key={index2} className={style.activityCard} onClick={() => {
                      this.domEvents.showActivityDetail(item.id, signUpText, [new Date(item.start_time * 1000).getFullYear(), new Date(item.start_time * 1000).getMonth(), new Date(item.start_time * 1000).getDate()])
                    }}>
                      <div className={style.upBox}>
                        <p className={style.title}>{item.title}</p>
                        <p className={style.dateInfo}>报名时间：{new Date(item.sign_start_time * 1000).getFullYear() + "." + this.turnDateFormat((new Date(item.sign_start_time * 1000).getMonth() + 1)) + "." + this.turnDateFormat(new Date(item.sign_start_time * 1000).getDate())}
                         至 {new Date(item.sign_end_time * 1000).getFullYear() + "." + this.turnDateFormat(new Date(item.sign_end_time * 1000).getMonth() + 1) + "." + this.turnDateFormat(new Date(item.sign_end_time * 1000).getDate())}</p>
                        <p className={style.dateInfo}>活动时间：{new Date(item.start_time * 1000).getFullYear() + "." + this.turnDateFormat(new Date(item.start_time * 1000).getMonth() + 1) + "." + this.turnDateFormat(new Date(item.start_time * 1000).getDate())}</p>
                      </div>
                      <div className={style.downBox}>
                        活动地址
                      </div>
                      {activeTab === 0 && <div className={style.signUp} style={{
                        color: signUpText === "报名截止" || signUpText === "敬请期待" ? "#666" : "#28c6a8"
                      }}>{signUpText}
                        {signUpText !== "报名截止" && signUpText !== "敬请期待" && <Icon className={style.rightIcon} type="right" size="sm"/>}
                      </div>}
                      <div className={style.goIn} onClick={(e) => {
                        this.domEvents.toAddress(e, item.address)
                      }}>{item.address ? "点击进入" : "暂无"} {item.address && <Icon className={style.rightIcon} type="right" size="sm"/>}</div>
                    </div>
                  })
                }
              </div> : <div key={index1} className={style.activityCardBox}>
                <div className={style.date}>
                  <div className={style.colorBlock}/>
                  {listItem.date[0] + "." + this.turnDateFormat(listItem.date[1] + 1)}
                </div>
                <img className={style.emptyImg} src={emptyImg} alt=""/>
                <p className={style.emptyText}>{activeTab === 0 ? "暂无活动，敬请期待！" : "暂无活动"}</p>
              </div>
            })
          }
        </div>
        <Modal
          popup
          visible={this.state.modalVisible}
          onClose={this.domEvents.closeModal}
          animationType="slide-up"
          className={style.activityModal}
          style={{
            paddingBottom: cardStatus === 0 && activityInfo.contents && (activityInfo.contents[0].applicants_people < activityInfo.contents[0].total_people ||
              activityInfo.contents[1].applicants_people < activityInfo.contents[1].total_people ||
              activityInfo.contents[2].applicants_people < activityInfo.contents[2].total_people) ? 0 : "4vw"
          }}
        >
          <div className={style.modalContainer}>
            <Icon type="cross-circle-o" className={style.closeBtn} onClick={this.domEvents.closeModal}/>
            <p className={style.modalTitle}>{activityInfo.title}</p>
            <div className={style.modalContent}>
              <div className={style.abstract}>
                <p>简介：</p>
                <p>{activityInfo.intro}</p>
              </div>
              {cardStatus !== 1 && <div className={style.selectBox}>
                <p className={style.title}>{cardStatus === 0 ? "请选择您想要参加的内容" : "我参加的内容"}</p>
                {activityInfo.contents && activityInfo.contents.map((content, index) => {
                  return (cardStatus !== 2 || content.status !== -1) && <div key={index} className={style.selectItem} onClick={() => {
                    if(cardStatus === 0){
                      if(this.isFullPeople(cardStatus, content.applicants_people, content.total_people)){
                        return
                      }
                      this.domEvents.signUp(this.getAttr(index))
                    }
                  }}>
                    <img className={style.logo} src={this.getLogoUrl(index, this.isFullPeople(cardStatus, content.applicants_people, content.total_people))} alt=""/>
                    <div className={style.textContainer}>
                      <p className={style.activityTitle} style={{
                        color: this.isFullPeople(cardStatus, content.applicants_people, content.total_people) ? "#999" : "#333"
                      }}>{content.content}</p>
                      <p className={style.peopleCount} style={{
                        color: this.isFullPeople(cardStatus, content.applicants_people, content.total_people)  ? "#999" : "#666"
                      }}>已有{content.applicants_people}人报名成功，剩余{content.total_people - content.applicants_people}个名额</p>
                    </div>
                    {cardStatus === 0 && (this.state[this.getAttr(index)] ? <div className={style.checked}>
                      <Icon className={style.checkIcon} type="check" size="xs"/>
                    </div> : <div className={style.unchecked} style={{
                      borderColor: this.isFullPeople(cardStatus, content.applicants_people, content.total_people) ? "#bbb" : "#999"
                    }}/>)}
                    {cardStatus === 2 && <div className={style.statusText}>{this.getSignStatus(content.status)}</div>}
                  </div>
                }) }
                {cardStatus === 0 && activityInfo.contents && (activityInfo.contents[0].applicants_people < activityInfo.contents[0].total_people ||
                  activityInfo.contents[1].applicants_people < activityInfo.contents[1].total_people ||
                  activityInfo.contents[2].applicants_people < activityInfo.contents[2].total_people) &&
                <div className={style.nextStep} onClick={() => {this.domEvents.toInfoPage(activityInfo.id, {
                  liveId: activityInfo.contents[0].id,
                  onlineId: activityInfo.contents[1].id,
                  scienceId: activityInfo.contents[2].id
                }, activityInfo.title)}}>下一步</div>}
              </div>}
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}

export default Activity
