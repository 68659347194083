import 'react-app-polyfill/stable';
import "@babel/polyfill";
import React from 'react';
import ReactDOM from 'react-dom';
import VConsole from  'vconsole';
import util from './utils';
import '@/common/css/resetAnt.css'
import './index.css';
// import Tea from 'byted-tea-sdk'; // 线上
// import Tea from 'byted-tea-sdk/es/index-et.min.js' // boe
import { BrowserRouter } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import routes from './routes/index';
import vemars from '@apm-insight-web/rangers-site-sdk';

// @ts-ignore
function isWeChat() {
  const ua = window.navigator.userAgent.toLowerCase();
  // @ts-ignore
  if (ua.match(/MicroMessenger/i) == 'micromessenger') {
    const system = {
      win: false,
      mac: false
    };
    //检测平台
    const p = navigator.platform;
    system.win = p.indexOf("Win") == 0;
    system.mac = p.indexOf("Mac") == 0;
    return system.win || system.mac;
  }
}

if (util.isDev || util.isTest) {
  new VConsole(); // 调试工具
}


// 火山云性能监控
try {
  util.isProd && vemars('config', {
    app_id: '302534',
  });
  (window as any).vemars = vemars;
  console.log('火山云性能监控开启');
} catch (error) {
  console.error('火山云性能监控错误：', error);
}


// TODO 增长分析待接入
// window.TEA = (page_title,action)=>{
//   Tea('doctorcard_tod_general',{
//     page_url: window.location.href,
//     doctor_id: localStorage.getItem('doctor_id'),
//     page_title,
//     action
//   });
// };

// window.LoginTea = (page_title,action)=>{
//   Tea('doctorcard_tod_general',{
//     page_url: window.location.href,
//     page_title,
//     action
//   });
// };

// window.sendLog = (event_name, data ={} )=>{
//   Tea('doctorcard_tod_general', {
//     page_url: window.location.href,
//     event_name,
//     ...data,

//   });
// };

// Tea.init({
//   app_id: 4382, // 示例appid，使用时请替换为自己的appid
//   channel: 'cn', // 当前上报渠道 cn为国内，具体值参考下方参数说明
//   log: true
//   // enable_et_test: true // 打到boe
// });

// Tea.config({
//   _staging_flag: 0 // 1发到测试库 0发到线上
// });

// Tea.start();

// if(isWeChat()){
//   window.sendLog('微信pc来源', {
//     wx_source: 'pc', // m
//   })
// }

ReactDOM.render(
  <BrowserRouter>
    {/* kick it all off with the root route */}
    {renderRoutes(routes)}
  </BrowserRouter>,
  document.getElementById('root')
);
