// import Tea from 'byted-tea-sdk';
import util from '@/utils';

// window.TEA = Tea;
/* eslint-disable no-nested-ternary */
const HOST = window.location.host;
export const isLSG = HOST.indexOf('songguojiankang.com') > -1;
export const isTest = true;
//  HOST.indexOf('test') > -1;
const loginRedirectPathInfo = {
  // 登录之后
  dev: `${location.host}/login`,
  test: 'https://d-test-card.jkzg2030.cn/login',
  // test: 'http://10.248.146.83:6789/login',
  prod: 'https://d-card.jkzg2030.cn/login'
};
// TODO 替换API域名
// d-jkzgyzmp-api.lvsongguo.com   -> api.jkzg2030.cn
const APIPathInfo = {
  dev: '/api/',
  test: 'https://api-test.jkzg2030.cn/',
  prod: 'https://api.jkzg2030.cn/'
};

// TODO 替换API域名
// healthchina-doctor-mis-api.xiaohe-jiankang.com  -> api.jkzg2030.cn
const uploadAPIPathInfo = {
  dev: 'https://api-test.jkzg2030.cn/',
  test: 'https://api-test.jkzg2030.cn/',
  prod: 'https://api.jkzg2030.cn/'
};
export const loginRedirectPath = `${location.origin}/login`;

export const APIPath = `${APIPathInfo[util.envKey]}dcard/`;
export const HostAPIPath = APIPathInfo[util.envKey];
export const uploadAPIPath = uploadAPIPathInfo[util.envKey]; // 上传视频地址
// export const uploadAPIPath = 'https://healthchina-doctor-mis-api.xiaohe-jiankang.com/'; // 上传视频地址

export const loginSource = isLSG ? 'h5_healthchina_lsg' : 'h5_healthchina';

export const appid = isLSG ? 'wxdc7973f4e3a13279' : 'wx5749b20957c51680';

export const prefix = 'lsg_hc_doctor_';

// TODO API 替换API
// c-doctor-api.lvsongguo.com   --> api.jkzg2030.cn
export const shareApi = util.envKey === 'prod'
  ? 'https://api.jkzg2030.cn/ccard/'
  : 'https://api-test.jkzg2030.cn/ccard/';
