import { observable, action, toJS } from 'mobx';
import message from '../components/message';
import DoctorInfoService from '../services/doctorInfoService';
import Doctor, { DaySchedule } from '../types/doctor';

interface ModalDataItem {
  checked: boolean;
  value: string;
}

class ScheduleStore {
  service: DoctorInfoService;

  weekMap = ['周一', '周二', '周三', '周四', '周五', '周六', '周日'];

  dayMap = [
    { key: '1', value: '上午' },
    { key: '2', value: '下午' },
    { key: '3', value: '晚上' }
  ];

  @observable
  modalData?: ModalDataItem[];

  @observable
  doctor: Doctor | undefined;

  @observable
  weekSchedule: DaySchedule[] = [];

  @observable
  showModal = false;

  @observable
  doctorId = '';

  @observable
  showCertCodeHint = false;

  @observable
  activeDay = 0;

  constructor() {
    this.service = new DoctorInfoService();
    this.init();
  }

  @action
  init = () => {
    this.service
      .getUserInfo()
      .then((res) => {
        if (res[0] && res[0].doctor_id) {
          const doctorId = res[0].doctor_id;
          this.doctorId = doctorId;
          this.service.getDoctorInfo().then((doctor) => {
            this.doctor = doctor;
            if (doctor.work_time) {
              this.weekSchedule = doctor.work_time;
            } else {
              const weekSchedule: DaySchedule[] = [];
              this.weekMap.forEach((_day, index) => {
                weekSchedule.push({
                  work_day: index + 1,
                  time: []
                });
              });
              this.weekSchedule = weekSchedule;
            }
          });
        }
      })
      .catch(() => {
        window.location.href = '/';
      });
  };

  @action
  selectDay = (index: number) => {
    this.activeDay = index;
    this.modalData = this.dayMap.map((range, number) => {
      const time = this.weekSchedule[index].time || [];
      console.log(time, number);
      const data: ModalDataItem = {
        checked: time.includes(`${number + 1}`),
        value: range.key
      };
      return data;
    });
    this.openModal();
  };

  @action
  selectRange = (index: number) => {
    if (this.modalData) {
      console.log(index, this.modalData[index].checked);
      this.modalData[index].checked = !this.modalData[index].checked;
      console.log(index, this.modalData[index].checked);
    }
  };

  @action
  openModal = () => {
    this.showModal = true;
  };

  @action
  closeModal = () => {
    this.showModal = false;
    this.modalData = undefined;
  };

  @action
  submit = () => {
    if (!this.modalData) {
      return;
    }
    const temp: string[] = [];
    this.modalData.forEach((item) => {
      if (item.checked === true) {
        temp.push(item.value);
      }
    });
    this.weekSchedule[this.activeDay].time = temp;
    console.log(temp);
    const weekSchedule = toJS(this.weekSchedule);
    console.log(weekSchedule);
    this.service
      .updateDoctorInfo({
        doctor_id: this.doctorId,
        flag: 'extra',
        work_time: weekSchedule,
        login_source: 9
      })
      .then(() => {
        this.closeModal();
        this.init();
      })
      .catch((err) => {
        console.error(err);
        this.closeModal();
        message.info('保存失败，请稍后重试');
        // do nothing
      });
  };
}

export default ScheduleStore;
