import React from 'react';
import RcDialog from 'rc-dialog';
import classNames from 'classnames';
import './index.css';

export const destroyFns: Array<() => void> = [];

export interface DialogProps {
  /** 对话框是否可见 */
  visible?: boolean;
  /** 确定按钮 loading */
  confirmLoading?: boolean;
  /** 标题 */
  title?: React.ReactNode | string;
  /** 是否显示右上角的关闭按钮 */
  closable?: boolean;
  /** 点击确定回调 */
  onOk?: (e: React.MouseEvent<HTMLElement>) => void;
  /** 点击模态框右上角叉、取消按钮、Props.maskClosable 值为 true 时的遮罩层或键盘按下 Esc 时的回调 */
  onCancel?: (e: React.MouseEvent<HTMLElement>) => void;
  afterClose?: () => void;
  /** 垂直居中 */
  centered?: boolean;
  /** 宽度 */
  width?: string | number;
  /** 底部内容 */
  footer?: React.ReactNode;
  /** 确认按钮文字 */
  okText?: React.ReactNode;
  /** 取消按钮文字 */
  cancelText?: React.ReactNode;
  /** 点击蒙层是否允许关闭 */
  maskClosable?: boolean;
  /** 强制渲染 Dialog */
  forceRender?: boolean;
  destroyOnClose?: boolean;
  style?: React.CSSProperties;
  wrapClassName?: string;
  maskTransitionName?: string;
  transitionName?: string;
  className?: string;
  zIndex?: number;
  bodyStyle?: React.CSSProperties;
  maskStyle?: React.CSSProperties;
  mask?: boolean;
  keyboard?: boolean;
  wrapProps?: any;
  prefixCls?: string;
  closeIcon?: React.ReactNode;
}

export interface DialogFuncProps {
  prefixCls?: string;
  className?: string;
  visible?: boolean;
  title?: React.ReactNode;
  content?: React.ReactNode;
  // TODO: find out exact types
  onOk?: (...args: any[]) => any;
  onCancel?: (...args: any[]) => any;
  centered?: boolean;
  width?: string | number;
  iconClassName?: string;
  okText?: React.ReactNode;
  cancelText?: React.ReactNode;
  icon?: React.ReactNode;
  /* Deprecated */
  iconType?: string;
  mask?: boolean;
  maskClosable?: boolean;
  zIndex?: number;
  okCancel?: boolean;
  style?: React.CSSProperties;
  maskStyle?: React.CSSProperties;
  type?: string;
  keyboard?: boolean;
  autoFocusButton?: null | 'ok' | 'cancel';
  transitionName?: string;
  maskTransitionName?: string;
}

export type DialogFunc = (
  props: DialogFuncProps
) => {
  destroy: () => void;
  update: (newConfig: DialogFuncProps) => void;
};

export interface DialogLocale {
  okText: string;
  cancelText: string;
  justOkText: string;
}

export default class Dialog extends React.Component<DialogProps, {}> {
  static info: DialogFunc;

  static success: DialogFunc;

  static error: DialogFunc;

  static warn: DialogFunc;

  static warning: DialogFunc;

  static confirm: DialogFunc;

  static destroyAll: () => void;

  static defaultProps = {
    width: 520,
    confirmLoading: false,
    visible: false,
    centered: true
  };

  handleCancel = (e) => {
    const { onCancel } = this.props;
    if (onCancel) {
      onCancel(e);
    }
  };

  handleOk = (e) => {
    const { onOk } = this.props;
    if (onOk) {
      onOk(e);
    }
  };

  renderFooter = () => {
    const { okText, cancelText } = this.props;
    return (
      <div className="dialog-footer-buttons">
        <button className="dialog-footer-button cacel" type="button" onClick={this.handleCancel}>
          {cancelText || '取消'}
        </button>
        <button className="dialog-footer-button ok" type="button" onClick={this.handleOk}>
          {okText || '确认'}
        </button>
      </div>
    );
  };

  render() {
    const {
      prefixCls = 'dialog',
      footer,
      visible,
      wrapClassName,
      centered = true,
      title,
      closable,
      children
    } = this.props;
    const defaultFooter = this.renderFooter();
    return (
      <RcDialog
        title={title}
        closable={closable}
        onClose={this.handleCancel}
        prefixCls={prefixCls || 'dialog'}
        wrapClassName={classNames({ [`${prefixCls}-centered`]: !!centered }, wrapClassName)}
        footer={footer === undefined ? defaultFooter : footer}
        visible={visible}
      >
        {children}
      </RcDialog>
    );
  }
}
