import React, { Component } from 'react';
import classNames from 'classnames/bind';
import { observer } from 'mobx-react';
import ScheduleStore from '@/stores/scheduleStore';

import styles from './index.module.css';

const cx = classNames.bind(styles);

@observer
class Schedule extends Component {
  store: ScheduleStore;

  constructor(props: Readonly<{}>) {
    super(props);
    this.store = new ScheduleStore();
  }

  componentDidMount() {
    document.title = '门诊时间';
  }

  render() {
    const {
      doctor,
      weekSchedule,
      weekMap,
      showModal,
      selectDay,
      modalData,
      closeModal,
      submit,
      dayMap,
      selectRange
    } = this.store;
    if (doctor) {
      return (
        <div className={`${styles.schedule} page`}>
          <div className={styles.week}>
            {weekSchedule &&
              weekSchedule.map((day, index) => {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <div className={styles.day} key={`${index}_dayindex`}>
                    <div className={styles.name}>{weekMap[index]}</div>
                    <div
                      className={styles.text}
                      onClick={() => {
                        selectDay(index);
                      }}
                    >
                      {day.time && day.time.length > 0 ? (
                        <span>
                          {day.time
                            .map((item) => {
                              return dayMap[parseInt(item, 10) - 1].value;
                            })
                            .join('，')}
                        </span>
                      ) : (
                        <span className={styles.placeholder}>请选择</span>
                      )}
                      <i className={styles.arrow} />
                    </div>
                  </div>
                );
              })}
          </div>
          <div className={styles.hint}>*请您按第一执业医院填写</div>
          {modalData && showModal && (
            <div className={styles.modalLayer}>
              <div className={styles.modalMask} onClick={closeModal} />
              <div className={styles.modalContainer}>
                <div className={styles.modal}>
                  <div className={styles.modalHead}>
                    <div className={styles.modalTitle}>时间段选择（多选）</div>
                    <div className={cx('modalBtn', 'submit')} onClick={submit}>
                      提交
                    </div>
                  </div>
                  <div className={styles.modalBody}>
                    <div className={styles.itemList}>
                      {modalData.map((item, itemIndex) => {
                        return (
                          <div
                            className={styles.item}
                            key={item.value}
                            onClick={() => {
                              selectRange(itemIndex);
                            }}
                          >
                            <div
                              className={cx({
                                checkbox: true,
                                checked: item.checked
                              })}
                            />
                            <span className={styles.itemText}>{dayMap[itemIndex].value}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      );
    }
    return null;
  }
}

export default Schedule;
