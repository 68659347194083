/**
 * @file signInService.ts 医者B端服务号登录、注册接口
 */

import axios from '@/utils/interceptors';
import CryptoJS from 'crypto-js';
import { isArray } from 'lodash';
import { APIPath, uploadAPIPath } from '../config/conf';

axios.defaults.withCredentials = true;

// import { APIPath, loginSource } from '../config/conf';
const PaddingLeft = (key: any, length: any) => {
  let pkey = key.toString();
  const l = pkey.length;
  if (l < length) {
    pkey = new Array(length - l + 1).join('0') + pkey;
  } else if (l > length) {
    pkey = pkey.slice(length);
  }
  return pkey;
};

const aseEncrypt = (msg: any, key: any) => {
  // key = PaddingLeft(key, 16); // 保证key的长度为16byte,进行'0'补位
  // key = CryptoJS.enc.Utf8.parse(PaddingLeft(key, 16));
  const key0 = CryptoJS.enc.Utf8.parse(PaddingLeft(PaddingLeft(key, 16), 16));
  const encrypted = CryptoJS.AES.encrypt(msg, key0, {
    iv: key0,
    mode: CryptoJS.mode.CBC, // CBC算法
    padding: CryptoJS.pad.Pkcs7 // 使用pkcs7 进行padding 后端需要注意
  });
  return encrypted.ciphertext.toString(CryptoJS.enc.Hex); // 后端必须进行相反操作
};
const getToken = () => {
  const timestamp = Math.round(new Date().getTime() / 1000);
  const msg = `${timestamp};e22674779c464244`;
  return aseEncrypt(msg, '186133958051');
};
interface VideoContent {
  video_img: string;
  video_url?: string;
  video_id: string;
  title: string;
  cate: string;
}

class VideoService {
  uploadVideo = (formData: FormData, ext: string) => {
    console.log('视频扩展类型', ext);
    
    return axios.post(`${uploadAPIPath}mobile/upload/video`, formData, {
      // headers: {
      //   type: ext
      // }
    });
  };

  addVideoArticle = (video_img: string, video_id: string, title: string) => {
    // cate：video, video_url: 视频id, title:标题
    const mode: VideoContent = { video_img, video_id, title, cate: 'video' };
    return axios.post(`${APIPath}doctorauth/singleinfo`, {
      ...mode,
      login_source: 9
    });
  };

  changeContentsStatus = (id: string | string[], action: string) => {
    let idList = id;
    if (!isArray(id)) {
      idList = [id];
    }
    return axios.put(
      `${APIPath}doctorauth/docinfo`,
      JSON.stringify({
        action,
        uid_list: idList,
        login_source: 9
      }),
      {
        headers: { 'Content-Type': 'application/json' }
      }
    );
  };
}

export default VideoService;
