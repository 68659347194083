import React, { Component } from 'react';
// import classNames from 'classnames/bind';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Menu, ActivityIndicator, NavBar } from 'antd-mobile';

import './select.css';


interface ContentPageProps {

}

@observer
class Selector extends Component<any,any> {
  @observable
  listLength?: number;

  constructor(props) {
    super(props);
    // this.listLength = this.props.pageData.length;
    this.state={
        initData:'',
        show: false,
        curLabel:'',
        curIdx:[],
        tplList:[],
        initObj:{

        }
    }
  }

  toP = (value) => {
    this.props.getMsg(value)
  }
  onChange = (value) => {
    // const {initData} = this.state;
    
    let label='';
    this.state.tplList.forEach((dataItem) => {
      if (dataItem.value === value[0]) {
        label = dataItem.label;
        this.setState({
            curLabel :label,
            curIdx:value,
            initObj:null,
            show: false,
        })
      }
    });
    this.toP(value)
    this.setState({
        show:false
    })
  }
  handleClick = (e) => {
      
    e.preventDefault(); // Fix event propagation on Android
    this.setState({
      show: !this.state.show,
    });
    const {tplList} = this.state;
    
    this.setState({
              initData: tplList,
            });

  }
  componentDidUpdate = () =>{

  }
  componentDidMount = () => {

  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.tplList !== prevState.tplList) {
      return {
        tplList:nextProps.tplList,
        initObj:nextProps.tplList[0]
      };
    }

    return null;
  }
  onMaskClick = () => {
    this.setState({
      show: false,
    });
  }
  render() {
    const { pageData, selectedRows, } = this.props;
    const { show,curLabel,curIdx,tplList,initObj } = this.state;
    
    // const {tplList,initObj} = this.props;
    
    const menuEl = (
      <Menu
        className="single-foo-menu"
        data={tplList}
        value={curIdx}
        level={1}
        onChange={this.onChange}
        // height={400}
      />
    );
    const loadingEl = (
      <div style={{ position: 'absolute', width: '100%', height: document.documentElement.clientHeight * 0.6, display: 'flex', justifyContent: 'center' }}>
        <ActivityIndicator size="large" />
      </div>
    );
    
    return (
      <div className="">
          <div className={show ? 'single-menu-active' : ''}>
            <div>
            {/* <NavBar
                // leftContent="Menu"
                mode="light"
                onLeftClick={this.handleClick}
                onClick={this.handleClick}
                className="single-top-nav-bar"
            >
                {/* OneLevel menu */}
                {/* {curLabel}
            </NavBar> */} 
            <div 
                className="single-top-nav-bar"
                onClick={this.handleClick}
                >
                   
                    <div className="cur-label">{initObj?initObj.label:curLabel}</div>
                   
                    
                </div>
            </div>
            {/* <div className="menus">
                <div className="menus-item">
                    12345
                </div>
            </div> */}
            {/* <img className="dropdown" src={dropImg} alt=""/> */}
            {show ? tplList ? menuEl : loadingEl : null}
            {show ? <div className="menu-mask" onClick={this.onMaskClick} /> : null}
        </div>
      </div>
    );
  }
}

export default  Selector;
