// import Tea from 'byted-tea-sdk';

interface SourceAndMedium {
  utm_medium: string;
  utm_source: string;
}

export function myAajx(options: any): void {
  let { url } = options;
  const method = options.method.toLocaleLowerCase() || 'get';
  const { data } = options;
  const xhr = new XMLHttpRequest();
  if (options.timeout && options.timeout > 0) {
    xhr.timeout = options.timeout;
  }
  // 超时
  xhr.ontimeout = () => {
    console.log('请求超时');
  };
  // 成功回调
  xhr.onreadystatechange = () => {
    if (xhr.readyState === 4) {
      options.success(xhr.responseText);
    }
  };
  // 回调失败
  xhr.onerror = () => {
    console.log('error');
  };

  // 类型判断
  const isType = (type: string) => (target: string) =>
    `[object ${type}]` === Object.prototype.toString.call(target);

  if (method === 'get' && isType('Object')(data)) {
    const paramsStr = Object.keys(data).map((key) => {
      return `&${key}=${data[key]}`;
    });
    if (decodeURIComponent(url).includes('?')) {
      url += paramsStr.join('');
    } else {
      url += `?${paramsStr.join('').slice(1)}`;
    }
  }
  if (method === 'get') {
    xhr.send(null);
  }
  if (method === 'post') {
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.send(JSON.stringify(data));
  }
}

export function getTiming(): void {
  try {
    const time = performance.timing;
    const timingObj = {};

    const loadTime = (time.loadEventEnd - time.loadEventStart) / 1000;

    if (loadTime < 0) {
      setTimeout(function () {
        getTiming();
      }, 200);
      return;
    }

    timingObj['重定向时间'] = (time.redirectEnd - time.redirectStart) / 1000;
    timingObj['DNS解析时间'] =
      (time.domainLookupEnd - time.domainLookupStart) / 1000;
    timingObj['TCP完成握手时间'] = (time.connectEnd - time.connectStart) / 1000;
    timingObj['HTTP请求响应完成时间'] =
      (time.responseEnd - time.requestStart) / 1000;
    timingObj['DOM开始加载前所花费时间'] =
      (time.responseEnd - time.navigationStart) / 1000;
    timingObj['DOM加载完成时间'] = (time.domComplete - time.domLoading) / 1000;
    timingObj['DOM结构解析完成时间'] =
      (time.domInteractive - time.domLoading) / 1000;
    timingObj['脚本加载时间'] =
      (time.domContentLoadedEventEnd - time.domContentLoadedEventStart) / 1000;
    timingObj['onload事件时间'] =
      (time.loadEventEnd - time.loadEventStart) / 1000;
    timingObj['页面完全加载时间'] =
      timingObj['重定向时间'] +
      timingObj['DNS解析时间'] +
      timingObj['TCP完成握手时间'] +
      timingObj['HTTP请求响应完成时间'] +
      timingObj['DOM结构解析完成时间'] +
      timingObj['DOM加载完成时间'];

    // for (const item in timingObj) {
    //   console.log(`${item}:${timingObj[item]}毫秒(ms)`);
    // }
  } catch (e) {
    console.log(e);
  }
}

export function getQueryString(name: string): null | string {
  const reg = new RegExp(`(^|&)${name}([^&]*)(&|$)`, 'i');
  const r = window.location.search.substr(1).match(reg);
  if (r != null) {
    return decodeURIComponent(r[2]);
  }
  return null;
}

// 设置标题
export function setTitleAndContent(title: string, content: string): void {
  document.title = title;
  if (document.querySelector('meta[name="description"]')) {
    (document.querySelector(
      'meta[name="description"]'
    ) as Element).setAttribute('content', content);
  }
}

// 是否是app
export function isApp(): boolean {
  // 判断端内端外
  const LSGAppMainReg = /codeshake\/lvsongguo\/main\/([\d+.]+)/i;
  const UA = navigator.userAgent;
  return (
    LSGAppMainReg.test(UA) || window.location.href.indexOf('debug=lsg_app') > -1
  );
}

function getMediumAndSource(): SourceAndMedium {
  const medium = 'xiao-he-h5';
  const source = 'toutiao-search';

  const { searchParams } = new URL(window.location.href);
  const utm_medium: string = searchParams.get('utm_medium') || medium;
  const utm_source: string = searchParams.get('utm_source') || source;
  return {
    utm_medium,
    utm_source
  };
}

export function getIOSVersion(): string {
  const ua = navigator.userAgent.toLowerCase();
  const sys = ua.match(/iphone/i);
  if (sys && sys.length > 0 && sys[0] === 'iphone') {
    const curVersion = ua.match(/cpu iphone os (.*?) like mac os/);
    return curVersion && curVersion.length > 1
      ? curVersion[1].replace(/_/g, '.')
      : '';
  } 
  return '';
}

export function isCurBand(type: string): boolean {
  const ua = navigator.userAgent.toLowerCase();
  const exp = new RegExp(type, 'i');
  const sys = ua.match(exp);
  if (sys && sys.length > 0 && sys[0] === `${type}`) {
    return true;
  }
  return false;
}

function openScheme(scheme: string) {
  const frame = document.createElement('iframe');
  frame.src = scheme;
  frame.style.display = 'none';
  document.body.appendChild(frame);
  window.setTimeout(function () {
    document.body.removeChild(frame);
  }, 500);
}

// 统计打点
export function tongji(action = ''): void {
  try {
    const { utm_medium, utm_source } = getMediumAndSource();
    const url = window.location.href;
    const reffer = document.referrer || '';
    const { title } = document;
    const { host } = window.location;
    const eventParams = {
      origin_utm_medium: utm_medium,
      origin_utm_source: utm_source,
      action,
      url,
      reffer,
      title,
      host
    };
    // Tea('general_event', eventParams);
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = `https://auroralog.songguojiankang.com/lsglogapi.php
    ?action=${action}&utm_medium=${utm_medium}&utm_source=${utm_source}&url=${url}&title=${title}&host=${host}`;
    document.body.insertBefore(script, document.body.firstChild);
  } catch (error) {
    console.log(error);
  }
}
export function copyText(text: string): void {
  let input: any = document.getElementById('copyInput');
  if (!input) {
    input = document.createElement('input');
    input.id = 'copyInput';
    input.readOnly = 'readOnly'; // 防止ios聚焦触发键盘事件
    input.style.position = 'absolute';
    input.style.left = '-1000px';
    input.style.zIndex = '-1000';
    document.body.appendChild(input);
  }
  input.value = text;
  function selectText(textbox: any, startIndex: number, stopIndex: number) {
    if (textbox.createTextRange) {
      // ie
      const range = textbox.createTextRange();
      range.collapse(true);
      range.moveStart('character', startIndex); // 起始光标
      range.moveEnd('character', stopIndex - startIndex); // 结束光标
      range.select(); // 不兼容苹果
    } else {
      // firefox/chrome
      textbox.setSelectionRange(startIndex, stopIndex);
      textbox.focus();
    }
  }
  // ios必须先选中文字且不支持 input.select();
  selectText(input, 0, text.length);
  if (document.execCommand('copy')) {
    document.execCommand('copy');
  } else {
    console.log('不兼容');
  }
  input.blur();
  // input自带的select()方法在苹果端无法进行选择，所以需要自己去写一个类似的方法
  // 选择文本。createTextRange(setSelectionRange)是input方法
};

// 下载app
export async function downloadApp(action = '', params: any = {}) {
  console.log('下载');
  const { utm_medium, utm_source } = getMediumAndSource();
  const paramsData: any = { act: action, utm_medium, utm_source };
  if (params.orderId) {
    paramsData.dispatch = 'inquiry';
    paramsData.content = {
      order_id: params.orderId, // 订单id，必传
      url:
        `lvsongguo:/WebView?url=${ encodeURIComponent(`https://meihejia.xiangyujiankang.com/index.html?utm_source=lvsongguoAPP#/order/details/${params.orderId}`)}&need-login`, // 问诊url，必传，示例https://meihejia.xiangyujiankang.com/index.html?utm_source=lvsongguoAPP#/order/details/10563012908
      doctor_id: params.doctorId ? params.doctorId : ''
    };
  } else if (params.doctorId) {
    paramsData.dispatch = 'doctor';
    paramsData.content = {
      // dispatch为inquiry必传
      doctor_id: params.doctorId, // 订单id，必传
      pd: 'nethosp'
    };
  }
  let clipboardid: string;
  try {
    myAajx({
      url: 'https://ad.jiemoselect.com/operation/clipboardid',
      method: 'post',
      async: false,
      data: paramsData,
      success: (paramsData0: any) => {
        const clipboardid0 = JSON.parse(paramsData0).data.id as string;
        copyText(clipboardid0);
        // const aa = encodeURIComponent(JSON.stringify({ clipboard: clipboardid }))
        if (action) {
          tongji(
            action
            // `&ext_clipboard=${clipboardid0}&extend_map=${aa}`
          );
        } else {
          tongji(
            'downloadapp'
            // `&ext_clipboard=${clipboardid0}&extend_map=${aa}`
          );
        }
      }
    });
  } catch (error) {
    console.log(error);
  }

  const isIphone = isCurBand('iphone');
  const isHuawei = isCurBand('huawei');
  const isHonor = isCurBand('honor');
  const isOppo = isCurBand('oppo');
  const isOppoR15 = isCurBand('pacm00');
  const isVivo = isCurBand('vivo');
  const isXiaomi = isCurBand('mi');
  const isXiaomi2s = isCurBand('mix');
  const isRedmi = isCurBand('redmi');
  const isSamsung = isCurBand('sm');
  if (params.jurl) {
    const { jurl } = params;
    const iosVer = getIOSVersion();
    if (jurl === 'medicalService') {
      const openTempUrl = 'lvsongguo:/HomePage?tab=/MedicalService';
      const scheme = openTempUrl;
      openScheme(scheme);
    } else if (
      jurl.indexOf('medicaldiseasedetail') > -1 ||
      jurl.indexOf('medicaldrugdetail') > -1 ||
      jurl.indexOf('medicalsymptomdetail') > -1 ||
      jurl.indexOf('medicalvaccindetail') > -1 ||
      jurl.indexOf('medicalindex') > -1
    ) {
      const openTempUrl = jurl.replace(/\\/g, '');
      const scheme = openTempUrl;
      openScheme(scheme);
    } else if (iosVer && iosVer.indexOf('13') > -1) {
      window.location.href =
          'https://apps.apple.com/app/apple-store/id1483317652?pt=120534449&ct=songguowenzhen_appdownload_banner&mt=8';
    } else {
      const scheme = 'lvsongguo:/HomePage?tab=/FeedList';
      openScheme(scheme);
    }
  }

  setTimeout(() => {
    if (isIphone) {
      window.location.href =
        'https://apps.apple.com/app/apple-store/id1483317652?pt=120534449&ct=songguowenzhen_appdownload_banner&mt=8';
    } else if (isHuawei || isHonor) {
      const scheme = 'appmarket://details?id=com.lvsongguo';
      openScheme(scheme);
      setTimeout(() => {
        window.location.href =
          'https://a.app.qq.com/o/simple.jsp?pkgname=com.lvsongguo';
      }, 300);
    } else if (isOppo || isOppoR15) {
      const scheme = 'oppomarket://details?packagename=com.lvsongguo';
      openScheme(scheme);
      setTimeout(() => {
        window.location.href =
          'https://a.app.qq.com/o/simple.jsp?pkgname=com.lvsongguo';
      }, 300);
    } else if (isVivo) {
      setTimeout(() => {
        window.location.href =
          'https://a.app.qq.com/o/simple.jsp?pkgname=com.lvsongguo';
      }, 300);
    } else if (isXiaomi || isRedmi || isXiaomi2s) {
      // let scheme = `mimarket://details?id=com.lvsongguo&back=true`;
      // openScheme(scheme);
      setTimeout(() => {
        window.location.href =
          'https://a.app.qq.com/o/simple.jsp?pkgname=com.lvsongguo';
      }, 300);
    } else if (isSamsung) {
      setTimeout(() => {
        window.location.href =
          'https://a.app.qq.com/o/simple.jsp?pkgname=com.lvsongguo';
      }, 300);
    } else {
      const UA = navigator.userAgent;
      const UAWhiteListReg = /\(.*Android.*(one|sony|HTC|Lenovo).*\)/i;
      if (!UAWhiteListReg.test(UA)) {
        openScheme('vivomarket://details?id=com.lvsongguo');
        setTimeout(() => {
          window.location.href =
            'https://a.app.qq.com/o/simple.jsp?pkgname=com.lvsongguo';
        }, 300);
      } else {
        setTimeout(() => {
          window.location.href =
            'https://a.app.qq.com/o/simple.jsp?pkgname=com.lvsongguo';
        }, 300);
      }
    }
  }, 400);
}
// 发送打点日志
export function sendLogParam(paramobj: any): void {
  myAajx({
    method: 'GET',
    url: 'https://www.lvsongguo.com/lsglogapi.php',
    dataType: 'json',
    data: paramobj
  });
  myAajx({
    method: 'GET',
    url: 'https://auroralog.songguojiankang.com/lsglogapi.php',
    dataType: 'json',
    data: paramobj
  });
}
