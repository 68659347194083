import React, { Component } from 'react';
import { observable, action, computed } from 'mobx';
import { Link } from 'react-router-dom';
import { createForm } from 'rc-form';
import { observer } from 'mobx-react';
import { Picker, List } from 'antd-mobile';
import Modal from '@/components/modal';
import agreement from '@/const/agreement';
import xhagreement from '@/const/xhagreement';
import { provinceData } from '@/config/provinceData';
import EditStore from '@/stores/editStore';
import message from '@/components/message';
import MultiInput from '@/components/multiInput';
import styles from './index.module.css';
import './index.less';

const provinceDataCache = {};
interface TextareaProps {
  name: string;
  title: string;
  value: string;
  placeholder?: string;
  maxLength?: number;
  onChange(arg0: string, arg1: string): void;
  onBlur(): void;
}
interface IProps {
  form: any,
}
const Textarea: React.FC<TextareaProps> = ({
  name,
  title,
  value,
  placeholder,
  maxLength = 500,
  onChange,
  onBlur
}) => (
  <div className={styles.textareaContainer}>
    <div className={styles.head}>
      <div className={styles.title}>
        {title}
        {name === 'specialize' && <span className={styles.attention}>*</span>}
      </div>
      <div className={styles.maxHint}>{`最多输入${maxLength}字`}</div>
    </div>
    <div className={styles.wrapper}>
      <textarea
        className={styles.textarea}
        name={name}
        id={name}
        value={value}
        placeholder={placeholder}
        maxLength={maxLength}
        onChange={(event) => {
          console.log(event.target.value, name);
          onChange(event.target.value, name);
        }}
        onBlur={onBlur}
      />
    </div>
  </div>
);

const TextareaNumber: React.FC<TextareaProps> = ({
  name,
  title,
  value,
  placeholder,
  maxLength = 15,
  onChange,
  onBlur
}) => (
  <div className={styles.textareaContainer}>
    <div className={styles.head}>
      <div className={styles.title}>
        {title}
        {name === 'specialize' ||
          (<span className={styles.attention}>*</span>)}
      </div>
      <div className={styles.maxHint}>{`${maxLength}位数字`}</div>
    </div>
    <div className={styles.wrapper_number}>
      <textarea
        className={styles.textarea}
        name={name}
        id={name}
        value={value}
        placeholder={placeholder}
        maxLength={maxLength}
        onChange={(event) => {
          console.log(event.target.value, name);
          onChange(event.target.value, name);
        }}
        onBlur={onBlur}
      />
    </div>
  </div>
);

@observer
class Card extends Component<IProps> {
  // service: DoctorInfoService;

  store: EditStore;

  hint = '请确认您的信息，带*为必填项';

  @observable
  checked = false;

  basicInfoFormText = {
    headimg_url: ['头像'],
    name: ['姓名', '请填写姓名'],
    grade_name: ['聘用职称', '如主任/副主任/主治等'],
    edugrade_name: ['教学职级', '如教授/副教授等'],
    cert_code: ['医师执业证书', '15位数字'],
    id_num: ['身份证号码', ''],
    intro: ['个人简介', '最少输入20字'],
    specialize: ['专业擅长', '最少输入20字'],
    idcard_img_front: ['身份证人像面'],
    idcard_img_obverse: ['身份证国徽面'],
    cert_img: ['医师资格证书照片（人像面）'],
    grade_img: ['职称证书照片（人像面）'],
  };

  basicInfoFormTextTwo = {
    hospital: ['所在医院', '请填写医院全称'],
    hospital_level: ['医院级别', ''],
    province_city_area: ['医院所在地', '请选择省、市、区'],
    department: ['行政科室', '请填写科室全称'],
    specialty: ['专业方向', '请输入专业方向'],
    // schedule: ['门诊时间', '点击选择'],
    recommend_institutions_name: ['推荐机构', '请填写推荐机构']
  };

  extraInfoFormText = {
    // specialize: {
    //   type: 'textarea',
    //   text: ['专业擅长', '主要领域...']
    // },
    // intro: {
    //   type: 'textarea',
    //   text: ['个人简介', '主要经历...']
    // },
    multiWork: {
      type: 'multiInput',
      text: ['执业机构', '执业机构']
    },
    honor: {
      type: 'multiInput',
      text: ['荣誉称号', '荣誉']
    },
    workExperience: {
      type: 'multiInput',
      text: ['执业履历（主要工作经历）', '履历']
    }
  };

  methods: any;

  // fileInput: React.RefObject<HTMLInputElement>;
  hideArea = [
    'idcard_img_front',
    'idcard_img_obverse',
    'cert_img',
    'grade_img',
    'province_city_area',
    'specialty'
  ];


  constructor(props: Readonly<IProps>) {
    super(props);
    this.store = new EditStore();
    this.methods = this.getMethods();
  }

  // UNSAFE_componentWillMount() {
  //   window.TEA('主页', 'editors');
  // }

  componentDidMount() {
    document.title = '编辑个人名片';
    this.methods.initProvince();
  }
  getMethods() {
    return {
      initProvince: () => {
        // 广度非递归遍历
        const travelWidely = (roots) => {
          const queue = [...roots];
          while (queue.length) {
            const node = queue.shift();
            if (node) {
              node.label = node.name;
              node.value = node.code;
              provinceDataCache[node.code] = node;
            }
            if (node.children && node.children.length) {
              queue.push(...node.children);
            }
          }
        };
        travelWidely(provinceData);
      },
      // 区域选择确定
      pickOnOk: (val: string[]) => {
        const hospitalLocation: any = {};
        if (val && val[0]) {
          hospitalLocation.province = {
            id: provinceDataCache[val[0]].value,
            name: provinceDataCache[val[0]].name,
          }
        }
        if (val && val[1]) {
          hospitalLocation.city = {
            id: provinceDataCache[val[1]].value,
            name: provinceDataCache[val[1]].name,
          }
        }
        if (val && val[2]) {
          hospitalLocation.area = {
            id: provinceDataCache[val[2]].value,
            name: provinceDataCache[val[2]].name,
          }
        }
        if (this.store.doctor && this.store.doctor.hospital) {
          this.store.doctor.hospital.hospital_location = hospitalLocation;
        }
      },
      pickOnDismiss: () => {
      },
    }
  }
  @computed
  get basicInfo() {
    if (this.store.doctor) {
      const {
        name,
        grade_name,
        cert_code,
        id_num,
        intro = '',
        specialize = '',
        headimg_url,
        idcard_img_front,
        idcard_img_obverse,
        cert_img,
        grade_img,
        specialty,
        state,
        hospital,
        edugrade_name,
        recommend_institutions_name,
      } = this.store.doctor;
      const { hospital_location = {} } = hospital || {};
      const { province, city, area } = hospital_location || {};
      const province_city_area: any[] = [];
      if (province && province.id) {
        province_city_area.push(province.id);
      }
      if (city && city.id) {
        province_city_area.push(city.id);
      }
      if (area && area.id) {
        province_city_area.push(area.id);
      }
      return {
        name,
        grade_name,
        cert_code,
        id_num,
        intro: intro ? intro.slice(0, 500) : '',
        specialize,
        headimg_url: +state > 0 ? headimg_url : undefined,
        idcard_img_front,
        idcard_img_obverse,
        cert_img,
        grade_img,
        specialty,
        edugrade_name,
        hospital: hospital.name,
        province_city_area,
        hospital_level: hospital.level,
        department: hospital.department ? hospital.department.name : '',
        recommend_institutions_name
      };
    }
    return {};
  }

  @computed
  get extraInfo() {
    if (this.store.doctor) {
      const {
        specialize = '',
        intro = '',
        multi_working_hospital,
        work_experience,
        designation
      } = this.store.doctor;
      const workExperience =
        work_experience && work_experience.length > 0
          ? work_experience.map((experience) => experience.description)
          : [''];
      const multiWork =
        multi_working_hospital && multi_working_hospital.length > 0
          ? multi_working_hospital.map((hospital) => hospital.name)
          : [''];
      const honor =
        designation && designation.length > 0 ? designation.map((item) => item.description) : [''];
      return {
        specialize,
        intro: intro ? intro.slice(0, 500) : '',
        multiWork,
        workExperience,
        honor
      };
    }
    return {};
  }

  @computed
  get basicKeys() {
    return Object.keys(this.basicInfoFormText).filter((key) => !this.hideArea.includes(key));
  }

  @computed
  get basicKeysTwo() {
    return Object.keys(this.basicInfoFormTextTwo).filter((key) => !this.hideArea.includes(key));
  }

  @computed
  get extraKeys() {
    return Object.keys(this.extraInfoFormText).filter((key) => !this.hideArea.includes(key));
  }

  @action
  handleMultiInputChange = (values, name) => {
    let newValue;
    if (this.store.doctor) {
      if (name === 'multiWork') {
        newValue = values.map((value) => {
          return { name: value };
        });
        this.store.doctor.multi_working_hospital = newValue;
      } else {
        newValue = values.map((value) => {
          return { description: value };
        });
        if (name === 'honor') {
          this.store.doctor.designation = newValue;
        } else {
          this.store.doctor.work_experience = newValue;
        }
      }
    }
    this.extraInfo[name] = values;
  };

  @action
  handleBasicInput = (event) => {
    const { value, name } = event.target;
    if (this.store.doctor) {
      if (name === 'hospital') {
        this.store.doctor.hospital.name = value;
      } else if (name === 'department') {
        this.store.doctor.hospital.department = {
          name: value
        };
      } else {
        this.store.doctor[name] = value;
      }

      if (name === 'cert_code') {
        // if (!value || value.length < 15) {
        //   this.store.showCertHint();
        // } else {
        //   this.store.hideCertHint();
        // }
        // if (value.length > 14) {
        //   this.store.hideCertHint();
        // }
      }
    }
  };

  @action
  uploadAvatar = (e: any, key: string) => {
    const { target } = e;
    if (target && target.files) {
      const formData = new FormData();
      formData.append('file', target.files[0]);
      this.store.upload(formData, key);
    }
  };

  @action
  handleTextareaChange = (value, name) => {
    if (this.store.doctor) {
      this.store.doctor[name] = value;
    }
  };

  @action
  handleCheck = () => {
    this.checked = !this.checked;
  };

  submit = () => {
    const { doctor, oldUserNeedAuth } = this.store;

    if (doctor && (doctor.state === '0' || oldUserNeedAuth)) {
      if (!this.checked) {
        message.info('请阅读并同意《用户注册协议》《第三方数据共享隐私声明》');
        return;
      }
    }
    this.store.submit();
  };

  handleBlur = () => {
    setTimeout(() => {
      // window.scrollTo(0, 0);
    }, 50);
  };

  // handleCodeBlur = () => {
  //   const { showCertHint, hideCertHint, doctor } = this.store;
  //   if (doctor) {
  //     if (!doctor.cert_code || doctor.cert_code.length < 15) {
  //       showCertHint();
  //     } else {
  //       hideCertHint();
  //     }
  //   }
  // };

  render() {
    const { methods } = this;
    const { getFieldProps } = this.props.form;
    const { doctor, showCertCodeHint, oldUserNeedAuth } = this.store;
    const scheduleArr = [1, 2, 3, 4, 5, 6, 7, 8];
    const weekMap = ['周一', '周二', '周三', '周四', '周五', '周六', '周日'];
    if (doctor) {
      return (
        <div className={`${styles.edit} page edit-page`}>
          <div className={styles.edit_tips}>基本信息，带*为必填</div>
          <form className={styles.form}>
            <div className={styles.basicInfo}>
              {this.basicKeys.map((key, index) => {
                if (
                  key === 'idcard_img_front'
                  || key === 'idcard_img_obverse'
                  || key === 'cert_img'
                  || key === 'grade_img'
                ) {
                  return (
                    <div key={key + index}>
                      <label className={styles.uploadLabel} key={key} htmlFor={key}>
                        <span className={`${styles.uploadLabelInputTitle} ${styles.inputTitleRequired}`}>
                          {this.basicInfoFormText[key][0]}
                        </span>
                        {doctor[key]
                          ? (
                            <div className={styles.uploadImgBox}>
                              <img src={doctor[key]} />
                            </div>
                          )
                          : <div className={styles.uploadBox}>
                            <div className={styles.uploadAddIcon}>
                              <span className={styles.addIconTxt}>+</span>
                            </div>
                            <div className={styles.clickUpTxt}>点击上传</div>
                          </div>
                        }
                        <input
                          style={{ display: 'none' }}
                          id={key}
                          className={styles.inputUpload}
                          type="file"
                          accept="image/*"
                          onChange={(e) => this.uploadAvatar(e, key)}
                        />
                      </label>
                    </div>
                  )
                }
                if (key === 'headimg_url') {
                  return (
                    <label className={styles.label} key={key} htmlFor={key}>
                      <span className={`${styles.inputTitle} ${styles.inputTitleRequired}`}>
                        {this.basicInfoFormText[key][0]}
                      </span>
                      {doctor.headimg_url && (
                        <img src={doctor.headimg_url} className={styles.avatar} alt="avatar" />
                      )}
                      <input
                        style={{ display: 'none' }}
                        id={key}
                        className={styles.input}
                        type="file"
                        accept="image/*"
                        // ref={this.fileInput}
                        onChange={(e) => this.uploadAvatar(e, key)}
                      />
                    </label>
                  );
                }
                if (key === 'cert_code' || key === 'id_num' || key === 'specialize' || key === 'intro') {
                  return (
                    <div key={`${key}_kss`}>
                      {(key === 'cert_code' || key === 'id_num') && (
                        <div key={`${key}_ks`} className={styles.textbox_line} />
                      )}
                      <div key={key} className={styles.textbox}>
                        <div className={styles.textbox_head}>
                          <span className={styles.textbox_title}>
                            {this.basicInfoFormText[key][0]}
                          </span>
                          <span className={styles.textbox_dis}>
                            {this.basicInfoFormText[key][1]}
                          </span>
                        </div>
                        <div
                          className={
                            (key === 'cert_code' || key === 'id_num') ? styles.textbox_ipnumber : styles.textbox_iput
                          }
                        >
                          <textarea
                            className={styles.textbox_textarea}
                            name={key}
                            key={key}
                            onChange={this.handleBasicInput}
                            value={this.basicInfo[key] || ''}
                          />
                        </div>
                      </div>
                    </div>
                  );
                }
                const titleCls =
                  key === 'edugrade_name' ||
                    key === 'specialty' ||
                    key === 'hospital_level' ||
                    key === 'recommend_institutions_name'
                    ? styles.inputTitle
                    : `${styles.inputTitle} ${styles.inputTitleRequired}`;

                return (
                  <label
                    className={styles.label}
                    key={key}
                    htmlFor={key}
                  >
                    <span className={titleCls}>{this.basicInfoFormText[key][0]}</span>
                    {key === 'recommend_institutions_name' ? (
                      <span className={styles.iptSpan}>{this.basicInfo[key] || ''}</span>
                    ) : (
                        <input
                          id={key}
                          readOnly={key === 'hospital_level' || key === 'recommend_institutions_name'}
                          style={{
                            color:
                              key === 'hospital_level' || key === 'recommend_institutions_name'
                                ? '#bbb'
                                : '#333'
                          }}
                          className={styles.input}
                          type="text"
                          name={key}
                          value={this.basicInfo[key] || ''}
                          onChange={this.handleBasicInput}
                          onBlur={this.handleBlur}
                          placeholder={this.basicInfoFormText[key][1]}
                        />
                      )}
                  </label>
                );
              })}
            </div>
            <div className={styles.baseline} />
            <div className={styles.basicInfo}>
              {this.basicKeysTwo.map((key) => {
                const titleCls =
                  key === 'hospital_level' ||
                    key === 'recommend_institutions_name'
                    || key === 'hospital'
                    || key === 'department'
                    ? styles.inputTitle
                    : `${styles.inputTitle} ${styles.inputTitleRequired}`;

                if (key === 'province_city_area') {
                  const pcaVal = this.basicInfo[key] || [];
                  return (
                    <label
                      className={`${styles.label} ${styles.pickerLable} ${pcaVal && pcaVal.length > 0 ? 'picker-value' : ''}`}
                      key={key}
                    >
                      <Picker extra={this.basicInfoFormTextTwo[key][1]}
                        data={provinceData}
                        title="省市区"
                        {...getFieldProps('district', {
                          initialValue: pcaVal,
                        })}
                        onOk={methods.pickOnOk}
                        onDismiss={methods.pickOnDismiss}
                      >
                        <List.Item>
                          <span className={titleCls}>{this.basicInfoFormTextTwo[key][0]}</span>
                        </List.Item>
                      </Picker>
                    </label>
                  )
                }
                return (
                  <label
                    className={styles.label}
                    key={key}
                    htmlFor={key}
                  >
                    <span className={titleCls}>{this.basicInfoFormTextTwo[key][0]}</span>
                    {key === 'recommend_institutions_name' ? (
                      <span className={styles.iptSpan}>{this.basicInfo[key] || ''}</span>
                    ) : (
                        <input
                          id={key}
                          readOnly={key === 'hospital_level' || key === 'recommend_institutions_name' || key === 'hospital' || key === 'department'}
                          style={{
                            color:
                              key === 'hospital_level' || key === 'recommend_institutions_name' || key === 'hospital' || key === 'department'
                                ? '#bbb'
                                : '#333'
                          }}
                          className={styles.input}
                          type="text"
                          name={key}
                          value={this.basicInfo[key] || ''}
                          onChange={this.handleBasicInput}
                          onBlur={this.handleBlur}
                          placeholder={this.basicInfoFormTextTwo[key][1]}
                        />
                      )}
                  </label>
                );
              })}
            </div>
            <div className={styles.extraInfo}>
              {this.extraKeys.map((key) => {
                const { type, text } = this.extraInfoFormText[key];
                if (type === 'textarea') {
                  return (
                    <Textarea
                      key={key}
                      name={key}
                      title={text[0]}
                      placeholder={text[1]}
                      value={this.extraInfo[key]}
                      onChange={this.handleTextareaChange}
                      onBlur={this.handleBlur}
                    />
                  );
                }
                if (type === 'textarea_number') {
                  return (
                    <TextareaNumber
                      key={key}
                      name={key}
                      title={text[0]}
                      placeholder={text[1]}
                      value={this.extraInfo[key]}
                      onChange={this.handleTextareaChange}
                      onBlur={this.handleBlur}
                    />
                  );
                }

                if (type === 'multiInput') {
                  return (
                    <MultiInput
                      name={key}
                      type={key}
                      key={key}
                      title={text[0]}
                      values={this.extraInfo[key]}
                      placeholder={text[1]}
                      onChange={this.handleMultiInputChange}
                    />
                  );
                }
                return null;
              })}
            </div>
            <div style={{ height: '30px', background: '#fff' }} />
          </form>
          <div className={styles.botm}>
            {(doctor.state === '0' || oldUserNeedAuth) && (
              <div className={styles.agree}>
                <div onClick={this.handleCheck} className={`${styles.checkbox} ${this.checked ? styles.checked : ''}`} />
                <div className={styles.agreeText}>
                  <span onClick={this.handleCheck}>我已阅读并同意</span>
                  <span className={styles.highlight} onClick={this.store.showAgreement}>
                    《用户注册协议》
                  </span><span className={styles.highlight} onClick={this.store.showXhAgreement}>
                    《第三方数据共享隐私声明》
                  </span>
                </div>
              </div>
            )}
            <div className={styles.button} onClick={() => {
              //window.TEA('编辑个人名片', 'submit');
              this.submit();
            }}>
              提交
            </div>
          </div>
          <Modal
            open={this.store.agreementOpen}
            title="用户注册协议"
            content={agreement.split('\n')}
            onClose={this.store.closeAgreement}
          />
          <Modal
            open={this.store.xhAgreementOpen}
            title="第三方数据共享隐私声明"
            content={xhagreement.split('\n')}
            onClose={this.store.closeXhAgreement}
          />
        </div>
      );
    }
    return null;
  }
}

export default createForm()(Card);
