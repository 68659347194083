import { observable, action } from 'mobx';
import message from '../components/message';
import DoctorInfoService from '../services/doctorInfoService';
import Doctor from '../types/doctor';

interface ShareMode {
  url: string;
  name: string;
}
class ShareStore {
  service: DoctorInfoService;

  constructor() {
    this.service = new DoctorInfoService();
  }

  @observable
  doctor: ShareMode | undefined;

  @action
  getDoctorCard(id: string): void {
    this.service
      .getDoctorCard(id)
      .then((res) => {
        this.doctor = res;
        console.log('tp:', res);
      })
      .catch(() => {
        window.location.href = '/';
      });
  }
}

export default ShareStore;
