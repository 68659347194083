/**
 * @file signInService.ts 医者B端服务号登录、注册接口
 */
import { isArray } from 'lodash';
import Axios from '@/utils/interceptors';
import CryptoJS from 'crypto-js';
import { APIPath, uploadAPIPath } from '@/config/conf';

// import { APIPath, loginSource } from '../config/conf';
Axios.defaults.withCredentials = true;
const PaddingLeft = (key: any, length: any) => {
  let pkey = key.toString();
  const l = pkey.length;
  if (l < length) {
    pkey = new Array(length - l + 1).join('0') + pkey;
  } else if (l > length) {
    pkey = pkey.slice(length);
  }
  return pkey;
};

const aseEncrypt = (msg: any, key: any) => {
  // key = PaddingLeft(key, 16); // 保证key的长度为16byte,进行'0'补位
  // key = CryptoJS.enc.Utf8.parse(PaddingLeft(key, 16));
  const key0 = CryptoJS.enc.Utf8.parse(PaddingLeft(PaddingLeft(key, 16), 16));
  const encrypted = CryptoJS.AES.encrypt(msg, key0, {
    iv: key0,
    mode: CryptoJS.mode.CBC, // CBC算法
    padding: CryptoJS.pad.Pkcs7 // 使用pkcs7 进行padding 后端需要注意
  });
  return encrypted.ciphertext.toString(CryptoJS.enc.Hex); // 后端必须进行相反操作
};
const getToken = () => {
  const timestamp = Math.round(new Date().getTime() / 1000);
  const msg = `${timestamp};e22674779c464244`;
  return aseEncrypt(msg, '186133958051');
};
export interface File {
  name: string;
  url: string;
}

export interface ItemData {
  cate: string;
  doc_id: string;
  title: string;
  state: number;
  snippet: string;
  update_time: string;
  fromsite?: string;
  file?: File;
}

export interface Result {
  type: string;
  data: {
    doc_count: number;
    doc_list: ItemData[];
  };
}

export interface Video {
  src: string;
  poster: string;
}
export interface Voice {
  src: string;
  duration: string;
}

export interface NewContent {
  cate: string;
  title: string;
  content?: string;
  file?: File;
  qcontent?: string;
  content_raw?: string;
  template_id?: any;
  save_type?: any;
}
export interface NewVoiceContent {
  cate: 'voice';
  title: string;
  voice_url: string;
  voice_time: string;
  qid: string;
}

export interface SaveContent {
  cate: string;
  uid: string;
  title: string;
  content?: string;
  content_raw?: string,
  qcontent?: string;
  voice_url?: string;
  voice_time?: string;
  video_img?: string;
  video_url?: string;
  video_id?: string;
}

export type Cate = 'article' | 'video' | 'ask' | 'voice';

interface Reminder {
  acontent: string;
  qcontent: string;
}
export interface VoiceQuestion {
  qid: string;
  title: string;
  reminder?: Reminder[];
  next_qid?: string;
  page_size?: number;
  page_num?: number;
  position?: number;
}
export type VoiceQuestionList = VoiceQuestion[];

export interface SingleContent {
  doc_id: string;
  title: string;
  acontent: string;
  htmlContent?: string;
  url: string;
  update_time?: string;
  source: string;
  pre_doc?: string;
  next_doc?: string;
}

export interface AskContent extends SingleContent {
  qcontent: string;
}

export interface FileContent extends SingleContent {
  file: File;
}

export interface VideoContent extends SingleContent {
  video_img: string;
  video_url: string;
  title: string;
  cate: string;
}
export interface VoiceContent extends SingleContent {
  voice_time: string;
  voice_url: string;
}

class ContentsService {
  getContents = (page_num = 1, cate = 0, type = 0): Promise<Result> => {
    return new Promise(function(resolve, reject) {
      Axios.get(`${APIPath}doctorauth/docinfo`, {
        headers: { 'access-token': localStorage.getItem('token') },
        params: {
          page_num,
          type: cate,
          status: type,
        }
      })
        .then((res) => {
          console.log(res);
          if (res.data && res.data.code === 0 && res.data?.data?.data) {
            resolve({
              type: '0',
              data: res.data?.data?.data
            });
          } else if (res.data && res.data.code === 20018) {
            reject(new Error('login fail'));
          } else {
            reject(new Error('fail'));
          }
        })
        .catch((err) => {
          console.log(err);
          reject(new Error('fail'));
        });
      // const time = page_num === 1 ? 2000 : 3000;
      // const random = Math.random();
      // const result: Result = {
      //   type,
      //   data: {
      //     doc_count: 0,
      //     doc_list: []
      //   }
      // };
      // const mock = contents.getListMock.data;
      // if (page_num === 1) {
      //   result.data.doc_count = mock.doc_count;
      //   result.data.doc_list = mock.doc_list;
      // } else if (random > 0.5) {
      //   result.data.doc_list = mock.doc_list;
      // } else {
      //   reject(new Error('nomore'));
      // }
      // console.log(type, contents, result);
      // setTimeout(() => {
      //   if (mock.doc_list.length > 0) {
      //     resolve(result);
      //   } else {
      //     reject(new Error('nomore'));
      //   }
      // }, time);
    });
  };

  getArticleContent = (id: string): Promise<SingleContent> => {
    return new Promise(function(resolve) {
      Axios.get(`${APIPath}doctorauth/singleinfo`, {
        params: {
          doc_id: id
        }
      }).then((res) => {
        console.log('article single res', res);
        if (res && res.data.code === 0 && res.data?.data?.data) {
          resolve(res.data.data.data);
        }
      });
    });
  };

  getAskContent = (id: string): Promise<AskContent> => {
    return new Promise(function(resolve) {
      Axios.get(`${APIPath}doctorauth/singleinfo`, {
        params: {
          doc_id: id
        }
      }).then((res) => {
        console.log('ask single res', res);
        if (res && res.data.code === 0 && res.data?.data?.data) {
          resolve(res.data.data.data);
        }
      });
    });
  };

  getVideoContent = (id: string): Promise<VideoContent> => {
    return new Promise(function(resolve) {
      Axios.get(`${APIPath}doctorauth/singleinfo`, {
        params: {
          doc_id: id
        }
      }).then((res) => {
        console.log('video single res', res);
        if (res && res.data.code === 0 && res.data?.data?.data) {
          resolve(res.data.data.data);
        }
      });
    });
  };

  getVoiceContent = (id: string): Promise<VoiceContent> => {
    return new Promise(function(resolve) {
      Axios.get(`${APIPath}entitysearch/voiceqainfo`, {
        params: {
          doc_id: id
        }
      }).then((res) => {
        console.log('voice single res', res);
        if (res && res.data.code === 0 && res.data?.data?.data) {
          resolve(res.data.data.data);
        }
      });
    });
  };

  getFileContent = (id: string): Promise<FileContent> => {
    return new Promise(function(resolve) {
      Axios.get(`${APIPath}entitysearch/fileinfo`, {
        params: {
          doc_id: id
        }
      }).then((res) => {
        console.log('file single res', res);
        if (res && res.data.code === 0 && res.data?.data?.data) {
          resolve(res.data.data.data);
        }
      });
    });
  };

  changeContentsStatus = (id: string | string[], action: string, doc_id: string) => {
    let idList;
    if (id) {
      idList = id;
      if (!isArray(id)) {
        idList = [id];
      }
    } else {
      idList = [doc_id];
    }
    return Axios.put(
      `${APIPath}doctorauth/docinfo`,
      JSON.stringify({
        action,
        uid_list: idList,
        login_source: 9
      }),
      {
        headers: {
          'Content-Type': 'application/json',
        }
      }
    );
  };

  createContent = (data: NewContent) => {
    let params = {
      ...data,
      login_source: 9
    };
    return Axios.post(`${APIPath}doctorauth/singleinfo`, params, {
      headers: {
        'access-token': localStorage.getItem('token')
      }
    });
  };

  saveContent = (data: SaveContent) => {
    return Axios.put(`${APIPath}doctorauth/singleinfo`, {
      ...data,
      login_source: 9
    });
  };

  uploadImage = (formData: FormData) => {
    return Axios.post(`${uploadAPIPath}mobile/upload/image`, formData);
  };

  uploadFile = (formData: FormData) => {
    console.log(formData);
    return Axios.post(`${APIPath}/doctorauth/filestorage`, formData);
  };

  getVoiceQuestionTabs = (): Promise<string[]> => {
    return new Promise((resolve, reject) => {
      Axios.get(`${APIPath}doctorauth/voiceqtab`)
        .then((res) => {
          if (res && res.data.code === 0 && res.data?.data?.data?.tab_list) {
            const tabs: string[] = res.data.data.data.tab_list;
            resolve(tabs);
          } else {
            reject(new Error('no result'));
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  getVoiceQuestionList = (type: string, page_num = 1): Promise<VoiceQuestionList> => {
    return new Promise((resolve, reject) => {
      Axios.get(`${APIPath}doctorauth/voiceqlist`, {
        params: {
          disease_type: decodeURIComponent(type),
          page_size: 10,
          page_num
        }
      })
        .then((res) => {
          if (res && res.data.code === 0 && res.data?.data?.data?.question_list) {
            const result: VoiceQuestionList = res.data.data.data.question_list;
            resolve(result);
          } else {
            reject(new Error('no result'));
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  getVoiceQuestionInfo = (
    id: string,
    pageNum: number,
    position: number,
    page_size: number,
    tabType?: string
  ): Promise<VoiceQuestion> => {
    return new Promise((resolve, reject) => {
      Axios.get(`${APIPath}doctorauth/voiceqinfo`, {
        params: {
          qid: id,
          disease_type: tabType || undefined,
          page_size,
          page_num: pageNum,
          position
        }
      })
        .then((res) => {
          console.log('video single res', res);
          if (res && res.data.code === 0 && res.data?.data?.data) {
            const result: VoiceQuestion = res.data.data.data;
            resolve(result);
          } else {
            reject(new Error('no result'));
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  blockVoiceQuestion = (id: string, reasons: number[]): Promise<string> => {
    return new Promise((resolve, reject) => {
      Axios.put(`${APIPath}doctorauth/blockvoice`, {
        qid: id,
        block_reason: reasons
      })
        .then((res) => {
          console.log('video single res', res);
          if (res && res.data.code === 0 && res.data?.data?.data) {
            resolve('success');
          } else {
            reject(new Error('fail'));
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  getDocTemplate = (): Promise<SingleContent> => {
    return new Promise(function(resolve) {
      Axios.get(`${APIPath}doctorauth/doctemplate`, {
        params: {
          doc_id: localStorage.getItem('doctor_id')
          // login_source: 9,
        }
      }).then((res) => {
        console.log('doctemplate res', res);
        if (res && res.data.code === 0 && res.data?.data?.data) {
          resolve(res.data.data.data);
        }
      });
    });
  };
}

export default ContentsService;
