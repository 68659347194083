import { observable, action } from 'mobx';
import { loginRedirectPath, appid } from '@/config/conf';
import util from '@/utils';
import DoctorInfoService from '../services/doctorInfoService';
import Doctor from '../types/doctor';

class HomeStore {
  service: DoctorInfoService;

  @observable
  public doctor: Doctor | undefined;

  @observable
  public doctorFlag = false;

  @observable
  public oldUserNeedAuth = false; // 老用户用户，未上传身份证信息的

  constructor(service: DoctorInfoService) {
    this.service = service;
    this.getDoctorInfo();
  }

  @action setDoctorFlag = () => {
    this.doctorFlag = !this.doctorFlag;
    localStorage.setItem('lsg_home_flag', '1');
  };

  @action
  getDoctorInfo(): void {
    this.service
      .getUserInfo()
      .then((res) => {
        // 1. 成功拿到用户信息，state
        // state='0'/'1'，未认证完成，跳edit
        // state='2'，完成认证流程，留在home
        // 2.拿不到用户信息，未登录/登录失效，跳微信认证拿code后跳login登录页
        console.log('BBB====', res);
        if (res[0]) {
          const [doctor] = res;
          const { is_edit_extra = '' } = doctor;
          const noAuthUser = +doctor.state <= 1; // 未认证的完成
          const fisrtGuid = localStorage.getItem('lsg_home_flag') !== '1'; // 初始引导

          this.oldUserNeedAuth = doctor.state == '2' && is_edit_extra === '1'; // 旧用户，未上传身份证信息的
          this.doctor = doctor;
          this.doctorFlag = fisrtGuid && (this.oldUserNeedAuth || noAuthUser);
          localStorage.setItem('isMcn', String(doctor.is_add_mcn));
          localStorage.setItem('doctor_id', String(doctor.doctor_id));

          document.title = `健康中国·医者名片·${doctor.name}`;
        }
        console.log('执行，', '健康中国·医者名片');
      })
      .catch(() => {
        if (util.needWXCheckEnv) {
          this.redirect();
        } else {
          window.location.href = '/login';
        }
      });
  }

  @action
  openXiaohe(): void {
    this.service
      .openXh()
      .then((res) => {
        console.log('授权开通', res);
      })
      .catch(() => {});
  }

  @action goEdit = () => {
    window.location.href = '/edit';
  };

  redirect = (login = true) => {
    console.log(login);
    if (login) {
      const redirectURI = encodeURIComponent(loginRedirectPath);
      const href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirectURI}&response_type=code&scope=snsapi_userinfo#wechat_redirect`;
      window.location.replace(href);
    } else {
      window.location.href = '/edit';
    }
  };
}

export default HomeStore;
