import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps, Link } from 'react-router-dom';
import classNames from 'classnames/bind';

import RemindCard from './components/remindCard';
import TimerProgress from './components/timerProgress';
import Loader from '../../components/loader';
import VoiceRecorderStore from '../../stores/voiceRecorderStore';
// import Loader from '../../components/loader';

import styles from './recorder.module.css';

const cx = classNames.bind(styles);

interface RouteParams {
  id: string;
}

@observer
class Recorder extends Component<RouteComponentProps<RouteParams>> {
  store: VoiceRecorderStore;

  constructor(props) {
    super(props);
    const { id } = this.props.match.params;
    this.store = new VoiceRecorderStore(id);
  }

  componentDidMount() {
    document.title = '语音问答';
  }

  componentDidUpdate(prevProps) {
    const navigated = prevProps.location !== this.props.location;

    if (navigated) {
      const { id } = this.props.match.params;
      this.store.init(id);
      // load data while the old screen remains
    }
  }

  handleClickPlay = () => {
    if (!this.store.voiceUrl) {
      return;
    }
    const audio = document.querySelector('audio');
    if (this.store.showPlayer) {
      if (audio) {
        audio.pause();
      }
      this.store.hidePlayer();
    } else {
      this.store.openPlayer();
      if (audio) {
        audio.play();
      }
    }
  };

  render() {
    const {
      question,
      duration,
      status,
      handleButtonClick,
      isVoiceExist,
      showDialog,
      closeDialog,
      restartRecord,
      voiceUrl,
      showPlayer,
      reCreate,
      handlePublishClick,
      fromTab,
      loaded,
      showGuide,
      nextGuideStep
    } = this.store;
    // eslint-disable-next-line no-nested-ternary
    const buttonType = status === 0 ? (isVoiceExist ? 'Reset' : 'Start') : 'Stop';
    return loaded && question ? (
      <div className={styles.voiceRecorder}>
        {showGuide > 0 && (
          <div className={styles.guideLayer}>
            <div className={cx('guideBox', `step-${showGuide}`)} onClick={nextGuideStep} />
          </div>
        )}
        <div className={styles.guideGroup}>
          <div className={styles.guideItem}>
            <span className={styles.guideIndex}>1</span>
            <span className={styles.guideText}>选择安静环境</span>
          </div>
          <div className={styles.slash}>-</div>
          <div className={styles.guideItem}>
            <span className={styles.guideIndex}>2</span>
            <span className={styles.guideText}>点击开始录音</span>
          </div>
          <div className={styles.slash}>-</div>
          <div className={styles.guideItem}>
            <span className={styles.guideIndex}>3</span>
            <span className={styles.guideText}>确认发布录音</span>
          </div>
        </div>
        <div className={styles.questionTitle}>{question.title}</div>
        <div className={styles.scrollWrapper}>
          <div className={styles.scrollContent}>
            {question.reminder &&
              question.reminder.length > 0 &&
              question.reminder.map((remind, index) => {
                return (
                  <RemindCard
                    className="remind-card-container"
                    title={remind.qcontent}
                    content={remind.acontent}
                    index={index}
                    key={remind.qcontent}
                  />
                );
              })}
            {!reCreate && (!question.reminder || !question.reminder.length) && (
              <div className={cx('card', 'remind-card-container', 'noContent')}>
                该问题暂无回答样例参考
              </div>
            )}
          </div>
        </div>
        <div className={styles.aboveFooter}>请在计时开始后回答，录制时长需大于20秒</div>
        <div className={styles.footer}>
          <audio
            className={cx({
              player: true,
              show: showPlayer
            })}
            src={voiceUrl}
            controls
          />
          {!showPlayer ? (
            <div className={cx('timer', { playing: status !== 0 })}>
              <div className={styles.animation}>
                <TimerProgress playing={status !== 0} direction="left" />
              </div>
              <span className={styles.durationText}>{duration}</span>
              <div className={styles.animation}>
                <TimerProgress playing={status !== 0} direction="right" />
              </div>
            </div>
          ) : (
            <div style={{ height: '30px' }} />
          )}

          <div className={styles.btnGroup}>
            <div
              className={cx('btn', {
                left: true
              })}
              onClick={this.handleClickPlay}
            >
              <span
                className={
                  buttonType === 'Reset'
                    ? styles.btnIconContainer
                    : `${styles.btnIconContainer} ${styles.btnIconContainer_active} `
                }

                // className={
                //   showPlayer
                //     ? `${styles.btnIconContainer} ${styles.btnIconContainer_active} `
                //     : `${styles.btnIconContainer} ${styles.btnIconContainer_active} `
                // }
              >
                <i className={styles.iconHeadphone} />
              </span>
              <span className={styles.btnText}>{showPlayer ? '结束' : '试听'}</span>
            </div>
            <div className={styles.actionBtnWrapper} onClick={handleButtonClick}>
              <div
                className={cx({
                  actionBtnIcon: true,
                  [`btn${buttonType}`]: true
                })}
              />
            </div>
            <div className={cx('btn', 'right')} onClick={handlePublishClick}>
              <span className={styles.btnIconContainer}>
                <i className={styles.iconConfirm} />
              </span>
              <span className={styles.btnText}>提交审核</span>
            </div>
          </div>
        </div>
        <Link className={cx('prev', 'jumpPill')} to="/voice-ask">
          <span className={cx('arrow')} />
          <span className={cx('arrow')} />
          返回列表
        </Link>
        {question.next_qid && (
          <a
            className={cx('next', 'jumpPill')}
            href={`/voice-recorder/${question.next_qid}?from=${fromTab}&pn=${question.page_num ||
              1}&position=${question.position || 0}&page_size=${question.page_size || 10}`}
          >
            <span>下一条</span>
            <span className={cx('arrow')} />
          </a>
        )}
        {showDialog && (
          <div className={styles.dialogWrapper}>
            <div className={styles.dialogBox}>
              <div className={styles.dialogTitle}>重新录制</div>
              <div className={styles.dialogText}>重新录制将清空已有内容</div>
              <div className={styles.dialogBtnGroup}>
                <div className={cx('dialogBtn', 'cancel')} onClick={closeDialog}>
                  取消
                </div>
                <div className={cx('dialogBtn', 'confirm')} onClick={restartRecord}>
                  确认
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    ) : (
      <div className={styles.voiceLoader}>
        <Loader />
      </div>
    );
  }
}

export default Recorder;
