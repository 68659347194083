import React, { Component } from 'react';
import { observer } from 'mobx-react';
import xhagreement from '@/const/xhagreement';
import Modal from '@/components/modal';
import Xhicon from '@/static/images/xh.png';
import defaultAvatar from '@/static/images/avatar.png';
import DoctorInfoService from '@/services/doctorInfoService';
import HomeStore from '@/stores/homeStore';
import EditStore from '@/stores/editStore';
import styles from './index.module.css';

@observer
class Openxh extends Component {
  service: DoctorInfoService;

  store: HomeStore;

  stores: EditStore;

  name = '医生';

  constructor(props: Readonly<{}>) {
    super(props);
    this.service = new DoctorInfoService();
    this.store = new HomeStore(this.service);
    this.stores = new EditStore();
  }
  
  UNSAFE_componentWillMount(){
    //window.TEA('主页', 'banner');
  }

  componentDidMount() {
    document.title = '授权开通';
  };

  render() {
    const { doctor, doctorFlag, setDoctorFlag } = this.store;
    if(doctor) {
      const avatar = doctor.headimg_url || defaultAvatar;
      return (
      <div className={styles.card}>
        <div> 
          <img className={styles.xh} src={Xhicon} alt=""/><span className={styles.text1}>小荷医生App申请获得</span>
        </div>
        <div className={styles.text2}>您的手机、职业信息等</div>
        <div className={styles.text3}>使用当前账号登录</div>
        <div className={styles.info}>
          <div>
          <img className={styles.photo} src={avatar} alt=""/>
            <div className={styles.perinfo}>
              <div className={styles.text4} >{doctor.name}</div>
              <div className={styles.text5}>医者名片个人信息</div>
            </div>
          </div>
        </div>
        <div className={styles.action}>
          <div className={styles.btn}>
            <div className={styles.text6} onClick={()=>{
              window.location.href = '/';
            }}>下次再说</div>
            <div className={styles.text7} onClick={()=>{
              this.store.openXiaohe();
            }}>申请开通</div>
          </div>
          <div>
          <div className={styles.text8}>申请开通规则视为接受<span className={styles.text9}onClick={this.stores.showAgreement}
          >《第三方数据共享隐私声明》</span></div>
          <Modal
            open={this.stores.agreementOpen}
            title="第三方数据共享隐私声明"
            content={xhagreement.split('\n')}
            onClose={this.stores.closeAgreement}
          />
          </div>
        </div>
      </div>
      );
    }
    return null;
  };
};

export default Openxh;
