import React, { Component } from 'react';
import DoctorInfoService from '@/services/doctorInfoService';
import McnStore from "@/stores/mcnStore";
import Card from './components/card'
import { observer } from "mobx-react";

interface List {
  contract_name: string,
  contract_code: string,
  contract_subject: string,
  sign_date: string,
  validity_period: string,
  sign_status: number,
  refuse_reason: string,
  view_url: string
}
@observer
class SignatureList extends Component<any, any> {
  service: DoctorInfoService;
  store: McnStore;
  domEvents: any;
  constructor(props) {
    super(props);
    this.service = new DoctorInfoService();
    this.store = new McnStore(this.service);
    this.state = {
      modal: false,
      showToSigModal: false,
      isMcn: false,
      isContracted: false,
      doctor_id: '',
      list: [

      ]
    }

  }
  async componentDidMount() {
    const doctor_id = localStorage.getItem('doctor_id') || '';
    this.setState({
      doctor_id
    })
    const { list } = await this.service.getSignList(doctor_id) || [];
    if (list) {
      this.setState({
        list,
      })
    }

  }


  render() {
    return <div style={{ backgroundColor: '#fff', padding: '0 10px' }}>
      {
        this.state.list.length > 0 ? this.state.list.map(item => {
          return (
            // <Card info = {item} nextStep={()=>this.nextStep}></Card>
            <Card info={item} ></Card>
          )
        }) : (
            <div>暂无内容</div>
          )
      }

    </div>;
  }
}

export default SignatureList;
