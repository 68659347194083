// import wx from 'weixin-js-sdk'
import WechatService from "../services/wechatServise";

declare global {
  interface Window { wx: any; }
}

function initWechatConfig() {
  const url = window.location.href;
  const wechatStore = new WechatService();
  const configUrl = encodeURIComponent(url);
  wechatStore.getTicket(configUrl).then((resData: any) => {
    console.log('wxTicket data', resData);
    if (resData) {
      const config = {
        debug: false,
        appId: 'wx5749b20957c51680',
        timestamp: resData.timestamp,
        nonceStr: resData.nonceStr,
        signature: resData.signature,
        jsApiList: ['hideMenuItems'],
      };
      window.wx.config(config);
      window.wx.ready(() => {
        window.wx.hideMenuItems({
          menuList: [
            'menuItem:share:appMessage',
            'menuItem:share:timeline',
            'menuItem:copyUrl',
          ]
        })
      })
    }
  })
}

export default initWechatConfig;
