import { observable, action, computed, toJS } from 'mobx';
// import message from '../components/message';
import ContentsService, { File, Video, NewContent, SaveContent } from '../services/contentsService';

const checkResultMessage = {
  title: '请填写标题',
  content: '请填写正文内容'
};

class EditorStore {
  service: ContentsService;

  @observable
  id: string;

  @observable
  cate: string;

  @observable
  title = '';

  @observable
  content = '';

  @observable
  state = 1;

  @observable
  source = '';

  @observable
  sourceUrl = '';

  @observable
  updateTime = '';

  @observable
  create: boolean;

  @observable
  template_id = undefined;

  loaded = false;

  @observable
  dialog = {
    open: false,
    title: '',
    message: '',
    onConfirm: () => {},
    confirmText: '确认'
  };

  @observable
  question = '';

  @observable
  video: Video | null = null;

  @observable
  file: File | null = null;

  @observable
  agreementOpen = false;

  @observable
  doctorId = '';

  @observable
  fail_reason = '';

  constructor(id = '', cate = 'article') {
    this.create = !id;
    this.id = id;
    this.cate = cate;
    this.service = new ContentsService();
    if (this.create) {
      this.loaded = true;
    } else {
      this.getCurrentContent(); // 如果ID存在 请求当前ID的内容。
    }
  }

  @computed
  get topHint() {
    if (this.state === 1) {
      return '预发布后将进入审核流程';
    }
    // if (this.state === 2) {
    //   return '审核未通过，请编辑后重新预发布';
    // }
    if (this.state === 2) {
      return '审核过程中不可操作';
    }
    if (this.state === 4) {
      return '重新编辑保存后，此内容将不再线上展示，需重新预发布，审核通过后线上可见';
    }
    if (this.state === 7) {
      return '需编辑后重新预发布，审核通过后线上可见';
    }
    return '保存、发布成功后将进入审核流程';
  }

  @computed
  get checkOptions() {
    const { cate } = this;
    const options = {
      content: cate !== 'video', // 非视频，即文章、问答，正文内容必填
      title: true
    };
    return options;
  }

  @action
  check = (): string => {
    const options = Object.keys(toJS(this.checkOptions));
    let message = '';
    options.forEach((key) => {
      if (this.checkOptions[key] && !this[key]) {
        message = checkResultMessage[key];
      }
    });

    return message;
  };

  @action
  getCurrentContent = async () => {
    const result: any = {
      cate: this.cate
    };
    if (this.cate === 'video') {
      result.data = await this.service.getVideoContent(this.id);
    } else if (this.cate === 'ask') {
      result.data = await this.service.getAskContent(this.id);
    } else if (this.cate === 'file') {
      result.data = await this.service.getFileContent(this.id);
    } else {
      result.data = await this.service.getArticleContent(this.id);
    }
    this.parseResultData(result);
  };

  @action
  parseResultData = (result) => {
    this.title = result.data.title;
    this.state = result.data.state;
    this.updateTime = result.data.update_time;
    this.source = result.data.source;
    this.sourceUrl = result.data.url;
    if (this.state === 4 && result.data.fail_reason) {
      this.fail_reason = result.data.fail_reason;
    }
    if (this.cate === 'video' && result.data.video_url) {
      this.video = {
        poster: result.data.video_img || '',
        src: result.data.video_url
      };
    }
    if (this.cate === 'file' && result.data.file) {
      this.file = result.data.file;
    }
    if (result.data.html_content) {
      this.content = result.data.html_content;
    } else if (result.data.acontent) {
      this.content = this.parseOldContent(result.data.acontent);
    }
    if (this.cate === 'ask') {
      this.question = result.data.qcontent || '';
    }
    this.loaded = true;
  };

  parseOldContent = (contentText: string): string => {
    let parsed = contentText
      .replace(/\[add_image http:/g, '<img width="100%" src="')
      .replace(/\]/g, '"/>')
      .replace(/\[add_image/g, '<img style="display:none"');
    for (let i = 0; i < contentText.length; i++) {
      parsed = parsed.replace('\u0001', '<br/>');
    }
    return parsed;
  };

  @action
  changeTitle = (title: string) => {
    this.title = title;
  };

  @action
  changeQuestion = (question: string) => {
    this.question = question;
  };

  @action
  changeContent = (content: string) => {
    this.content = content;
  };

  @action
  afterCreate = (id, state, source?) => {
    this.id = id;
    this.state = state;
    this.create = false;
    if (source) {
      this.source = source;
    }
    if (this.state !== 2) {
      this.fail_reason = '';
    }
  };

  @action
  createContent = (save_type, editTxt) => {
    const { cate, title, content, service, afterCreate } = this;
    const data: NewContent = {
      cate,
      title,
      content,
      save_type,
      content_raw: editTxt,
    };
    console.log('data', data);
    
    return new Promise(function(resolve, reject) {
      service
        .createContent(data)
        .then((res) => {
          if (res && res.data.code === 0 && res.data?.data?.data) {
            resolve(res.data.data.data);
          } else {
            reject(new Error('fail1'));
          }
        })
        .catch(() => {
          reject(new Error('fail2'));
        });
    });
  };

  @action
  createContentPrev = (template_id) => {
    const { cate, title, content, service, afterCreate } = this;
    const data: NewContent = {
      cate,
      title,
      content,
      template_id
    };
    return new Promise(function(resolve, reject) {
      service
        .createContent(data)
        .then((res) => {
          if (res && res.data.code === 0) {
            resolve('success');
            // 预览后不再返回新建页面
            window.location.replace(`/preview/${cate}/${res.data.data.data}`);
          } else {
            reject(new Error('fail1'));
          }
        })
        .catch(() => {
          reject(new Error('fail2'));
        });
    });
  };

  @action
  saveContentPrev = () => {
    const { id, cate, title, content, question, service, afterCreate } = this;
    const params: SaveContent = {
      cate,
      uid: id,
      title
    };
    if (cate !== 'file') {
      params.content = content;
    }
    if (cate === 'ask') {
      params.qcontent = question;
    }
    return new Promise(function(resolve, reject) {
      service
        .saveContent(params)
        .then((res) => {
          console.log('预览：', res);
          if (res && res.data.code === 0 && res.data?.data?.data) {
            const { doc_id, state, source } = res.data?.data?.data;
            if (doc_id && state) {
              afterCreate(doc_id, state, source);
            }
            if (doc_id) {
              window.location.href = `/preview/article/${doc_id}`;
            } else {
              window.location.href = `/preview/article/${id}`;
            }
            //
            resolve('success');
          } else {
            reject(new Error('fail'));
          }
        })
        .catch(() => {
          reject(new Error('fail'));
        });
    });
  };

  @action
  saveContent = (editTxt) => {
    const { id, cate, title, content, question, service, afterCreate } = this;
    const params: SaveContent = {
      cate,
      uid: id,
      title,
      content_raw: editTxt,
    };
    if (cate !== 'file') {
      params.content = content;
    }
    if (cate === 'ask') {
      params.qcontent = question;
    }
    return new Promise(function(resolve, reject) {
      service
        .saveContent(params)
        .then((res) => {
          // console.log('预览--：', res);
          // console.log('if', res.data.code);
          if (res && res.data.code === 0 && res.data?.data?.data) {
            const { doc_id, state, source } = res.data?.data?.data;
            if (doc_id && state) {
              afterCreate(doc_id, state, source);
            }
            // console.log('if');
            resolve('success');
          } else {
            // console.log('else');
            reject(new Error('fail'));
          }
        })
        .catch(() => {
          reject(new Error('fail'));
        });
    });
  };

  @action
  publish = (doc_id) => {
    const { id, service } = this;
    return new Promise(function(resolve, reject) {
      service
        .changeContentsStatus(id, 'publish', doc_id)
        .then((res) => {
          if (res && res.data.code === 0) {
            console.log('if');
            resolve('success');
          } else {
            reject(new Error('fail'));
          }
        })
        .catch(() => {
          reject(new Error('fail'));
        });
    });
  };

  @action
  showDialog = (type: string) => {
    let message = '';
    message =
      type === 'save'
        ? '保存后，此内容将不在线上展示，需重新预发布，审核通过后线上可见'
        : '审核过程中，此内容将不在线上展示，审核通过后线上可见';
    this.dialog.confirmText = type === 'save' ? '确认保存' : '确认预发布';
    this.dialog.title = type === 'save' ? '确认保存？' : '确认预发布';
    this.dialog.message = message;
    this.dialog.open = true;
    return new Promise((resolve) => {
      // @ts-ignore
      this.dialog.onConfirm = resolve;
    });
  };

  @action
  closeDialog = () => {
    this.dialog.open = false;
    this.dialog.title = '';
    this.dialog.message = '';
    this.dialog.onConfirm = () => {};
  };

  @action
  uploadImage = (formData: FormData) => {
    return new Promise((resolve, reject) => {
      this.service
        .uploadImage(formData)
        .then((res) => {
          console.log('==', res);
          if (res.data && res.data.code === 200 && res?.data?.data?.url) {
            resolve(res.data.data.url);
          } else {
            reject(new Error('fail'));
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

export default EditorStore;
