import { observable, action, computed } from 'mobx';
import Hospital, { Department } from '@/types/hospital';
import message from '../components/message';
import ContentsService, { File, Video, Voice, SaveContent } from '../services/contentsService';
import VideoService from '../services/videoService';
import VideoBg from '../static/images/doctor-bg.png';
interface Doctor {
  name: string;
  headimg_url?: string;
  grade?: string;
}

class PreviewStore {
  service: ContentsService;
  serviceVideo: VideoService;

  doctor?: Doctor;

  hospital?: Hospital;

  department?: Department;

  @observable
  id: string;

  @observable
  cate: string;

  @observable
  title = '';

  @observable
  content = '';

  @observable
  source = '';

  @observable
  sourceUrl = '';

  @observable
  updateTime = '';

  @observable
  state = 1;

  @observable
  pre_doc?: string;

  @observable
  next_doc?: string;

  loaded = false;

  @observable
  dialog = {
    open: false,
    title: '审核未通过原因',
    message: '',
    onConfirm: () => { },
    confirmText: '确认'
  };

  @observable
  question = '';

  @observable
  video: Video | null = null;

  @observable
  voice: Voice | null = null;

  @observable
  file: File | null = null;

  @observable
  agreementOpen = false;

  @observable
  doctorId = '';

  @observable
  fail_reason = '';

  @observable
  play_flag = 0;

  constructor(id: string, cate = 'article') {
    this.id = id;
    this.cate = cate;
    this.service = new ContentsService();
    this.serviceVideo = new VideoService();
    this.getCurrentContent();
  }

  update = (id: string, cate = 'article') => {
    this.id = id;
    this.cate = cate;
    this.getCurrentContent();
  };

  @action
  playVedio = () => {
    this.play_flag = 1;
  };

  @computed
  get topHint() {
    if (this.state === 1) {
      return '预发布后将进入审核流程';
    }
    // if (this.state === 2) {
    //   return '审核未通过，请编辑后重新预发布';
    // }
    if (this.state === 2) {
      return '审核过程中不可操作';
    }
    if (this.state === 4) {
      return '重新编辑保存后，此内容将不再线上展示，需重新预发布，审核通过后线上可见';
    }
    if (this.state === 7) {
      return '需编辑后重新预发布，审核通过后线上可见';
    }
    return '保存、发布成功后将进入审核流程';
  }

  @action
  getCurrentContent = async () => {
    const result: any = {
      cate: this.cate
    };
    if (this.cate === 'video') {
      result.data = await this.service.getVideoContent(this.id);
    } else if (this.cate === 'ask') {
      result.data = await this.service.getAskContent(this.id);
    } else if (this.cate === 'file') {
      result.data = await this.service.getFileContent(this.id);
    } else if (this.cate === 'voice') {
      result.data = await this.service.getVoiceContent(this.id);
    } else {
      result.data = await this.service.getArticleContent(this.id);
    }
    this.parseResultData(result);
  };

  @action
  parseResultData = (result) => {
    this.title = result.data.title;
    this.state = result.data.state;
    this.updateTime = result.data.update_time;
    this.source = result.data.source;
    this.doctor = result.data.doctor;
    this.department = result.data.department;
    this.hospital = result.data.hospital;
    this.sourceUrl = result.data.url;
    this.pre_doc = result.data.pre_doc;
    this.next_doc = result.data.next_doc;
    if (this.state === 4 && result.data.fail_reason) {
      this.fail_reason = result.data.fail_reason;
    }
    if (this.cate === 'video' && result.data.video_url) {
      this.video = {
        poster: result.data.video_img || VideoBg,
        src: result.data.video_url || '',
      };
    }
    if (this.cate === 'file' && result.data.file) {
      this.file = result.data.file;
    }
    if (result.data.html_content && result.data.html_content !== ' ') {
      this.content = result.data.html_content;
    } else if (result.data.acontent) {
      this.content = this.parseOldContent(result.data.acontent);
    }
    if (this.cate === 'ask') {
      this.question = result.data.qcontent || '';
    }
    if (this.cate === 'voice') {
      this.voice = {
        src: result.data.voice_url,
        duration: result.data.voice_time
      };
    }
    this.loaded = true;
  };

  parseOldContent = (contentText: string): string => {
    let parsed = contentText
      .replace(/\[add_image http:/g, '<img width="100%" src="')
      .replace(/\]/g, '"/>')
      .replace(/\[add_image/g, '<img style="display:none"');
    for (let i = 0; i < contentText.length; i++) {
      parsed = parsed.replace('\u0001', '<br/>');
    }
    return parsed;
  };

  @action
  saveContent = () => {
    const { id, cate, title, content, question, service } = this;
    const params: SaveContent = {
      cate,
      uid: id,
      title
    };
    if (cate !== 'file') {
      params.content = content;
    }
    if (cate === 'ask') {
      params.qcontent = question;
    }
    return new Promise(function (resolve, reject) {
      service
        .saveContent(params)
        .then((res) => {
          if (res && res.data.code === 0 && res.data?.data?.data) {
            // const { doc_id, state, source } = res.data.data;
            resolve('success');
          } else {
            reject(new Error('fail'));
          }
        })
        .catch(() => {
          reject(new Error('fail'));
        });
    });
  };

  @action
  publish = () => {
    const { id, service } = this;
    return new Promise((resolve, reject) => {
      service
        .changeContentsStatus(id, 'publish', '')
        .then((res) => {
          if (res && res.data.code === 0) {
            resolve('success');
            message.success('审核提交成功', 1.5, () => {
              //   this.getCurrentContent();
              window.location.href = '/content/manage';
            });
            //

            console.log('WT:', res.data?.data?.data, id);
          } else {
            message.info('预发布失败，请稍后重试');
            reject(new Error('fail'));
          }
        })
        .catch(() => {
          message.info('预发布失败，请稍后重试');
          reject(new Error('fail'));
        });
    });
  };

  @action
  block = () => {
    const { id, service } = this;
    return new Promise((resolve, reject) => {
      service
        .changeContentsStatus(id, 'block', '')
        .then((res) => {
          if (res && res.data.code === 0 && res.data.data.data) {
            message.success('屏蔽成功');
            this.getCurrentContent();
            resolve('success');
          } else {
            message.info('屏蔽失败，请稍后重试');
            reject(new Error('fail'));
          }
        })
        .catch(() => {
          message.info('屏蔽失败，请稍后重试');
          reject(new Error('fail'));
        });
    });
  };

  @action
  cancelBlock = () => {
    const { id, service } = this;
    return new Promise((resolve, reject) => {
      service
        .changeContentsStatus(id, 'cancelblock', '')
        .then((res) => {
          if (res && res.data.code === 0 && res.data?.data?.data) {
            message.success('取消屏蔽成功');
            this.getCurrentContent();
            resolve('success');
          } else {
            message.info('取消屏蔽失败，请稍后重试');
            reject(new Error('fail'));
          }
        })
        .catch(() => {
          message.info('取消屏蔽失败，请稍后重试');
          reject(new Error('fail'));
        });
    });
  };

  // @action
  // showDialog = () => {
  //   this.dialog.message = this.fail_reason;
  //   this.dialog.open = true;
  //   return new Promise((resolve) => {
  //     this.dialog.onConfirm = resolve;
  //   });
  // };

  @action
  closeDialog = () => {
    this.fail_reason = '';
  };

  @action
  uploadImage = (formData: FormData) => {
    return new Promise((resolve, reject) => {
      this.service
        .uploadImage(formData)
        .then((res) => {
          if (res.data && res.data.code === 0 && res.data?.data?.data && res.data?.data?.data[0]) {
            resolve(res.data?.data?.data[0]);
          } else {
            reject(new Error('fail'));
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

export default PreviewStore;
