import React, {Component} from 'react';
import {Modal} from "antd-mobile";
import { Toast, WhiteSpace, WingBlank, Button } from 'antd-mobile';
import styles from "./index.module.css"
import header_bg from "../../static/images/poster_header.png"
import plan1 from "../../static/images/plan1.png"
import plan2 from "../../static/images/plan2.png"
import plan3 from "../../static/images/plan3.png"
import num1 from "../../static/images/poster-no1.png"
import num2 from "../../static/images/poster-no2.png"
import num3 from "../../static/images/poster-no3.png"
import num4 from "../../static/images/poster-no4.png"
import DoctorInfoService from '@/services/doctorInfoService';
import McnStore from "@/stores/mcnStore";
import {observer} from "mobx-react";
import Util from '../../utils/util'
interface Props { // Added this interface for props
  history:any
}
@observer
class Grow extends Component<Props, { modal: boolean,showToSigModal:boolean,isMcn:boolean, doctor_id:string,isContracted:boolean,showWarning:boolean}> {
  service: DoctorInfoService;
  store: McnStore;
  domEvents:any;

  constructor(props:Props) {
    super(props);
    this.service = new DoctorInfoService();
    this.store = new McnStore(this.service);
    this.state = {
      modal: false,
      showToSigModal:false,
      isMcn:false,
      isContracted:false,
      doctor_id:'',
      showWarning:false

    }
  }
      async componentDidMount()  {
     
        const {is_contract_sign} = await this.service.getDoctorInfo();
        const {isMcn} = this.store;  
        this.setState({
     
          isContracted: !!is_contract_sign,
          isMcn: isMcn === '1',
          },() => {
            this.setState({
              showToSigModal: this.state.isMcn && !is_contract_sign,
            })
          })     
      }
      toSign = async ()=>{
                //跳转
                try{
                  const {sign_url} = await this.service.commitSign(this.state.doctor_id)||'';
                  this.setState({
                  showToSigModal: false,
                },()=>{
                  window.open(sign_url,'_parent')
                }) 
                }catch(err){
                  if(err.code === 50000 ){
                    this.setState({
                          showWarning:true
                        })
                  }else{
                    const msg = err.msg;
                    Toast.fail(msg, 1);
                  }
                  
                }    
      }
      toEdit = () => {
        this.props.history.push('/edit');
        this.setState({
          showWarning: false
        })
      }
  render() {
    const {isMcn,isContracted} = this.state;

    return <div>
      <div className={styles.header}>
        <img src={header_bg} alt=""/>
      </div>
      <div className={styles.main_content}>
        <div className={styles.content}>
          <div className={styles.introduction}>
            <div className={styles.title}>
              <span>项目介绍</span>
            </div>
            <p className={styles.paragraph}>
              为了完善健康中国的内容和服务生态圈，提升互联网+健康服务的品质，为医者赋能，激活创作热情，健康中国医者名片发起“健康中国医者名片成长计划”，向医者名片入驻医生提供全方位资源支持和服务。
            </p>
          </div>
          <div className={styles.grow_plan}>
            <div className={styles.title}>
              <span>成长计划亮点</span>
            </div>
            <div className={styles.plan_box}>
              <img src={plan1} alt=""/>
              <div className={styles.content_box}>
                <p className={styles.paragraph}>
                  科普内容在健康中国新媒体平台高优呈现的机会；
                </p>
                <p className={styles.paragraph}>
                  优先参与健康中国政务新媒体相关科普活动。
                </p>
              </div>
            </div>
            <div className={styles.plan_box}>
              <img src={plan2} alt=""/>
              <div className={styles.content_box}>
                <p className={styles.paragraph}>
                  享受头条流量支撑，探索从科普内容到服务的转化，个人品牌建立；
                </p>
                <p className={styles.paragraph}>
                  参与医者名片阶段性“科普之星”展示与分享。
                </p>
              </div>
            </div>
            <div className={styles.plan_box}>
              <img src={plan3} alt=""/>
              <div className={styles.content_box}>
                <p className={styles.paragraph}>
                  利用碎片化时间，通过在线服务与内容生产，获得阳光收入；
                </p>
                <p className={styles.paragraph}>
                  新媒体专业培训，加入科普达人社群，结交和你同样优秀的人。
                </p>
              </div>
            </div>
          </div>
          <div>
            <div className={styles.title}>
              <span>报名流程</span>
            </div>
            <div className={styles.step_box}>
              <img src={num1} alt=""/>
              <p className={styles.paragraph}>
                点击下方"立即报名"，申请加入健康中国医者名片成长计划。
              </p>
            </div>
            <div className={styles.step_box}>
              <img src={num2} alt=""/>
              <p className={styles.paragraph}>
                工作人员会通过电话方式联系您，签署健康中国医者名片成长计划基础协议。
              </p>
            </div>
            <div className={styles.step_box}>
              <img src={num3} alt=""/>
              <p className={styles.paragraph}>
                下载小荷医生App并按步骤认证。
              </p>
            </div>
            <div className={styles.step_box}>
              <img src={num4} alt=""/>
              <p className={styles.paragraph}>
                完成以上步骤后，填写一份调查问卷，可获得精美礼物。
              </p>
            </div>
          </div>
        </div>
        {
          !this.state.isMcn && (
            <div onClick={() => {
              //处理报名事件
            this.store.addMcn();
            this.setState({
              isMcn:true,
              showToSigModal:true
            })
            }}  className={styles.bottom_btn}>
              立即报名
            </div>
          )
        }
        {
          this.state.isMcn && !this.state.isContracted  &&( 
          <div className={styles.bottom_btn} onClick = {()=>{
            this.setState({
              showToSigModal:true
            })
          }}>
            去签约
          </div>)
        }
        {
          this.state.isMcn && this.state.isContracted  &&( 
          <div className={styles.bottom_btn} onClick={()=>{
            
            this.props.history.push('/siglist');
            
          }}>
            查看签约状态
          </div>)
        }
        
      </div>
      <Modal
        visible={false}
        transparent
        className={styles.modal}
        onClose={() => {
          this.setState({
            modal: false
          })
        }}
        footer={[{ text: '好的', onPress: () => {
          //处理报名事件
          this.store.addMcn();
          this.setState({
            modal: false,
          },()=>{
            this.setState({
              isMcn:true
            })
          })
        } }]}
      >
        <div style={{textAlign: "left"}}>
          您已完成报名健康中国医者名片成长计划，接下来会有工作人员通过电话方式联系您，请注意接听。
        </div>
      </Modal>
      {/* 引导用户去签约 */}
      <Modal
        visible={this.state.showToSigModal}
        transparent
        title="协议签订"
        className={styles.modal}
        onClose={() => {
          this.setState({
            showToSigModal: false
          })
        }}
        footer={[{text:'再想想',onPress : () => {
          this.setState({
            showToSigModal: false,
          })
        }},{ text: '去签约', onPress: Util.debouncePrev(this.toSign,1500)}]}
      >
          <div style={{textAlign: "left"}}>
          您与健康报社有限公司签订成长计划协议《医者名片医生成长计划服务协议》
          </div>
      </Modal>
      {/* 二要素未通过 引导用户完善信息 */}
      <Modal
        visible={this.state.showWarning}
        transparent
        title="认证失败提示"
        // className={styles.modal}
        onClose={() => {
          this.setState({
            showWarning: false
          })
        }}
        footer={[{ text: '编辑名片', onPress: this.toEdit}]}
      >
          <div style={{textAlign: "left"}}>
            您的姓名与身份证不一致，请您修改后再完成在线签约！
          </div>
      </Modal>
    </div>;
  }
}

export default Grow
