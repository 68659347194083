/**
 * @file signInService.ts 医者B端服务号登录、注册接口
 */

import axios from '@/utils/interceptors';
import CryptoJS from 'crypto-js';
import message from '@/components/message';
import { APIPath, loginSource, uploadAPIPath } from '../config/conf';
import Doctor from '../types/doctor';
// import SignInfo from '../types/doctor'
// import message from '../components/message';

axios.defaults.withCredentials = true;
// import { APIPath, loginSource } from '../config/conf';
const PaddingLeft = (key: any, length: any) => {
  let pkey = key.toString();
  const l = pkey.length;
  if (l < length) {
    pkey = new Array(length - l + 1).join('0') + pkey;
  } else if (l > length) {
    pkey = pkey.slice(length);
  }
  return pkey;
};

const aseEncrypt = (msg: any, key: any) => {
  // key = PaddingLeft(key, 16); // 保证key的长度为16byte,进行'0'补位
  // key = CryptoJS.enc.Utf8.parse(PaddingLeft(key, 16));
  const key0 = CryptoJS.enc.Utf8.parse(PaddingLeft(PaddingLeft(key, 16), 16));
  const encrypted = CryptoJS.AES.encrypt(msg, key0, {
    iv: key0,
    mode: CryptoJS.mode.CBC, // CBC算法
    padding: CryptoJS.pad.Pkcs7 // 使用pkcs7 进行padding 后端需要注意
  });
  return encrypted.ciphertext.toString(CryptoJS.enc.Hex); // 后端必须进行相反操作
};
const getToken = () => {
  const timestamp = Math.round(new Date().getTime() / 1000);
  const msg = `${timestamp};e22674779c464244`;
  return aseEncrypt(msg, '186133958051');
};

export interface Score {
  user_id: string;
  doctor_id: string;
  score: number;
  doc_release_score: string;
  doc_add_score: number;
}

interface ShareMode {
  url: string;
  name: string;
}

export interface ShareDoctor {
  institution_name?: string; //推荐机构名称
  headimg_url?: string; //头像
  doctor_name?: string; //医生名称
  department_name?: string; //科室名称
  grade_name?: string; //医生职级
  hospital_name?: string; //医院名称
  title_tags?: string[]; //称号
  specialize?: string; //专业擅长
  designation?: string[]; //荣誉称号
  doctor_doc?: string[]; //科普作品
  qr_url?: string; //二维码图片地址
}

interface mcnInfo {
  data?: string;
  msg?: string;
  code?: number;
  has_new?: boolean;
}
interface SignInfo {
  sign_url: string;
  doctor_id: string;
  data: any;
  res: any;
}
class DoctorInfoService {
  getUserInfo = (): Promise<Doctor[]> =>
    new Promise((resolve, reject) => {
      axios
        .get(`${APIPath}doctorauth/init`)
        .then((res) => {
          console.log('医生信息初始化', res);
          if (res.data && res.data.code === 0 && res.data?.data?.data) {
            const result: Doctor[] = res.data.data.data;
            resolve(result);
          } else {
            if (res.data.code !== 10008) {
              message.warning(res?.data?.msg || '请稍后重试');
            }
            reject(new Error(res.data.code));
          }
        })
        .catch((err) => {
          console.log('医生信息初始化错误', err);
          reject(err);
        });
    });

  getDoctorInfo = (): Promise<Doctor> => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${APIPath}doctorauth/basicinfo`)
        .then((res) => {
          console.log(res);
          if (res.data && res.data.code === 0 && res.data?.data?.data) {
            const result: Doctor = res.data.data.data;
            resolve(result);
          } else {
            reject();
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  // // 获取签约链接
  commitSign = (doctor_id: string): Promise<any> => {
    const formdata = new FormData();
    formdata.append('doctor_id', doctor_id);
    formdata.append('login_source', '9');
    return new Promise((resolve, reject) => {
      axios
        .post(`${APIPath}doctorauth/commit_sign`, formdata, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        })
        .then((res) => {
          if (res.data && res.data.code === 0 && res.data?.data?.data) {
            resolve(res.data?.data?.data);
          } else if (res.data && res.data.code !== 0) reject(res.data);
          // const result:SignInfo = res.data.data;
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  //获取医生签约列表
  getSignList = (doctor_id: string): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${APIPath}doctorauth/sign_list`, {
          params: {
            page_num: 1,
            doctor_id
          }
        })
        .then((res) => {
          if (res.data && res.data.code === 0 && res.data?.data?.data) {
            const result = res.data.data.data;
            resolve(result);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  getSignState = (contractCode: string, doctor_id: string): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${APIPath}doctorauth/get_sign_state`, {
          params: {
            contractCode,
            doctor_id
          }
        })
        .then((res) => {
          if (res.data && res.data.code === 0 && res.data?.data?.data) {
            const result = res.data?.data?.data;
            resolve(result);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
  coverStatus = (status: number): string => {
    //签约状态 1.未签约 2.已签约 3.已拒签
    const obj = {
      1: '未签约',
      2: '已签约',
      3: '已拒签'
    };
    return obj[status];
  };
  // 授权开通小荷
  openXh = (): Promise<Doctor> => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${APIPath}doctorauth/authxiaohe`, {
          params: {
            login_source: 9
          }
        })
        .then((res) => {
          if (res.data.code === 0) {
            // message.info('申请成功', 1.5, () => {
            //   window.location.href = '/openres';
            // });
            window.location.href = '/openres';
          } else {
            window.location.href = '/';
          }
          if (res.data && res.data.code === 0) {
            const result: Doctor = res.data?.data?.data;
            resolve(result);
          } else {
            reject();
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  // 获取医生信息，带二维码的
  getDoctorCard = (id: string): Promise<ShareMode> => {
    console.log(id);
    return new Promise((resolve, reject) => {
      axios
        .get(`${APIPath}doctorauthapi/doctorimg`)
        .then((res) => {
          console.log(res);
          if (res.data && res.data.code === 0 && res.data?.data?.data) {
            const result: ShareMode = res.data?.data?.data;
            resolve(result);
          } else {
            reject();
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  // 获取医生信息
  getShareDoctor = (id: string): Promise<ShareDoctor> => {
    console.log(id);
    return new Promise((resolve, reject) => {
      axios
        .get(`${APIPath}doctorauth/sharedoctor`)
        .then((res) => {
          console.log('sss', res.data);
          if (res.data.code == 0) {
            const result: ShareDoctor = res.data.data;
            resolve(result);
          } else {
            reject();
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  updateDoctorInfo = (params): Promise<Doctor> => {
    return new Promise((resolve, reject) => {
      axios
        .put(`${APIPath}doctorauth/basicinfo`, JSON.stringify(params), {
          headers: {
            'Content-Type': 'application/json',
            'access-token': localStorage.getItem('token')
          }
        })
        .then((res) => {
          if (res.data && res.data.code === 0) {
            const result: Doctor = res.data;
            resolve(result);
          } else {
            reject();
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  uploadImage = (formData: FormData) => {
    return axios.post(`${uploadAPIPath}mobile/upload/image`, formData);
  };

  getDoctorScore = (): Promise<Score> => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${APIPath}doctorauth/doctorscore`)
        .then((res) => {
          if (res.data && res.data.code === 0 && res.data?.data?.data) {
            const score: Score = res.data.data.data;
            resolve(score);
          } else {
            reject();
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  addMcn = (): Promise<mcnInfo> => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${APIPath}doctorauth/addmcn`, {
          params: {
            doctor_id: localStorage.getItem('doctor_id'),
            login_source: 9
          }
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  //科普议题 /doctorauth/newmsg
  checkNewMsg = (): Promise<mcnInfo> => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${APIPath}doctorauth/newmsg`, {
          // 获取是否有新消息状态，禁用浏览器缓存，否则缓存期内无法更新状态。
          headers: { 'Cache-Control': 'no-cache' },
          params: {
            doc_id: localStorage.getItem('doctor_id')
          }
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  ///doctorauth/newmsg
  postNewMsg = (): Promise<mcnInfo> => {
    const formdata = new FormData();
    const id: any = localStorage.getItem('doctor_id');
    formdata.append('doc_id', id);
    return new Promise((resolve, reject) => {
      axios
        .post(`${APIPath}doctorauth/newmsg`, formdata)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  ///doctorauth/doctemplate
  // getDocTemplate = ():Promise<mcnInfo> => {
  //   return new Promise((resolve, reject) => {
  //     axios
  //       .get(`${APIPath}doctorauth/doctemplate`, {
  //         params: {
  //           doctor_id: localStorage.getItem('doctor_id'),

  //         }
  //       }).then(res => {
  //       resolve(res.data);
  //     }).catch(err => {
  //       reject(err)
  //     })
  //   })
  // }
}

export default DoctorInfoService;
