import { isLSG } from '@/config/conf';

const lsgAgreement = `
欢迎您与北京源码悦动健康科技有限公司共同签署《绿松果医者平台服务协议》（以下简称本协议），绿松果医者平台（本协议中提及的绿松果医者平台包括平台、小程序及软硬件客户端）为北京源码悦动健康科技有限公司（以下简称源码悦动）运营和提供的在线信息服务平台，欢迎各大医院医生关注并使用。
请仔细阅读本协议，当您按照提示填写信息、签署本协议后，表示您已充分阅读、理解并接受本协议的全部内容，并与绿松果医者平台达成一致，成为绿松果医者平台用户，并服从于绿松果医者平台的统一管理。
一、合作内容：
1.1 绿松果医者平台为用户制作个人在线名片，为用户提供内容管理平台和在线名片，帮助用户通过绿松果医者平台对科普内容进行编辑、审核、发布等管理操作，发布审核后的医疗科普内容，提升自身行业影响力和公众影响力。用户签署本协议、注册、登录、使用绿松果医者平台提供的服务，即视为用户完全了解并同意不可撤销地独家且无偿地授权、许可源码悦动收集、统计、存储、协助管理和使用用户通过互联网生产、创作、发布的信息和内容，包括但不限于问答内容、图文文章内容、著作文献、音频、视频等形态医疗健康相关内容（以下简称用户名下内容），并授权源码悦动与该等用户名下内容相关的财产性权利，如著作权财产性权利（包括但不限于信息网络传播权）。用户可通过绿松果医者平台管理工具，进行自身执业信息更新，对自己名下内容的提交、更新、删除、在个人名片中置顶等管理操作，保护个人内容质量和品牌。
1.2用户授权绿松果医者平台存储其个人信息（包括个人身份信息、执业信息）及用户名下内容，同时授权绿松果医者平台在自身产品的内容使用权和转授权、肖像展示权，进行执业信息展示，并在全网流量渠道推广用户个人名片及分发优质的用户名下内容，帮助用户提升个人影响力。
1.3 绿松果医者平台的具体服务由源码悦动提供，源码悦动保留随时变更、中断或终止部分或全部网络服务的权利，且无需向用户或任何第三方承担任何责任。
1.4绿松果医者平台仅作为医疗健康信息和咨询平台，用户通过绿松果医者平台发表的各种言论（包括但不仅限于医学文献、诊疗建议、科普内容等），不代表绿松果医者平台赞同其观点或证实其内容的真实性，也不能作为临床诊断及医疗的依据，不能替代医生与病人面对面的诊断和治疗。
二、用户及注册：
2.1绿松果医者平台提供申请/注册流程，用户名和密码由用户自行保管；用户应当对以其帐号进行的所有活动和事件负法律责任。
2.2 用户在注册过程中，根据绿松果医者平台提示可以选择表示“同意”的操作。当用户勾选“同意”时即视为用户已仔细阅读、签署本协议，同意接受本协议项下的所有条款约束。用户同意所有注册协议条款并完成注册程序，才能成为绿松果医者平台的正式用户、使用绿松果医者平台提供的服务。
2.3用户申请/注册时，在用户名、头像和简介等注册信息中不得出现违法和不良信息，否则绿松果医者平台有权拒绝提供服务，并删除该账号。
2.4用户申请/注册绿松果医者平台时，应当遵守本第二条的规定，并进行实名认证，绿松果医者平台有权采取合理方式对用户身份进行核实，通过认证后绿松果医者平台将提供相应服务。
三、用户的权利和责任：
3.1 用户有权利拥有自己在绿松果医者平台的用户名及密码，并有权利使用自己的用户名及密码随时登陆绿松果医者平台服务。用户不得以任何形式转让或授权他人使用，亦不得盗用他人帐号，由以上行为造成的后果由用户自行承担。
3.2 用户应保证提供真实、准确和完整的个人资料和信息以符合绿松果医者平台的认证要求。如用户个人资料或信息发生变动，用户应及时更改。
3.3 用户应保证其具有签署本协议的权利和能力，并具备医务人员的必要专业资质。
3.4 用户对自己发布的用户名下内容承担责任，并确保绿松果医者平台使用该等用户名下内容、行使本协议项下的权利不受任何第三方关于侵犯知识产权等权利的指控、索赔或其他权利追索。用户不得发布违法信息，不得发布无医疗根据的、无益的医疗内容。用户承诺自己在使用绿松果医者平台时实施的所有行为均遵守国家法律、法规和绿松果医者平台管理规定以及各种社会公共利益或公共道德。如有违反导致任何法律后果的发生，用户将以自己的名义独立承担所有相应的法律责任。
3.5 用户必须遵守卫生部相关法律法规及国家食品药品监督管理局的有关法规，认证医生只能提供医疗卫生信息建议，不得从事网上诊断和治疗活动，不得进行网上售药，不得进行广告性质宣传活动。
3.6 用户不得将涉及医疗纠纷的问题或其它有责任争议的问题在绿松果医者平台发布，关于医疗纠纷的问题，请另行咨询律师或相关主管部门寻求援助，绿松果医者平台有权将此类信息删除。
3.7 用户如发现其他用户有违法或违反绿松果医者平台管理规定的行为，可以向绿松果医者平台进行反映要求处理。用户因绿松果医者平台展示的内容与其他用户发生纠纷的，司法部门可以要求绿松果医者平台根据法律程序提供该案件所需证据材料。
3.8 用户同意维护和保障绿松果医者平台和源码悦动的利益，如果因用户违法或违反本协议规定或绿松果医者平台管理规定而给绿松果医者平台或源码悦动造成任何不利法律后果，用户应承担该等不利法律后果，包括但不限于向绿松果医者平台或源码悦动赔偿相关全部损失。
四、绿松果医者平台的权利和责任：
4.1 绿松果医者平台有义务在现有技术上维护整个网络平台的正常运行，并努力提升和改进技术，保障用户的正常使用，并提供优质服务。
4.2绿松果医者平台展示的所有医学信息以及认证医生建议，不作为诊断和治疗的依据, 不能替代医生与病人面对面的诊疗。
4.3 绿松果医者平台有权对用户的注册数据及活动行为进行查阅，发现注册数据或活动行为中存在任何问题或怀疑，均有权向用户发出询问及要求改正的通知或者直接作出删除等处理。
4.4 因不可抗力（如火灾、水灾、暴动、骚乱、战争、自然灾害等）导致绿松果医者平台的服务中断或者用户数据损坏、丢失等，绿松果医者平台不承担任何责任。
4.5 许可使用权：用户在此独家、无偿、永久且不可撤销地授予绿松果医者平台与用户名下内容相关的财产性权利，如著作权财产性权利（包括但不限于信息网络传播权），使绿松果医者平台有权使用、复制、修订、改写、发布、翻译、分发、传播、执行和展示用户公示于网站或其他互联网渠道（包括但不限于绿松果医者平台自身）的用户名下内容或制作其派生作品，和/或以现在已知或日后开发的任何形式、媒体或技术，将上述信息纳入其它作品内。用户同意，绿松果医者平台以该等用户名下内容为基础形成的新作品的知识产权应归属于绿松果医者平台所有。
五、服务变更、中断或终止：
5.1 双方任何一方违约，造成另一方损失的，违约方应向守约方赔偿实际损失。
5.2 用户提供的授权内容违反法律法规及其他规范性文件，或绿松果医者平台及其关联公司按照本协议约定使用授权内容时侵犯了任何第三方的合法权益（包括但不限于知识产权权利），由此导致的一切不利后果均由用户自行承担，造成绿松果医者平台或其关联方损失的，用户还应向绿松果医者平台或其关联方承担赔偿责任。
5.3出现如下情况之一的，本协议终止：
5.3.1 绿松果医者平台宣布破产或进入清算或解散程序之日起，本合同终止。
5.3.2 如不可抗力持续三十（30）日以上，甲乙任何一方均有权终止本协议。
5.4 甲乙任何一方违约，守约方有权要求违约方在5个工作日内予以修正，否则守约方有权立即终止本协议，该终止不影响守约方要求违约方承担违约责任。
六、隐私声明：
6.1 适用范围
6.1.1 用户注册绿松果医者平台时，根据网站要求提供的个人信息；
6.1.2 用户使用绿松果医者平台服务、参加相关活动、访问网站网页（或移动客户端）时，网站自动接收并记录用户浏览器上的服务器数据，包括但不限于IP地址、网站Cookie中的资料及用户要求取用的网页记录；
6.1.3 绿松果医者平台通过合法途径从其他途径取得的用户个人资料。
6.2 信息保密
6.2.1 保护用户隐私是绿松果医者平台的一项基本政策，绿松果医者平台保证不对外公开或向第三方提供单个用户的注册资料及用户在使用网络服务时存储的非公开内容，但下列情况除外：事先获得用户的明确授权；根据有关的法律法规要求；按照相关政府主管部门的要求；为维护社会公众的利益；为维护绿松果医者平台的合法权益。
6.2.2 绿松果医者平台可能会与第三方合作向用户提供相关的网络服务，在此情况下，如该第三方同意承担与绿松果医者平台同等的保护用户隐私的责任，则绿松果医者平台有权将用户的注册资料等提供给该第三方。
6.3 信息使用：
6.3.1 在不透露单个用户隐私资料的前提下，绿松果医者平台有权对整个用户数据库进行分析并对用户数据库进行商业上的利用。
6.3.2 为服务用户的目的，绿松果医者平台可能通过使用用户的个人信息，向用户提供服务，包括但不限于向用户发出活动和服务信息等。
七、免责声明：
7.1 用户使用绿松果医者平台服务所存在的风险将完全由其自己承担；因其使用绿松果医者平台服务而产生的一切后果也由其自己承担，绿松果医者平台对用户不承担任何责任。
7.2 绿松果医者平台不担保网络服务一定能满足用户的要求，也不担保网络服务不会中断，对网络服务的及时性、安全性、准确性也都不作担保。
7.3 绿松果医者平台不保证为向用户提供便利而设置的外部链接的准确性和完整性，同时，对于该等外部链接指向的不由绿松果医者平台实际控制的任何网页上的内容，绿松果医者平台不承担任何责任。
7.4 对于因不可抗力或绿松果医者平台不能控制的原因造成的网络服务中断或其它缺陷，绿松果医者平台不承担任何责任，但将尽力减少因此而给用户造成的损失和影响。
7.5 因用户在绿松果医者平台上发布的任何信息、回复、资讯、文章而引发的纠纷，或因用户违反相关规定在绿松果医者平台上从事任何其他活动而引发的纠纷，由用户自行解决并承担任何不利后果，绿松果医者平台不承担任何责任。
八、服务条款修改：
8.1绿松果医者平台有权根据服务情况变更、终止本协议以及绿松果医者平台管理规定
的各项条款，绿松果医者平台将会通过适当方式向用户提示修改内容。
8.2如果用户不同意本协议或绿松果医者平台管理规定所做的修改，有权停止使用绿松
果医者平台的网络服务。如果用户继续使用绿松果医者平台的网络服务，则视为用户接
受绿松果医者平台对服务条款相关条款所做的修改。
`;

const jkbAgreement = `
欢迎您与北京源码悦动健康科技有限公司共同签署《绿松果医者平台服务协议》（以下简称本协议），绿松果医者平台（本协议中提及的绿松果医者平台包括平台、小程序及软硬件客户端）为北京源码悦动健康科技有限公司（以下简称源码悦动）运营和提供的在线信息服务平台，欢迎各大医院医生关注并使用。
请仔细阅读本协议，当您按照提示填写信息、签署本协议后，表示您已充分阅读、理解并接受本协议的全部内容，并与绿松果医者平台达成一致，成为绿松果医者平台用户，并服从于绿松果医者平台的统一管理。
一、合作内容：
1.1 绿松果医者平台为用户制作个人在线名片，聚合用户通过互联网发布和传播的医疗健康内容和相关资讯。通过人工智能技术服务对内容进行冒名识别、风险识别等提示，用户签署本协议、注册、登录、使用绿松果医者平台提供的服务，即视为用户完全了解并同意不可撤销地独家且无偿地授权、许可源码悦动收集、统计、存储、协助管理和使用用户通过互联网生产、创作、发布的信息和内容，包括但不限于问答内容、图文文章内容、著作文献、音频、视频等形态医疗健康相关内容（以下简称用户名下内容），并授权源码悦动与该等用户名下内容相关的财产性权利，如著作权财产性权利（包括但不限于信息网络传播权）。用户可通过绿松果医者平台管理工具，进行自身执业信息更新，对自己名下内容的提交、更新、删除、在个人名片中置顶等管理操作，保护个人内容质量和品牌。
1.2 用户授权绿松果医者平台存储其个人信息（包括个人身份信息、执业信息）及用户名下内容，同时授权绿松果医者平台在自身产品的内容使用权和转授权、肖像展示权，进行执业信息展示，并在全网流量渠道推广用户个人名片及分发优质的用户名下内容，帮助用户提升个人影响力。
1.3 绿松果医者平台的具体服务由源码悦动提供，源码悦动保留随时变更、中断或终止部分或全部网络服务的权利，且无需向用户或任何第三方承担任何责任。
1.4 绿松果医者平台仅作为医疗健康信息和咨询平台，用户通过绿松果医者平台发表的各种言论（包括但不仅限于医学文献、诊疗建议、科普内容等），不代表绿松果医者平台赞同其观点或证实其内容的真实性，也不能作为临床诊断及医疗的依据，不能替代医生与病人面对面的诊断和治疗。
二、用户及注册：
2.1 绿松果医者平台提供申请/注册流程，用户名和密码由用户自行保管；用户应当对以其帐号进行的所有活动和事件负法律责任。
2.2 用户在注册过程中，根据绿松果医者平台提示可以选择表示“同意”的操作。当用户勾选“同意”时即视为用户已仔细阅读、签署本协议，同意接受本协议项下的所有条款约束。用户同意所有注册协议条款并完成注册程序，才能成为绿松果医者平台的正式用户、使用绿松果医者平台提供的服务。
2.3 用户申请/注册时，在用户名、头像和简介等注册信息中不得出现违法和不良信息，否则绿松果医者平台有权拒绝提供服务，并删除该账号。
2.4 用户申请/注册绿松果医者平台时，应当遵守本第二条的规定，并进行实名认证，绿松果医者平台有权采取合理方式对用户身份进行核实，通过认证后绿松果医者平台将提供相应服务。
三、用户的权利和责任：
3.1 用户有权利拥有自己在绿松果医者平台的用户名及密码，并有权利使用自己的用户名及密码随时登陆绿松果医者平台服务。用户不得以任何形式转让或授权他人使用，亦不得盗用他人帐号，由以上行为造成的后果由用户自行承担。
3.2 用户应保证提供真实、准确和完整的个人资料和信息以符合绿松果医者平台的认证要求。如用户个人资料或信息发生变动，用户应及时更改。
3.3 用户应保证其具有签署本协议的权利和能力，并具备医务人员的必要专业资质。
3.4 用户对自己发布的用户名下内容承担责任，并确保绿松果医者平台使用该等用户名下内容、行使本协议项下的权利不受任何第三方关于侵犯知识产权等权利的指控、索赔或其他权利追索。用户不得发布违法信息，不得发布无医疗根据的、无益的医疗内容。用户承诺自己在使用绿松果医者平台时实施的所有行为均遵守国家法律、法规和绿松果医者平台管理规定以及各种社会公共利益或公共道德。如有违反导致任何法律后果的发生，用户将以自己的名义独立承担所有相应的法律责任。
3.5 用户必须遵守卫生部相关法律法规及国家食品药品监督管理局的有关法规，认证医生只能提供医疗卫生信息建议，不得从事网上诊断和治疗活动，不得进行网上售药，不得进行广告性质宣传活动。
3.6 用户不得将涉及医疗纠纷的问题或其它有责任争议的问题在绿松果医者平台发布，关于医疗纠纷的问题，请另行咨询律师或相关主管部门寻求援助，绿松果医者平台有权将此类信息删除。
3.7 用户如发现其他用户有违法或违反绿松果医者平台管理规定的行为，可以向绿松果医者平台进行反映要求处理。用户因绿松果医者平台展示的内容与其他用户发生纠纷的，司法部门可以要求绿松果医者平台根据法律程序提供该案件所需证据材料。
3.8 用户同意维护和保障绿松果医者平台和源码悦动的利益，如果因用户违法或违反本协议规定或绿松果医者平台管理规定而给绿松果医者平台或源码悦动造成任何不利法律后果，用户应承担该等不利法律后果，包括但不限于向绿松果医者平台或源码悦动赔偿相关全部损失。
四、绿松果医者平台的权利和责任：
4.1 绿松果医者平台有义务在现有技术上维护整个网络平台的正常运行，并努力提升和改进技术，保障用户的正常使用，并提供优质服务。
4.2绿松果医者平台展示的所有医学信息以及认证医生建议，不作为诊断和治疗的依据, 不能替代医生与病人面对面的诊疗。
4.3 绿松果医者平台有权对用户的注册数据及活动行为进行查阅，发现注册数据或活动行为中存在任何问题或怀疑，均有权向用户发出询问及要求改正的通知或者直接作出删除等处理。
4.4 因不可抗力（如火灾、水灾、暴动、骚乱、战争、自然灾害等）导致绿松果医者平台的服务中断或者用户数据损坏、丢失等，绿松果医者平台不承担任何责任。
4.5 许可使用权：用户在此独家、无偿、永久且不可撤销地授予绿松果医者平台与用户名下内容相关的财产性权利，如著作权财产性权利（包括但不限于信息网络传播权），使绿松果医者平台有权使用、复制、修订、改写、发布、翻译、分发、传播、执行和展示用户公示于网站或其他互联网渠道（包括但不限于绿松果医者平台自身）的用户名下内容或制作其派生作品，和/或以现在已知或日后开发的任何形式、媒体或技术，将上述信息纳入其它作品内。用户同意，绿松果医者平台以该等用户名下内容为基础形成的新作品的知识产权应归属于绿松果医者平台所有。
五、服务变更、中断或终止：
5.1 双方任何一方违约，造成另一方损失的，违约方应向守约方赔偿实际损失。
5.2 用户提供的授权内容违反法律法规及其他规范性文件，或绿松果医者平台及其关联公司按照本协议约定使用授权内容时侵犯了任何第三方的合法权益（包括但不限于知识产权权利），由此导致的一切不利后果均由用户自行承担，造成绿松果医者平台或其关联方损失的，用户还应向绿松果医者平台或其关联方承担赔偿责任。
5.3出现如下情况之一的，本协议终止：
5.3.1 绿松果医者平台宣布破产或进入清算或解散程序之日起，本合同终止。
5.3.2 如不可抗力持续三十（30）日以上，甲乙任何一方均有权终止本协议。
5.4 甲乙任何一方违约，守约方有权要求违约方在5个工作日内予以修正，否则守约方有权立即终止本协议，该终止不影响守约方要求违约方承担违约责任。
六、隐私声明：
6.1 适用范围
6.1.1 用户注册绿松果医者平台时，根据网站要求提供的个人信息；
6.1.2 用户使用绿松果医者平台服务、参加相关活动、访问网站网页（或移动客户端）时，网站自动接收并记录用户浏览器上的服务器数据，包括但不限于IP地址、网站Cookie中的资料及用户要求取用的网页记录；
6.1.3 绿松果医者平台通过合法途径从其他途径取得的用户个人资料。
6.2 信息保密
6.2.1 保护用户隐私是绿松果医者平台的一项基本政策，绿松果医者平台保证不对外公开或向第三方提供单个用户的注册资料及用户在使用网络服务时存储的非公开内容，但下列情况除外：事先获得用户的明确授权；根据有关的法律法规要求；按照相关政府主管部门的要求；为维护社会公众的利益；为维护绿松果医者平台的合法权益。
6.2.2 绿松果医者平台可能会与第三方合作向用户提供相关的网络服务，在此情况下，如该第三方同意承担与绿松果医者平台同等的保护用户隐私的责任，则绿松果医者平台有权将用户的注册资料等提供给该第三方。
6.3 信息使用：
6.3.1 在不透露单个用户隐私资料的前提下，绿松果医者平台有权对整个用户数据库进行分析并对用户数据库进行商业上的利用。
6.3.2 为服务用户的目的，绿松果医者平台可能通过使用用户的个人信息，向用户提供服务，包括但不限于向用户发出活动和服务信息等。
七、免责声明：
7.1 用户使用绿松果医者平台服务所存在的风险将完全由其自己承担；因其使用绿松果医者平台服务而产生的一切后果也由其自己承担，绿松果医者平台对用户不承担任何责任。
7.2 绿松果医者平台不担保网络服务一定能满足用户的要求，也不担保网络服务不会中断，对网络服务的及时性、安全性、准确性也都不作担保。
7.3 绿松果医者平台不保证为向用户提供便利而设置的外部链接的准确性和完整性，同时，对于该等外部链接指向的不由绿松果医者平台实际控制的任何网页上的内容，绿松果医者平台不承担任何责任。
7.4 对于因不可抗力或绿松果医者平台不能控制的原因造成的网络服务中断或其它缺陷，绿松果医者平台不承担任何责任，但将尽力减少因此而给用户造成的损失和影响。
7.5 因用户在绿松果医者平台上发布的任何信息、回复、资讯、文章而引发的纠纷，或因用户违反相关规定在绿松果医者平台上从事任何其他活动而引发的纠纷，由用户自行解决并承担任何不利后果，绿松果医者平台不承担任何责任。
八、服务条款修改：
8.1绿松果医者平台有权根据服务情况变更、终止本协议以及绿松果医者平台管理规定
的各项条款，绿松果医者平台将会通过适当方式向用户提示修改内容。
8.2如果用户不同意本协议或绿松果医者平台管理规定所做的修改，有权停止使用绿松
果医者平台的网络服务。如果用户继续使用绿松果医者平台的网络服务，则视为用户接
受绿松果医者平台对服务条款相关条款所做的修改。
`;
const agreement = `
1.用户协议的接受与修改 
本协议是用户（您）与健康报社有限公司举办的健康中国医者名片平台（下称“平台”）之间的协议，平台将按照本协议约定之内容为您提供服务。
1.1本协议所述服务条款构成您（个人或者单位、组织、团体等）使用平台提供的相关服务的先决条件。若您不同意本协议中所述服务条款或其后对协议条款的修改，您应不使用或主动取消平台提供的相关服务。您的使用行为将视作对本协议全部条款的完全接受。 
2.服务说明 
2.1平台向您提供包括但不限于如下服务：
2.1.1本微信公众号，即「健康中国医者名片」服务号，提供注册、名片信息管理、内容管理、内容提交等功能；
2.1.2健康报社有限公司直接拥有或运营的包括但不限于PC、平板、手机、TV端等全部终端客户端产品及互联网流量平台开通的媒体号、内容号等载体，健康报社有限公司委托进行运营传播的合作伙伴，对医者名片及通过本项目提交的内容向公众传播，提升用户的个人影响力，并促进公众健康科普素养提升；
2.1.3平台用户活动、用户作品、个人资料；
2.1.4平台直接拥有或运营的服务器、网络存储空间；
2.1.5平台提供给您的其他技术和/或服务。
2.2平台所提供的服务，均限于在平台内使用，任何以恶意破解等非法手段将平台所提供的服务与平台分离的行为，皆不属于本协议约定的由平台提供的服务。由此引起的一切后果由行为人负责，平台将保留依法追究行为人法律责任的权利。
2.3活动所公布的方式包括注册、登录、关注「健康中国医者名片」服务号，为参与本活动和获得本平台服务的唯一合法方式，用户通过其他任何途径、任何渠道、任何方式参与活动及获取服务均为非法所得，平台概不承认其效力，且一经发现，平台有权立即作出删除、清零、封号等处理，任何因此导致的一切不利后果均由用户自行承担。请用户妥善保管自己的账号和密码，加强密码安全性，谨防账号泄露或被盗。因用户账号被泄露或被盗而造成的任何损失，平台不承担补偿责任。
2.4用户理解并认可平台享有如下权利，平台行使如下权利不视为违约，用户不追究或者豁免平台的相关法律责任：用户有权长期使用其合法获得的平台账号及其账号下活动、作品等，但是用户确认其仅享有上述服务和产品的使用权，上述服务和产品，及其衍生物的所有权及知识产权均归平台所有（用户经合法渠道取得的实体产品所有权除外）。平台有权根据实际情况自行决定收回日期，无需另行通知用户亦无需征得用户同意。
2.5平台有权提前向用户公告（包括但不限于弹出页面公告、服务号消息推送等）以修改、替换、升级与平台服务相关的任何软件。如果用户不同意或者不接受平台相关软件的修改、替代、升级，请直接拒绝、停止、取消使用行为，否则视为用户同意并接受平台相关软件的修改、替代、升级，同时该同意并接受的行为仍受本协议约束。
3.用户注册 
如果您使用平台提供的网络存储空间进行医者名片的开通、发布，对音视频、图文、问答等形式的医疗健康内容的上传及传播服务，您需要关注服务号并注册一个账号，确保注册信息的真实性、正确性及完整性，如果上述注册信息发生变化，您应及时更改。在完成本服务的登记程序后，您应维护账号及密码的机密安全。您应对任何人利用您的账号及密码所进行的活动完全负责，平台无法对非法或未经您授权使用您账号及密码的行为作出甄别，因此平台将不承担任何责任。同时您同意并承诺做到：
3.1当您的账号或密码遭到未经授权的使用，或者发生任何安全问题时，您会立即有效地通知到平台；
3.2当您每次登录平台或使用相关服务后，会将有关账号等安全退出；
3.3您同意接受平台通过电子邮件、客户端、网页、微信消息推送或其他合法方式向您发送通知信息和其他相关信息；
3.4您承诺不在注册、使用平台账号从事以下行为：
3.4.1故意冒用他人信息为自己注册平台账号；
3.4.2未经他人合法授权以他人名义注册平台账号；
3.4.3窃取、盗用他人的平台账号等；
3.4.4使用侮辱、诽谤、色情、政治等违反法律、道德及公序良俗的词语注册平台账号；
3.4.5以非法占有平台相关服务资源为目的，通过正当或非正当手段恶意利用网站漏洞；
3.4.6侵犯他人合法权益的其他内容。
3.5您在此同意，平台有权对违反上述条款的用户作出禁止注册及/或封号的处理。
3.6您在此同意，为您同步开通小荷医生App账号。
4.平台上的内容 
4.1平台上的内容是指您在平台上传、传播的图片、视频、音频或其他任何形式的内容，包括但不限于图像、文字、链接等。
4.2您在平台上传或发布的作品，您保证对其享有合法的著作权或获得相应授权，平台有权展示、散布及推广前述内容。
4.3对于用户通过平台提供的UGC服务上传至平台的任何信息内容，包括但不限于视频、图片、文字等形式，用户在此同意授权平台使用并进行传播。
4.4任何经由平台提供的服务，以上传、张贴、发送电子邮件或任何其他方式传送的资讯、资料、文字、软件、音乐、音讯、照片、图形、视讯、信息或其他资料（以下简称“内容”），无论系公开还是私下传送，均由内容提供者、上传者承担责任。
4.5平台无法预先知晓并合理控制经由平台服务上传之内容，因此，您已预知在使用平台的服务时，可能会接触到部分令人不快、不适或厌恶之内容，您同意放弃由此产生的针对平台的任何追索权。但平台有权依法停止传输任何前述内容并采取相应处理，包括但不限于暂停您继续使用平台的部分或全部服务，保存有关记录并向有关机关报告。
4.6您需独立对自己在平台上实施的行为承担法律责任。若您使用平台服务的行为不符合本协议，平台有权作出独立处理，且在无需事先通知及/或征得用户同意的情况下停用您的账号。您若在平台上散布和传播反动、色情或其他违反国家法律、规定的信息，平台的系统记录可能作为您违反相关法律的证据。
5.使用规则 
5.1用户在使用服务的过程中，应遵守以下法律法规：
5.1.1《中华人民共和国保守国家秘密法》
5.1.2《中华人民共和国著作权法》
5.1.3《中华人民共和国计算机信息系统安全保护条例》
5.1.4《计算机软件保护条例》
5.1.5《互联网电子公告服务管理规定》
5.1.6《信息网络传播权保护条例》
5.1.7《中华人民共和国网络安全法》
5.1.8其他有关计算机及互联网规定的法律、法规。
5.2在任何情况下，平台一旦合理地认为用户的行为可能违反上述法律、法规，可以在任何时候，不经事先通知终止向该用户提供服务。
5.3禁止用户从事以下行为：
5.3.1制作、上传、复制、传送、传播包含任何反对宪法所确定的基本原则、危害国家安全、泄露国家秘密、颠覆国家政权、破坏国家统一、破坏民族团结、损害国家荣誉和利益、煽动民族仇恨、民族歧视、破坏民族团结、破坏国家宗教政策、宣扬邪教和封建迷信、淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪、侮辱或者诽谤他人，侵害他人合法权益的等法律、行政法规禁止的内容或其他令人反感的包括但不限于资讯、资料、文字、软件、音乐、照片、图形、信息或其他资料；
5.3.2以任何方式危害未成年人；
5.3.3冒充任何人或机构，或以虚伪不实的方式谎称或使人误认为与任何人或任何机构有关；
5.3.4伪造标题或以其他方式操控识别资料，使人误认为该内容为平台所传送；
5.3.5将无权传送的内容（例如内部资料、机密资料）进行上载、张贴、发送电子邮件或以其他方式传送；
5.3.6将侵犯任何人的专利、商标、著作权、商业秘密等知识产权的内容加以上载、张贴、发送电子邮件或以其他方式传送；
5.3.7将广告函件、促销资料、"垃圾邮件"等，加以上载、张贴、发送电子邮件或以其他方式传送；
5.3.8跟踪或以其他方式骚扰他人；
5.3.9将有关干扰、破坏或限制任何计算机软件、硬件或通讯设备功能的软件病毒或其他计算机代码、档案和程序之资料，加以上载、张贴、发送电子邮件或以其他方式传送；
5.3.10干扰或破坏平台服务或与平台服务相连的服务器和网络，或不遵守本协议之规定；
5.3.11故意或非故意违反任何相关的中国法律、法规、规章、条例等其他具有法律效力的规范；
5.3.12用户需保证其上传内容不得违反广电总局的相关规定，包括但不限于《互联网视听节目服务管理规定》（广电总局56号令）等，其上传内容应当符合法律、行政法规、部门规章的规定，上传内容不得含有以下内容：
5.3.12.1 反对宪法确定的基本原则的；
5.3.12.2 危害国家统一、主权和领土完整的；
5.3.12.3 泄露国家秘密、危害国家安全或者损害国家荣誉和利益的；
5.3.12.4 煽动民族仇恨、民族歧视，破坏民族团结，或者侵害民族风俗、习惯的；
5.3.12.5 扰乱社会秩序，破坏社会稳定的；
5.3.12.6 诱导未成年人违法犯罪和渲染暴力、色情、赌博、恐怖活动的；
5.3.12.7 侮辱或者诽谤他人，侵害公民个人隐私等他人合法权益的；
5.3.12.8 危害社会公德，损害民族优秀文化传统的；
5.3.12.9 有关法律、行政法规和国家规定禁止的其他内容。
5.3.13如用户提供的上传内容含有以上违反政策法律法规的信息或者内容的，由用户直接承担以上导致的一切不利后果，如因此给平台造成不利后果的，用户应负责消除影响，并且赔偿因此导致的一切损失。
5.4用户不得通过任何渠道或媒体（包括但不限于自媒体等）发出“与平台合作”、“与平台共同出品”等任何携带“平台”品牌的字样，如用户需宣传推广合作内容，用户只能在宣传中提及内容本身而不得提及与平台关系或者擅自以平台品牌进行推广，凡是用户的发稿带有“平台”的一切宣传稿件必须通过平台相应合作部门之书面同意，否则因此给平台造成的一切损失用户应予以赔偿。 
6.隐私权策略 
平台注重保护用户个人信息，所谓用户个人信息，是指以电子或者其他方式记录的能够单独或者与其他信息结合识别个人身份的各种信息，包括但不限于用户的姓名、出生日期、身份证件号码、个人生物识别信息、住址、电话号码等。本隐私策略涉及用户个人信息收集、使用、共享和保护的有关情况，适用于平台向用户提供的所有相关服务。平台建议您完整地阅读并理解本隐私策略，如果您不同意本隐私策略的任何内容，您应立即停止使用平台提供的服务。当您使用平台提供的任一服务时，即表示您已同意平台按照本隐私策略来合法收集、使用、共享和保护您的个人信息。 
6.1用户充分理解并同意平台为了向用户提供更好以及更个性化的服务，主动、被动地收集用户如下个人信息，并有针对性的为用户提供合适的内容和提升用户体验：
6.1.1用户向平台提供的信息。当您注册平台账户及您在使用平台相关服务时填写、提交及/或其他任何方式提供的信息，包括但不限于您的姓名、性别、出生年月日、身份证号码、电话号码、电子邮箱或网银在线账号、银行卡信息及相关附加信息等。您可以选择不提供某一或某些信息，但您也因此可能无法使用平台的相关服务。请您理解，我们使用您提供的信息是为了回应您的要求，为您在平台享受服务提供便利，完善平台以及与您进行信息沟通。另外，我们可能会将您所提供的信息与您的平台账户关联，用以识别您的身份。
6.1.2用户使用服务过程中收集的信息。为了提高服务质量和用户体验，平台会留存您使用服务以及使用方式的相关信息，包括浏览器和计算机上的信息（在您访问平台或使用平台服务时，平台系统自动接收并记录的您的浏览器和计算机上的信息，包括但不限于您的IP地址、浏览器的类型、使用的语言、访问日期和时间、软硬件特征信息及您需求的网页记录等数据）、位置信息、设备信息、行为信息。
6.1.3来自第三方的信息。在您注册平台账户和使用服务过程中，您授权的平台可向平台的关联方、合作伙伴所收集的相关信息，以及您授权的平台的关联方、合作伙伴可向平台分享相关的信息。这些信息包括但不限于您的身份信息、行为信息、设备信息等，平台会将此类信息汇总，用于帮助平台向您提供更好以及更加个性化的服务或更好的预防互联网欺诈。
6.1.4 收集、使用个人信息目的变更的处理。随着业务的发展，可能会对平台的功能和提供的服务有所调整变化。原则上，当新功能或服务与平台创作、发布内容、账户权益等场景相关时，收集与使用的个人信息将与原处理目的具有直接或合理关联。在与原处理目的无直接或合理关联的场景下，我们收集、使用你您的个人信息，会再次进行告知，并征得你您的同意。
6.2为向您提供服务、提升我们的服务质量以及优化您的服务体验，平台会在符合法律规定或根据您授权的情况下管理和使用您的个人信息，并主要用于下列用途： 
6.2.1向您提供您使用的各项服务，并维护、改进这些服务。
6.2.2向您推荐您可能感兴趣的内容，包括但不限于向您发出产品和服务信息，或通过系统向您展示个性化的第三方推广信息，或在征得您同意的情况下与平台的合作伙伴共享信息以便他们向您发送有关其产品和服务的信息。如您不希望接收上述信息，可通过相应的退订功能进行退订。
6.2.3平台可能使用您的个人信息以验证身份、预防、发现、调查欺诈、危害安全、非法或违反与平台或其关联方协议、政策或规则的行为，以保护您、其他平台用户，或平台或其关联方的合法权益。
6.2.4平台可能会将来自某项服务的个人信息与来自其他服务所获得的信息结合起来，用于为了给您提供更加个性化的服务使用。
6.2.5平台会对提供的服务使用情况进行统计，并可能会与公众或第三方分享这些统计信息，以展示平台的产品或服务的整体使用趋势。但这些统计信息不包含您的任何身份识别信息。
6.2.6让您参与有关我们产品及服务的调查。
6.2.7经您同意或授权的其他用途。
6.3用户可以通过登录自己的账户查询、更正及删除自己的账户信息等使用我们服务时您提供的个人信息。如果您认为平台存在违反法律、行政法规的规定或者与您的约定收集、使用您个人信息的，有权要求平台予以更正或删除。
6.4平台对收集的用户个人信息负有保密及安全保护义务，不会在未经合法用户授权时，公开、编辑或者向任何第三方透露用户个人信息及保存在平台的非公开内容，除非遇到以下情况：
6.4.1征得您的同意或授权；
6.4.2按照法律、法规、规章的规定或行政、司法机构的要求；
6.4.3为维护其他用户、平台自身及社会公众的合法权益，即您出现违反适用的法律法规或平台服务规则或相关协议的情况，或为保护平台及其关联公司或其他用户或社会公众的权利、财产或安全免遭损害；
6.4.4出于实现用户个人信息的管理和使用之用途，或为履行本隐私策略中的义务和行使权利，向平台的关联方、合作伙伴或代表平台履行某项职能的第三方分享您的个人信息，且受本隐私策略中所声明目的的约束；
6.4.5协助解决争议，即某些情况下只有共享您的信息，才能便于处理您与他人的纠纷或争议；
6.4.6其他依法需要共享用户个人信息的情况。
6.5平台重视对未成年人个人信息的保护，建议未成年用户请监护人仔细阅读本隐私权策略，并在征得监护人同意的前提下使用平台的服务或向平台提供信息。
6.6Cookie 及网络 Beacon的使用:
6.6.1Cookie的使用。Cookie是由网页服务器存放在您的访问设备上的文本文件。指定给您的Cookie 是唯一的，它只能被将Cookie发布给您的域中的Web服务器读取。请您理解，平台使用 Cookie 来帮助您实现您的联机体验的个性化，使您在平台及其关联方获得更轻松的访问体验。例如，Cookie 会帮助您在后续访问平台时调用您的信息，简化记录您填写个人信息（例如一键登录等）的流程；保护您的数据安全等。您有权接受或拒绝 Cookie。大多数浏览器会自动接受Cookie，但您通常可根据自己的需要来修改浏览器的设置以拒绝 Cookie。如果选择拒绝 Cookie，那么您可能无法完全体验所访问的平台或某些服务的全部功能。
6.6.2网络Beacon的使用。平台网页上常会包含一些电子图象（称为"单像素" GIF 文件或 "网络 beacon"），它们可以帮助网站计算浏览网页的用户或访问某些Cookie。平台使用网络beacon的方式有：
（1）平台通过在网站上使用网络beacon，计算用户访问数量，并通过访问 cookie 辨认注册用户；
（2）平台通过得到的Cookie信息，为用户提供个性化服务。
6.7平台重视并严格保护用户的个人信息安全，包括但不限于使用各种制度、安全技术和程序等措施来保护您的个人信息不被未经授权的访问、篡改、披露或破坏。你您知悉并理解，你您接入平台服务所用的系统和通讯网络，有可能因平台可控范围外的因素而出现问题。因此，强烈建议你您采取积极措施保护个人信息的安全，包括但不限于使用复杂密码、定期修改密码、不将自己的账号密码等个人信息透露给他人。您理解并同意，平台将通过备份、对密码进行加密等安全措施以保护您的账户信息等个人信息不丢失、不被滥用和变造。
平台会制定应急处理预案，并在发生用户信息安全事件时立即启动应急预案，努力阻止该等安全事件的影响和后果扩大。一旦发生用户信息安全事件（泄露、丢失等）后，平台将按照法律法规的要求，及时向你您告知：安全事件的基本情况和可能的影响、平台已经采取或将要采取的处置措施、你您可自主防范和降低风险的建议、对你您的补救措施等。平台将及时将事件相关情况以推送通知、邮件、信函、短信等形式告知你您，难以逐一告知时，平台会采取合理、有效的方式发布公告。同时，平台还将按照相关监管部门要求，上报用户信息安全事件的处置情况。
6.8  隐私政策的修订和通知。为了给您提供更好的服务平台及相关服务将不时更新与变化，平台会适时对本隐私政策进行修订，这些修订构成本隐私政策的一部分并具有等同于本隐私政策的效力，未经您明确同意，平台不会削减您依据当前生效的本隐私政策所应享受的权利。隐私政策更新后，平台会以适当的方式提醒您更新的内容，以便您及时了解本隐私政策的最新版本。
7.第三方链接 
平台服务可能会提供与其他国际互联网网站或资源进行链接。除非另有声明，平台无法对第三方网站之服务进行控制，用户因使用或依赖上述网站或资源所产生的损失或损害，平台不承担任何责任。 
8.知识产权 
8.1受国际版权公约、著作权法、专利法、及其他知识产权方面的法律法规的保护，平台服务及本服务所使用的软件、技术、商标、材料等的所有知识产权归平台所有和享有。“知识产权”包括在专利法、版权法、商标法、反不正当竞争法中等法律规定的任何和所有权利、任何和所有其它所有权以及其中的任何和所有应用、更新、扩展和恢复。
8.2用户不得修改、改编、翻译平台服务所使用的软件、技术、材料等，或者创作与之相关的派生作品，不得通过反向工程、反编译、反汇编或其他类似行为获得其的源代码，否则由此引起的一切法律后果由用户负责，平台将依法追究违约方的法律责任。
8.3用户不得恶意修改、复制、传播平台服务所使用的软件、技术、材料等。否则，用户自行承担因此而造成对其他人的损害，或者造成对平台形象损害，要承担相应的法律责任。
8.4用户不得擅自删除、掩盖或更改平台的版权声明、商标或其它权利声明。平台平台所有设计图样以及其他图样、产品及服务名称，均为平台及/或其关联公司所享有的商标、标识。任何人不得使用、复制或用作其他用途。
8.5平台对其自制内容和其他通过授权取得的独占内容享有完全知识产权，未经平台许可，任何单位和个人不得私自转载、传播和提供观看服务或者有其他侵犯平台知识产权的行为。否则，平台将追究侵权行为人的法律责任。
8.6平台所有和享有的知识产权，不因用户的任何使用行为而发生权利转移。
8.7为了促进知识的分享和传播，用户在此平台发布的全部内容，授予平台方永久的、免费的、不可撤销的使用和传播许可和转授权的权利。平台方有权将该内容用于名下各种形态的产品和服务上，并在展示相关内容是展现用户执业信息及肖像
8.8用户同意平台以该等用户名下内容为基础汇编、改变形成的新作品知识产权应归属于平台方所有。
9.免责声明 
9.1平台对于任何包含、经由或连接、下载或从任何与有关本网络服务所获得的任何内容、信息或广告，不声明或保证其正确性或可靠性；并且对于用户经本服务上的广告、展示而购买、取得的任何产品、信息或资料，平台不负保证责任。用户自行负担使用本服务的风险。
9.2平台有权但无义务，改善或更正平台服务任何部分之任何疏漏、错误。
9.3平台对如下事项不做担保（包括但不限于）：
9.3.1平台提供的网站、客户端等软件虽然均已经过平台测试，但由于技术本身的局限性，平台不能保证其与其他软硬件、系统完全兼容。如果出现不兼容的情况，用户可将情况报告平台，以获得技术支持。如果无法解决问题，用户可以选择卸载、停止使用平台服务。
9.3.2使用平台服务涉及到Internet服务，可能会受到各个环节不稳定因素的影响。因不可抗力、黑客攻击、系统不稳定、网络中断、用户关机、通信线路等原因，均可能造成平台服务中断或不能满足用户要求的情况。平台不保证平台服务适合用户的使用要求。
9.3.3由于平台提供的客户端等软件可以通过网络途径下载、传播，因此对于从非平台指定官方站点下载、非平台指定途径获得的平台服务相关软件，平台无法保证其是否感染计算机病毒、是否隐藏有伪装的木马程序等黑客软件，也不承担用户由此遭受的一切直接或间接损害赔偿等法律责任。
9.3.4平台不做任何与平台服务、产品的安全性、可靠性、及时性和性能有关的担保。
9.3.5平台不保证其提供的任何产品、服务或其他材料符合用户的期望。
9.4用户使用经由平台服务下载或取得的任何资料，其风险由用户自行负担，因该使用而导致用户电脑系统损坏或资料流失，用户应负完全责任。
9.5基于以下原因而造成的利润、信誉、资料损失或其他有形或无形损失，平台不承担任何直接、间接、附带、衍生或惩罚性的赔偿：
9.5.1平台服务使用或无法使用；
9.5.2经由平台服务取得的任何产品、资料或服务；
9.5.3用户资料遭到未授权的使用或修改；
9.5.4其他与平台服务相关的事宜。
9.6用户应妥善保管自己的账号和密码，加强密码安全性，谨防账号泄露或被盗。因用户账号被泄露或被盗而造成的任何损失，平台不承担补偿责任。用户因电信和网通部门的通讯线路故障、网络或电脑故障、系统不稳定、不可抗力（如服务器当机）等非平台原因造成账号、账号内资料等丢失、减少的，平台不承担补偿等责任。
9.7用户理解并同意自主选择免费下载和使用平台服务，风险自负，包括但不限于用户使用平台服务过程中的行为，以及因使用平台服务产生的一切后果。如因下载或使用平台服务而对计算机系统造成的损坏或数据的丢失等，用户须自行承担全部责任。
10.服务终止 
10.1您同意平台有权基于自行考虑，因任何理由，包括但不限于缺乏使用或平台认为您已经违反本协议的条款及精神，而终止您的账号或服务的全部或任何部分，并将您在平台服务内的任何内容进行移除并删除。
10.2您同意本协议任何规定提供之服务，无需进行任何事先通知即可终端或终止。您承认并同意，平台可立即关闭或删除您的账号及账号中所有相关信息及文件，及/或禁止继续使用前述文件或平台的服务。
10.3平台采取以上行为均不需要进行通知，并且对用户和任何第三人均不承担责任。
11.法律适用和管辖 
11.1平台对本协议拥有最终解释权。
11.2本协议的生效、履行、解释及争议的解决均适用中华人民共和国法律。本条款因与中华人民共和国现行法律相抵触而导致部分无效，不影响其他部分的效力。
11.3如就本协议内容或其执行发生任何争议，应尽量友好协商解决；协商不成时，则争议各方均一致同意将争议提交北京仲裁委员会进行仲裁，适用该会仲裁规则，且一裁终局。
`
export default agreement;
