import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import InfiniteScroll from 'react-infinite-scroll-component';
import classNames from 'classnames/bind';
import Swipeout from 'rc-swipeout';
// eslint-disable-next-line import/no-unresolved
import 'rc-swipeout/assets/index.css';
import VoiceAskStore from '../../stores/voiceAskStore';
import Loader from '../../components/loader';

import styles from './index.module.css';

const cx = classNames.bind(styles);

@observer
class Doctor extends Component {
  store: VoiceAskStore;

  constructor(props: Readonly<{}>) {
    super(props);
    this.store = new VoiceAskStore();
  }

  componentDidMount() {
    document.title = '语音问答';
  }

  render() {
    const {
      tabs,
      activeTab,
      handleClickTab,
      updatePage,
      block,
      showDialog,
      closeDialog,
      submitBlockReason,
      selectReason,
      reasons
    } = this.store;
    return (
      <div className={styles.voiceAsk}>
        {tabs && tabs.length > 0 && (
          <div className={styles.tabsScrollWrapper}>
            <div className={styles.tabsScroll}>
              <div className={styles.contentTabs}>
                {tabs.map((tab, index) => {
                  return (
                    <div
                      className={cx({
                        tabItem: true,
                        active: activeTab === index
                      })}
                      key={tab.type}
                      onClick={() => {
                        handleClickTab(index);
                      }}
                    >
                      <div className={styles.tabText}>{tab.text}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}

        <div className={styles.tabContentWrapper}>
          {tabs.map((tab, index) => {
            return (
              <div
                className={cx({
                  tabContent: true,
                  active: activeTab === index
                })}
                id={`voice-content-layout-${index}`}
                key={tab.type}
              >
                {tab.firstPageLoaded ? (
                  <div className={styles.tabContentInner}>
                    {tab.pages.length > 0 ? (
                      <div className={styles.listContainer}>
                        <InfiniteScroll
                          dataLength={tab.pages.length}
                          next={() => {
                            updatePage(index);
                          }}
                          scrollableTarget={`voice-content-layout-${index}`}
                          hasMore={tab.hasMore}
                          loader={
                            <div className={styles.pageLoader}>
                              <div className={styles.spinner} />
                            </div>
                          }
                          endMessage={<div className={styles.pageEnd}>到底了，没有更多内容</div>}
                        >
                          {tab.pages.map((page, pageIndex) => {
                            const pageKey = `${pageIndex}page`;
                            return (
                              <Fragment key={pageKey}>
                                {page.map((content, itemIndex) => {
                                  return (
                                    <div key={content.qid} className={styles.listItem}>
                                      <Swipeout
                                        style={{ backgroundColor: 'transparent' }}
                                        autoClose
                                        right={[
                                          {
                                            text: (
                                              <div
                                                className={styles.itemAction}
                                                onClick={() => {
                                                  block(pageIndex, itemIndex, content.qid);
                                                }}
                                              >
                                                屏蔽
                                              </div>
                                            ),
                                            onPress: () => console.log('more more')
                                          }
                                        ]}
                                        onOpen={() => console.log('open')}
                                        onClose={() => console.log('close')}
                                      >
                                        <div className={styles.itemTextWrapper}>
                                          <a
                                            className={styles.itemText}
                                            href={`/voice-recorder/${content.qid}?from=${
                                              tab.text
                                            }&pn=${pageIndex +
                                              1}&position=${itemIndex}&page_size=${10}`}
                                          >
                                            {content.title}
                                          </a>
                                        </div>
                                      </Swipeout>
                                    </div>
                                  );
                                })}
                              </Fragment>
                            );
                          })}
                        </InfiniteScroll>
                      </div>
                    ) : (
                      <div className={styles.noResult}>
                        <div className={styles.noResultPic} />
                        <div className={styles.noResultHint}>啊哦，我还没有找到你想要的内容</div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className={styles.tabContentLoader}>
                    <Loader />
                  </div>
                )}
              </div>
            );
          })}
        </div>
        {showDialog && (
          <div className={styles.correctionLayer}>
            <div className={styles.correctionMask} onClick={closeDialog} />
            <div className={styles.correctionContainer}>
              <div className={styles.correctionForm}>
                <div className={styles.correctionFormHead}>
                  <div className={cx('correctionFormBtn', 'close')} onClick={closeDialog}>
                    关闭
                  </div>
                  <div className={styles.correctionFormTitle}>屏蔽原因（多选）</div>
                  <div className={cx('correctionFormBtn', 'submit')} onClick={submitBlockReason}>
                    提交
                  </div>
                </div>
                <div className={styles.correctionFormBody}>
                  <div className={styles.reasons}>
                    {reasons.map((reason, index) => {
                      return (
                        <div className={styles.reasonItem} key={`${reason.text}_reason`}>
                          <div
                            className={cx({
                              checkbox: true,
                              checked: reason.checked
                            })}
                            onClick={() => {
                              selectReason(index);
                            }}
                          />
                          <span className={styles.reasonText}>{reason.text}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className={styles.correctionFormFooter}>
                  *您的反馈将协助我们更好的为公众提供准确信息
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Doctor;
