import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps, Link } from 'react-router-dom';
import message from '@/components/message';
import VideoStore from '../../stores/videoStore';
import VideoBg from '../../static/images/doctor-bg.png';
import VideoDel from '../../static/images/del.png';
import VideoAdd from '../../static/images/add.png';
import VideoPlay from '../../static/images/play.png';

import styles from './index.module.css';

interface State {
  uplaodState: number;
}
interface RouteParams {
  id: string;
}

@observer
class Video extends Component<RouteComponentProps<RouteParams>, State> {
  store: VideoStore;

  constructor(props) {
    super(props);
    const { id = '' } = props.match.params;
    this.store = new VideoStore(id);
    // this.state = {
    //   uplaodState: 1 // 0待上传 1上传中 2上传完成
    // };
  }

  UNSAFE_componentWillMount(){
    //window.TEA('主页', 'upload_video');
  }

  componentDidMount() {
    document.title = '上传视频';
    //window.TEA('主页', 'upload_video');
  }

  imageHandler = () => {
    const input = document.createElement('input');
    input.setAttribute('style', 'display:none');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'video/*');
    document.body.appendChild(input);
    input.click();
    input.addEventListener('change', () => {
      if (!input.files) {
        return;
      }
      const file = input.files[0];
      // console.log(file);
      // let ext = '';
      // if (file.type) {
      //   ext = `.${file.type.split('/')[1]}`
      // }
      console.log('上传视频大小', file.size);
      if (file.size > 100 * 1024 * 1024) {
        message.warning('视频文件大小不可超过100M');
        return
      } 
      const formData = new FormData();
      formData.append('file', file);
      this.store.upload(formData, '.mp4'); // 指定转码的视频封装格式，统一封装为mp4
    });
  };

  render() {
    const {
      videoInfo,
      uplaodState,
      title,
      delVideo,
      changeTitle,
      addVideo,
      addAudit,
      playVideo
    } = this.store;
    console.log(videoInfo)
    return (
      <div>
        <div>
          <div className={styles.video_head}>
            <div className={styles.video_title}>
              视频标题<span style={{ color: 'red' }}>*</span>
            </div>
            <div className={styles.video_subtitle}>最多输入20字</div>
          </div>
          <div className={styles.video_title_input}>
            <textarea
              value={title}
              onChange={(e) => {
                changeTitle(e.target.value);
              }}
              placeholder="请输入标题内容"
            />
          </div>
        </div>
        <div className={styles.video_action}>
          <div className={styles.video_head}>
            <div className={styles.video_title}>
              上传视频<span style={{ color: 'red' }}>*</span>
            </div>
            <div />
          </div>
          <div
            className={styles.video_box}
            onClick={() => {
              if (uplaodState === 0) {
                // 视频上传
                this.imageHandler();
              }
            }}
          >
            {uplaodState === 0 && <img className={styles.video_add} src={VideoAdd} alt="添加" />}
            {uplaodState === 1 && <img className={styles.video_add} src={VideoAdd} alt="添加中" />}
            {uplaodState === 1 && (
              <div className={styles.progress_bar} style={{ width: `${this.store.uploadLen}%` }} />
            )}

            {uplaodState === 2 && (
              <video
                poster={
                  videoInfo && videoInfo.CoverUrl
                    ? videoInfo.CoverUrl
                    : VideoBg
                }
                controls
                src={videoInfo ? videoInfo.BackupPlayUrl : undefined}
                style={{ height: '100%', width: '100%' }}
              />
            )}

            {uplaodState === 2 && (
              <div
                onClick={() => {
                  delVideo();
                }}
              >
                <img className={styles.video_del} src={VideoDel} alt="删除" />
              </div>
            )}
          </div>
          <div className={styles.tips_box}>
            <span className={styles.video_tips}>*上传视频只支持mov、mp4格式</span>
            <span className={styles.video_tips}>*视频文件大小不超过100M</span>
          </div>
        </div>

        <div className={styles.video_btm}>
          <div
            className={styles.video_btn}
            onClick={() => {
              //window.TEA('上传视频', 'save_draft');
              addVideo();
            }}
          >
            存草稿
          </div>
          <div
            className={styles.video_btn}
            onClick={() => {
              //window.TEA('上传视频', 'submit_for_review');
              addAudit();
            }}
          >
            提交审核
          </div>
        </div>
      </div>
    );
  }
}

export default Video;
