import React, { Component, useCallback } from 'react';
import { observer } from 'mobx-react';
import 'intersection-observer';
import classnames from 'classnames/bind';
import ContentManageStore from '@/stores/contentManageStore';
import { isLSG } from '@/config/conf';
import Checkbox from '@/components/checkbox';
import message from '@/components/message';
import ContentPage from './components/page';
import styles from './manage.module.css';
import initWechatConfig from "@/utils/wxShare";

const cx = classnames.bind(styles);

interface TabProps {
  active: boolean;
  text: string;
  index: number;
  onClick(arg0: number): void;
}
const Tab: React.FC<TabProps> = ({ text, index, active, onClick }) => {
  const tabCls = cx({
    tab: true,
    activeTab: active
  });
  const clickHandler = useCallback(() => {
    onClick(index);
  }, []);
  return (
    <div className={tabCls} onClick={clickHandler}>
      {text}
    </div>
  );
};

@observer
class ContentManage extends Component {
  store: ContentManageStore;

  private loadMoreRef: React.RefObject<HTMLDivElement>;

  private pageContainerRef: React.RefObject<HTMLDivElement>;

  loadMoreObserver: IntersectionObserver;

  constructor(props: Readonly<{}>) {
    super(props);
    this.store = new ContentManageStore();
    this.loadMoreRef = React.createRef();
    this.pageContainerRef = React.createRef();
    this.loadMoreObserver = new IntersectionObserver(this.handleLoadMoreObserver, {
      root: this.pageContainerRef.current,
      rootMargin: '10px',
      threshold: 0.01
    });
  }

  componentDidMount() {
    document.title = '作品管理';
    try {
      initWechatConfig();
    } catch (error) {
      console.error('初始化微信配置错误', error);
    }
    if (this.loadMoreRef.current) {
      this.loadMoreObserver.observe(this.loadMoreRef.current);
    }
  }

  componentWillUnmount() {
    message.destroy();
  }

  handleLoadMoreObserver: IntersectionObserverCallback = (entities) => {
    const {
      boundingClientRect: { top }
    } = entities[0];
    console.log(this.store.prevY, top, this.store.hasMore);
    if (top > 0 && this.store.hasMore) {
      if (this.store.prevY > top || this.store.prevY === 0) {
        this.store.updatePage();
      }
    }
    this.store.prevY = top;
  };

  upload = () => {
    const input = document.createElement('input');
    const { uploadFile } = this.store;
    input.setAttribute('style', 'display:none');
    input.setAttribute('type', 'file');
    document.body.appendChild(input);
    input.click();

    input.addEventListener('change', () => {
      if (!input.files) {
        return;
      }
      const file = input.files[0];
      console.log(file);
      const formData = new FormData();

      formData.append('file', file);
      message.loading('上传中', 0);
      uploadFile(formData)
        .then((res) => {
          if (res) {
            input.remove();
            message.success('上传成功').then(
              () => {
                window.location.href = '/content/manage';
              },
              () => {}
            );
          }
        })
        .catch(() => {
          message.info('上传失败');
          input.remove();
        });
    });
  };

  handleButtonClick = (action: string) => {
    const { allSelectedItemsId, submit, init, activeNav } = this.store;
    const count = allSelectedItemsId.length;
    let messageText = '提交审核成功，审核通过后，可在「已发布」列表中查看';
    if (action === 'block') {
      messageText = '屏蔽成功，此内容线上不可见，可在「已屏蔽」列表中查看';
    } else if (action === 'cancelblock') {
      messageText = '已取消屏蔽，可在「存草稿」列表中查看';
    }
    if (!count) {
      message.info('请勾选内容后操作');
    } else {
      submit(action, allSelectedItemsId)
        .then((res) => {
          if (res && res.data.code === 0) {
            message.success(messageText);
            init(activeNav);
          } else {
            message.info('服务异常，请稍后重试');
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    console.log(allSelectedItemsId, action);
  };

  render() {
    const pageCls = cx('manage', 'page');
    const {
      navs,
      firstPageLoaded,
      activeNav,
      pagesData,
      updating,
      selectAll,
      showFilter,
      filter,
      filterOpen,
      filterList,
      filterCurrentText,
      resultCount,
      hasMore,
      actionButtons
    } = this.store;
    console.log(selectAll);
    const loadMoreCls = cx({
      loadMore: true,
      showLoadMore: firstPageLoaded && pagesData.length > 0
    });
    const isAllSelected = true;
    const topBarCls = {
      selectAll: cx({
        selectAll: true,
        selected: selectAll
      }),
      checkbox: cx({
        checkbox: true,
        checked: isAllSelected,
        disabled: true
      })
    };
    const filterGroupCls = {
      state: cx({
        filter: true,
        open: filterOpen === 'state'
      }),
      site: cx({
        filter: true,
        open: filterOpen === 'site'
      }),
      contentType: cx({
        filter: true,
        open: filterOpen === 'contentType'
      })
    };
    const filterContainerCls = cx(
      {
        filterContainerShow: !!filterOpen
      },
      {
        filterContainer: true
      }
    );
    let loadMoreText = hasMore ? '上滑加载更多' : '到底了，没有更多内容';
    if (updating) {
      loadMoreText = '加载中...';
    }
    let headText = '';
    if (firstPageLoaded) {
      if (isLSG) {
        headText =
          resultCount > 0
            ? '以下是您的全部内容，可进行查看、编辑、发布等操作。'
            : '未能获取到符合条件的内容';
      } else {
        headText =
          resultCount > 0
            ? `获取您在全网的${resultCount}条新内容，可查看、编辑、发布等操作`
            : '未能获取到符合条件的内容';
      }
    }
    return (
      <div className={pageCls}>
        <div className={styles.head}>{headText}</div>
        <div className={styles.tabsContainer}>
          {/* <div className={styles.tabs}>
            {navs.map((item, index) => {
              return (
                <Tab
                  key={item.type}
                  onClick={() => {
                    this.store.switchNav(index);
                  }}
                  index={index}
                  text={item.text}
                  active={index === activeNav}
                />
              );
            })}
          </div> */}
          <div className={styles.tabNavs}>
            {navs.map((item, index) => {
              return (
                <div
                  key={item.type}
                  className={activeNav === index ? styles.tabNavs_cur : styles.tabNavs_item}
                  onClick={() => {
                    //window.TEA('作品管理', 'navigation_bar');
                    this.store.switchNav(index);
                  }}
                >
                  <span>{item.text}</span>
                  <div className="cur_line" />
                </div>
              );
            })}
          </div>
        </div>
        <div className={styles.layout}>
          <div className={filterContainerCls}>
            <div className={styles.filterInfo}>点击筛选内容</div>
            <div className={styles.filterContent}>
              {filterOpen &&
                filterList &&
                filterList.length > 0 &&
                filterList.map((listItem, itemIndex) => {
                  const listItemCls = cx({
                    filterContentItem: true,
                    itemSelected: listItem.type === filter[filterOpen].current
                  });
                  return (
                    <div
                      className={
                        listItem.type === 'OwnResource'
                          ? `${listItemCls} ${styles.mybtn}`
                          : listItemCls
                      }
                      onClick={() => {
                        this.store.handleClickFilterItem(itemIndex);
                      }}
                      key={listItem.type}
                    >
                      <span className={styles.filterContentItemText}>{listItem.text}</span>
                    </div>
                  );
                })}
            </div>
            <div className={styles.filterMask} onClick={this.store.handleFilterMaskClick} />
          </div>
          <div className={styles.topBar}>
            {/* <div className={topBarCls.selectAll}>
              <Checkbox
                disabled={activeNav >= 2}
                value="selectAll"
                checked={selectAll}
                onChange={this.store.handleSelectAll}
              >
                <span className={styles.selectAllText}>全选</span>
              </Checkbox>
            </div> */}
            {/* {activeNav === 0 && (
              <div
                className={filterGroupCls.state}
                onClick={() => {
                  showFilter('state');
                }}
              >
                {filterCurrentText.state}
              </div>
            )} */}
            {/* <div
              className={filterGroupCls.site}
              onClick={() => {
                //window.TEA('作品管理', 'source_of_works');
                showFilter('site');
              }}
            >
              {filterCurrentText.site}
            </div> */}
            <div
              className={filterGroupCls.contentType}
              onClick={() => {
                //window.TEA('作品管理', 'types_of_works');
                showFilter('contentType');
              }}
            >
              {filterCurrentText.contentType}
            </div>
            <div style={{ height: '40px', width: '20%' }} />
          </div>
          <div className={styles.body} ref={this.pageContainerRef}>
            {!firstPageLoaded && <div className={styles.pageLoading}>加载中...</div>}
            {firstPageLoaded && pagesData.length === 0 && (
              <div className={styles.pageLoading}>无内容</div>
            )}
            {firstPageLoaded &&
              pagesData.length > 0 &&
              pagesData.map((page, pageIndex) => {
                const keyString = `${pageIndex}page`;
                return (
                  <ContentPage
                    key={keyString}
                    pageIndex={pageIndex}
                    pageData={page.list}
                    selectedRows={page.selectedKeys}
                    onSelectChange={this.store.handleSelectChange}
                  />
                );
              })}
            <div ref={this.loadMoreRef} className={loadMoreCls}>
              {loadMoreText}
            </div>
          </div>
        </div>
        <div className={styles.actionGroup}>
          {/* {actionButtons &&
            actionButtons.map((button) => {
              return (
                <div
                  key={button.action}
                  className={styles.actionButton}
                  onClick={() => {
                    if (button.action === 'upload') {
                      this.upload();
                    } else {
                      this.handleButtonClick(button.action);
                    }
                  }}
                >
                  {button.text}
                </div>
              );
            })} */}
          {/* <span>上传</span> */}
        </div>
      </div>
    );
  }
}

export default ContentManage;
