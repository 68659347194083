import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps, Link } from 'react-router-dom';
import VideoBg from '../../static/images/doctor-bg.png';
import VideoDel from '../../static/images/del.png';
import VideoAdd from '../../static/images/add.png';
import VideoPlay from '../../static/images/play.png';
import DefaultAvatar from '../../static/images/doctor/avatar.png';

import styles from './detial.module.css';

interface State {
  uplaodState: number;
}
interface RouteParams {
  id: string;
}

@observer
class Detial extends Component<RouteComponentProps<RouteParams>, State> {
  //   constructor(props) {
  //     super(props);
  //   }

  componentDidMount() {
    document.title = '新建视频';
  }

  render() {
    return (
      <div className={styles.main}>
        <div className={styles.article_title}>
          <span>乳腺癌康复期吃什么？</span>
        </div>
        <div className={styles.head}>
          <img className={styles.avatar} src={DefaultAvatar} alt="avatar" />
          <div className={styles.doctor_info}>
            <span className={styles.doctor_name}>陈小风 副主任医师</span>
            <div className={styles.hosp_name}>
              <span>内分泌科</span>
              <span>|</span>
              <span>首都医科大学北京第一肿瘤医院</span>
            </div>
          </div>
        </div>

        <div className={styles.video_box}>
          <img src={VideoPlay} className={styles.play} alt="bg" />
        </div>
        <div className={styles.summary}>
          <div className={styles.summary_title}>
            <span>视频文字摘要</span>
          </div>
          <div className={styles.summary_content}>
            <p>
              最后我还是留在了原来的医院由庄志刚主任制定了术前tac*6新辅助化疗+手术（淋巴全清）+33次放疗。穿刺确诊后，做了一系列的全身b超检查，胸部ct，头颅磁共振，骨扫描，最后因。
            </p>
            <p>
              最后我还是留在了原来的医院由庄志刚主任制定了术前tac*6新辅助化疗+手术（淋巴全清）+33次放疗。穿刺确诊后，做了一系列的全身b超检查，胸部ct，头颅磁共振，骨扫描，最后因。
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Detial;
