import { isLSG } from '@/config/conf';

const xhagreement = `
【健康报社有限公司】和与您签约合作的医生集团公司（以下称“我们”或公司）及其关联方非常重视用户信息的保护。您在使用我们的小程序时，我们可能会收集和使用您的相关信息。我们已经在向您提供了《隐私政策》以向您展示我们收集、使用、存储、保护您的个人信息的总体规则，我们希望通过本声明向您说明在当前环节适用于具体的第三方互联网医院问诊或咨询场景时，我们如何收集、使用、共享、保护您的个人信息，以进一步保障您的个人信息和为您提供更具个性化的优质服务。在使用本信息服务平台入驻的第三方的问诊和咨询服务时（以下简称“服务”），并在相应页面填写个人信息前，请您务必仔细阅读本声明。您一旦主动在相应页面填写您的个人信息并进行提交操作，即意味着您同意我们按照本声明收集、使用、共享您的相关信息。

数据接收方——第三方互联网医院信息：
小荷医生App运营方【幺零贰四（海南）互联网医院有限公司】

个人信息的使用和共享

1、您同意，我们可以通过以下方式处理您的个人信息，并共享给医生专用的小荷医生App和【幺零贰四（海南）互联网医院有限公司】，以满足您在小荷医生App中进行服务主体认证服务：

若您将使用相关信息，在小荷医生完成服务主体认证，以便向患者提供健康咨询或医疗问诊服务，您授权健康中国向小荷医生共享您的以下信息：

a. 当您希望向患者提供医疗健康咨询服务，您需要通过注册申请，成为入驻小荷医生平台的医生。为了申请入驻小荷医生平台，你需要提供以下基本申请信息：手机号、真实姓名。

b.您需要通过实名认证以及医生职业资质验证方可提供咨询服务。为了验证您的医生职业资质，您需要向我们提供您的以下真实信息：身份证人像面照片、身份证国徽面照片、医生职称或教学职称、执业医院及科室、专业方向或擅长疾病领域等。

c. 除了用于验证您的真实身份以及医生职业资质外，您的个人头像、个人简介、真实姓名、执业医院以及科室、职称、专业方向或擅长疾病领域将在小荷App、小荷医生平台公开展示并在咨询过程中展现。

d. 为帮助其他患者了解相关医疗健康信息，您在提供咨询服务时和患者的交流记录，将有可能被作为优质内容在小荷App、小荷医生等关联渠道向其他用户展示，也可能用于内容安全和医院运营管理的目的，为了满足患者的知情权等其他合法权利，您的个人头像、个人简介、真实姓名、医生职称、执业医院以及科室、专业方向或擅长疾病领域等也将一并展示。

2、我们将严格保护您的个人信息安全。我们将采用适当制度、安全技术和程序等措施来保护您的个人信息不被未经授权的访问、使用或泄漏。我们亦将促使本声明所述各接收和使用方尽力保护和不予泄露您的个人信息。

3、第三方互联网医院对您个人信息的使用和保护：您理解，第三方互联网医院控制您共享数据后的数据处理方式和安全保护，您应当仔细阅读【幺零贰四（海南）互联网医院有限公司】和小荷医生的<a style="color: blue;" href="https://docs.lvsongguo.com/lotus-doctor/privacy-protocol.html">隐私政策</a>，如果您不同意相关隐私政策，您应当拒绝相关数据共享。

4、我们将要求并敦促个人信息接收方遵循本声明和法律法规的规定，谨慎使用其接收到的个人信息，如其发生超越本声明范围使用您的个人信息，进而侵害您合法权益的情形，我们将协助您追究该接收方的法律责任。

5、本声明未做特别说明的，我们将仍然遵从《隐私政策》中关于数据收集、处理、共享的原则，您可以点击链接阅读我们的隐私政策。

`; // isLSG ? lsgAgreement : jkbAgreement;
export default xhagreement;
