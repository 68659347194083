interface Location {
    host: string,
    hostname: string,
}
const { location: Location = {} } = window;
const { hostname = '', port } = location || {};
const devHost = ['localhost']; // 开发环境域名
class Util {
    isProd: boolean = hostname === 'd-card.jkzg2030.cn'
    isTest = hostname === 'd-test-card.jkzg2030.cn'
    isDev = devHost.includes(hostname)
    // needWXCheckEnv: boolean = this.isProd && !port; // TODO 上线后需要开启 是否开启微信网页验证
    needWXCheckEnv: boolean = false; // TODO 是否开启微信网页验证

    envKey = '' // 环境标识key

    isIOS = () => {
      const u = navigator.userAgent;
      return u.indexOf("iPhone") > -1 || u.indexOf("Mac OS") > -1;
    }

    constructor() {
        this.initEnvKey();
    }

    private initEnvKey() {
        if (this.isDev) {
            this.envKey = 'dev';
            return;
        }
        if (this.isTest) {
            this.envKey = 'test';
            return;
        }
        if (this.isProd) {
            this.envKey = 'prod';
            return;
        }
    }
    // 防抖函数，执行一次，优先执行
    // eg: debouncePrev(handleSubmit, 1500); // 间隔1.5s，连续触发1.5s内只执行一次，切立即执行
    debouncePrev(fn, wait) {
        let timer;
        return (...args) => {
          if (timer) {
            return;
          }
          fn(...args);
          timer = setTimeout(() => {
            clearTimeout(timer);
            timer = null;
          }, wait);
        };
      }
}

export default new Util();
