import { observable, action, toJS, computed } from 'mobx';
import { isEmpty } from 'lodash';
import ContentsService, { Result, ItemData, NewContent } from '../services/contentsService';

export interface PageData {
  list: ItemData[];
  selectedKeys: number[];
}

// const siteList = [
//   'all',
//   '百科名医网',
//   '有来医生',
//   '博禾医生',
//   '健康中国',
//   '民福康',
//   '深读视频',
//   '松果健康',
//   '医学微视',
//   '39健康',
//   '120ask',
//   '好大夫',
//   'sohu公众号',
//   '3uol',
//   '妙手医生',
//   'OwnResource'
// ];

// 新的列表

const siteList = [
  'all',
  'OwnResource',
  '健康中国',
  '松果健康',
  '百科名医网',
  '有来医生',
  '博禾医生',
  '民福康',
  '深读视频',
  '医学微视',
  '39健康',
  '120ask',
  '好大夫',
  'sohu公众号',
  '3uol',
  '妙手医生'
];

const originalFilter = {
  state: {
    open: false,
    current: 'all',
    list: [
      {
        type: 'all',
        text: '全部'
      },
      {
        type: 'underreview',
        text: '审核中'
      },
      {
        type: 'failed',
        text: '未通过'
      }
    ]
  },
  site: {
    open: false,
    current: 'all',
    list: siteList.map((item) => {
      const temp = {
        type: encodeURIComponent(item),
        text: item === 'all' ? '全部' : item
      };
      if (temp.type === 'OwnResource') {
        temp.text = '自提';
      }
      return temp;
    })
  }, // 站点来源筛选
  contentType: {
    open: false,
    current: 0,
    list: [
      {
        type: 0,
        text: '全部'
      },
      {
        type: 1,
        text: '图文'
      },
      // {
      //   type: 'ask',
      //   text: '文字问答'
      // },
      {
        type: 2,
        text: '视频'
      },
      {
        type: 3,
        text: '医生笔记'
      }
      // {
      //   type: 'sc_topic',
      //   text: '科普议题'
      // }
      // {
      //   type: 'voice',
      //   text: '语音问答'
      // }
      // },
      // {
      //   type: 'file',
      //   text: '文件'
      // }
    ]
  } // 内容形式筛选
};

class ContentManageStore {
  service: ContentsService;

  @observable
  activeNav = 0;

  @observable
  prevY = 0;

  @observable
  selectAll = false;

  @observable
  resultCount = 0;

  @observable
  filter;

  @observable
  filterOpen: null | string = null;

  @observable
  pagesData: PageData[] = [];

  @observable
  dialog = {
    open: false,
    title: '提示',
    message: '',
    buttons: {
      cacel: '取消',
      confirm: '确认'
    }
  };

  @observable
  firstPageLoaded = false; // 整个nav数据第一次加载完成

  @observable
  updating = false; // 上滑加载触发，更新pages

  @observable
  hasMore = true;

  // 作品列表 state 枚举  1=>草稿，2=>审核失败，3=>审核中，4=>已发布，5=>屏蔽
  navs = [
    {
      type: 0,
      text: '全部',
      value: 0
    },
    {
      type: 1,
      text: '草稿箱',
      value: 1
    },
    {
      type: 2,
      text: '审核中',
      value: 3
    },
    {
      type: 3,
      text: '已发布',
      value: 4
    },
    {
      type: 4,
      text: '未通过',
      value: 2
    }
  ];

  constructor() {
    this.service = new ContentsService();
    this.filter = originalFilter;
    this.init();
  }

  @computed
  get filterList() {
    if (!this.filterOpen) {
      return undefined;
    }
    const filter = this.filter[this.filterOpen];
    return filter.list;
  }

  @computed
  get filterCurrentText() {
    const { state, site, contentType } = this.filter;
    const currentText = {
      state: '状态',
      //site: '作品来源',
      contentType: '作品类型'
    };
    if (state.current !== 'all') {
      currentText.state = state.list.find((item) => item.type === state.current).text;
    }
    // if (site.current !== 'all') {
    //   currentText.site = site.list.find((item) => item.type === site.current).text;
    // }
    if (contentType.current !== 'all') {
      currentText.contentType = contentType.list.find(
        (item) => item.type === contentType.current
      ).text;
    }
    return currentText;
  }

  @computed
  get allSelectedItemsId() {
    const idList: string[] = [];
    this.pagesData.forEach((page) => {
      const { selectedKeys, list } = page;
      selectedKeys.forEach((key) => {
        const { doc_id } = list[key];
        idList.push(doc_id);
      });
    });
    return idList;
  }

  @action
  init = (navIndex = 0) => {
    this.firstPageLoaded = false;
    this.prevY = 0;
    this.pagesData = [];
    this.hasMore = true;
    const { type } = this.navs[navIndex]; // 状态
    console.log('inittype', type);
    const { state, site, contentType } = this.filter;
    const stateFilter = type; // state.current;
    const from_site = decodeURIComponent(site.current);
    const cate = contentType.current; // 状态
    this.service
      .getContents(1, cate, type)
      .then((res) => {
        this.selectAll = false;
        this.loadFirstPage(res);
      })
      .catch((err) => {
        const { message } = err;
        if (message === 'login fail') {
          window.location.href = '/login';
        }
        console.log(message);
        this.firstPageLoaded = true;
      });
  };

  @action
  loadFirstPage = (resultData: any) => {
    const currentNavType = this.navs[this.activeNav].type;
    // if (resultData.type !== currentNavType) {
    //   return;
    // }
    this.resultCount = resultData.data.doc_count;
    if (resultData.data.doc_list && resultData.data.doc_list.length > 0) {
      const firstPageData: PageData = {
        list: resultData.data.doc_list,
        selectedKeys: []
      };

      this.pagesData.push(firstPageData);
    }
    console.log(this.pagesData);
    this.firstPageLoaded = true;
  };

  @action
  loadPage = (resultData: Result) => {
    console.log(resultData);
    const currentNavType = this.navs[this.activeNav].type;
    // if (resultData.type !== currentNavType) {
    //   return;
    // }
    if (isEmpty(resultData.data.doc_list)) {
      this.hasMore = false;
      return;
    }
    const { doc_list } = resultData.data;
    const selectedKeys: number[] = [];
    if (this.selectAll) {
      doc_list.forEach((listItem, index) => {
        if (listItem.state !== 2 && listItem.state !== 3) {
          selectedKeys.push(index);
        }
      });
    }
    const pageData: PageData = {
      list: doc_list,
      selectedKeys
    };
    console.log(resultData);
    this.pagesData.push(pageData);
  };

  @action
  updatePage = () => {
    const currentPageIndex = this.pagesData.length;
    this.updating = true;
    const { type } = this.navs[this.activeNav];
    const { state, site, contentType } = this.filter;
    const stateFilter = type; // state.current;
    const from_site = site.current;
    const cate = contentType.current;
    console.log(1);
    this.service
      .getContents(currentPageIndex + 1, cate, type)
      .then((res) => {
        console.log(2);
        this.loadPage(res);
        this.updating = false;
      })
      .catch((err) => {
        const { message } = err;
        console.log(message);
        if (message === 'nomore') {
          this.hasMore = false;
        }
        this.updating = false;
      });
  };

  @action
  showFilter = (type) => {
    if (!this.firstPageLoaded) {
      return;
    }
    console.log(type, this.filter, this.filterOpen);
    if (this.filterOpen === type) {
      this.filterOpen = null;
    } else {
      this.filterOpen = type;
    }
  };

  @action
  handleFilterMaskClick = () => {
    this.filterOpen = null;
  };

  @action
  handleClickFilterItem = (index) => {
    if (this.filterOpen) {
      this.filter[this.filterOpen].current = this.filter[this.filterOpen].list[index].type;
      console.log('current filter', this.filter[this.filterOpen].current);
    }
    this.filterOpen = null;
    this.init(this.activeNav);
  };

  @action
  reset = () => {
    this.selectAll = false;
    this.filter = originalFilter;
    this.pagesData = [];
    this.firstPageLoaded = false;
    this.updating = false;
    this.hasMore = true;
  };

  @action
  switchNav = (index: number) => {
    // 切换nav，数据清空， 新发起请求
    this.activeNav = index;
    this.reset();
    this.init(index);
  };

  @computed
  get actionButtons() {
    // 品列表 state 枚举 0,  1=>草稿，2=>审核失败，3=>审核中，4=>已发布，5=>屏蔽
    const allButtons = [[], [], []];
    return allButtons[this.activeNav];
  }

  @action
  handleSelectChange = (itemIndex: number, checked: boolean, pageIndex: number) => {
    const oldItems = toJS(this.pagesData[pageIndex].selectedKeys);
    if (oldItems.includes(itemIndex)) {
      if (!checked) {
        this.pagesData[pageIndex].selectedKeys = oldItems.filter(
          (item: number) => item !== itemIndex
        );
      }
    } else if (checked) {
      this.pagesData[pageIndex].selectedKeys.push(itemIndex);
    }
    console.log(toJS(this.pagesData));
  };

  @action
  handleSelectAll = (e) => {
    const { checked } = e.target;
    this.pagesData.forEach((page, pageIndex) => {
      const selectedKeys: number[] = [];
      if (checked) {
        page.list.forEach((listItem, index) => {
          if (listItem.state !== 2 && listItem.state !== 3) {
            selectedKeys.push(index);
          }
        });
      }

      this.pagesData[pageIndex].selectedKeys = selectedKeys;
    });
    this.selectAll = checked;
  };

  @action
  uploadFile = (formData: FormData) => {
    console.log(formData);
    return new Promise((resolve, reject) => {
      this.service
        .uploadFile(formData)
        .then((res) => {
          console.log('upload result', res);
          if (res.data && res.data.code === 0 && res.data?.data?.data && res.data?.data?.data[0]) {
            const file = res.data?.data?.data[0];
            const newFileContent: NewContent = {
              cate: 'file',
              file,
              title: file.name,
              content: ''
            };
            this.service.createContent(newFileContent).then((createRes) => {
              if (createRes && createRes.data.code === 0 && createRes.data?.data?.data) {
                const { doc_id } = res.data.data.data;
                resolve(doc_id);
              } else {
                reject(new Error('fail1'));
              }
            });
            resolve(res.data?.data?.data[0]);
          } else {
            reject(new Error('fail'));
          }
          console.log(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  @action
  submit = (actionType: string, idList: string[]) => {
    return this.service.changeContentsStatus(idList, actionType, '');
  };
}

export default ContentManageStore;
