import { observable, action } from 'mobx';
import DoctorInfoService from '../services/doctorInfoService';
import Doctor from '../types/doctor';

class McnStore {
  service: DoctorInfoService;

  @observable
  public isMcn: string | null = localStorage.getItem("isMcn");


  constructor(service: DoctorInfoService) {
    this.service = service;
  }

  @action
  addMcn(): void{
    this.service
      .addMcn()
      .then(res => {
        if(res && res.data === "ok"){
          console.log(res);
          this.isMcn = "1";
          localStorage.setItem("isMcn", this.isMcn)
        }else{

        }
      }).catch(() => {

    })
  }

}

export default McnStore
