import React from 'react';
import styles from './index.module.css';

const Loader = () => (
  <div className={styles.loaderLayout}>
    <div className={styles.loaderBox}>
      <div className={styles.spinnerbBox}>
        <svg className={styles.circular} height="65px" width="65px" viewBox="0 0 66 66">
          <circle
            className={styles.path}
            cx="33"
            cy="33"
            r="30"
            fill="none"
            strokeWidth="2"
            strokeLinecap="round"
            strokeMiterlimit="10"
          />
        </svg>
      </div>
    </div>
  </div>
);
export default Loader;
