import React, {Component} from 'react';
import {observer} from 'mobx-react';
import { Modal, PickerView } from 'antd-mobile';
import {RouteComponentProps} from "react-router-dom";

import message from '../../components/message';
import style from "./commit.module.css"
import ActivityStore from "@/stores/activityStore";
import ActivityService from "@/services/activityService";

@observer
class CommitActivity extends React.Component<RouteComponentProps<any>, {modalVisible: boolean, timeValue: Array<number>, timeStore: any}> {
  store: ActivityStore

  service: ActivityService

  timeData: any

  currSelect: number

  paramStore: any

  constructor(props) {
    super(props);
    this.paramStore = this.props.location.state;
    this.timeData = this.getTimeData();
    this.service = new ActivityService();
    this.store = new ActivityStore(this.service);
    this.currSelect = 0; //当前选择的活动 0，权威直播  1，在线义诊
    this.state = {
      modalVisible: false,
      timeValue: [], //初始timePicker值
      timeStore: {
        live: [],
        online: [],
      }
    }
  }

  domEvents = {
    uploadAvatar : (e: any) => {
      const { target } = e;
      if (target && target.files) {
        const formData = new FormData();
        formData.append('file', target.files[0]);
        this.store.upload(formData);
      }
    },
    closeModal: () => {
      this.setState({
        modalVisible: false
      })
    },
    showModal: (activityCate) => {
      this.currSelect = activityCate;
      this.setState({
        modalVisible: true,
        timeValue: activityCate === 0 ? [...this.state.timeStore.live] : [...this.state.timeStore.online]
      })
    },
    onTimeChange: (value) => {
      this.setState({
        timeValue: [...value]
      })
    },
    modalConfirm: () => {
      const {
        timeValue
      } = this.state;
      if(timeValue[0] >= timeValue[1]){
        message.error("起始时间不得晚于结束时间！");
        return
      }
      let params = {};
      if(this.currSelect === 0){
        params = {
          live: [...this.state.timeValue],
          online: [...this.state.timeStore.online],
        }
      } else {
        params = {
          live: [...this.state.timeStore.live],
          online: [...this.state.timeValue],
        }
      }
      this.setState({
        modalVisible: false,
        timeStore: params,
      })
    },
    //点击确认
    confirm: async () => {
      if(!this.store.uploadImgUrl){
        message.info("请上传全身职业照");
        return
      }
      const activity= [];
      const {
        uploadImgUrl,
        participateActivity
      } = this.store;
      const {
        startTime,
        liveId,
        onlineId,
        scienceId,
        activity_id,
        title
      } = this.paramStore;
      if(liveId){
        if(!this.state.timeStore.live.length){
          message.info("请选择参加权威直播的活动时间");
          return;
        }
        let param = {
          type: 0,
          activity_id,
          activity_content_id: liveId,
          start_time: Math.floor(new Date(startTime[0], startTime[1], startTime[2], this.state.timeStore.live[0]).getTime() / 1000),
          end_time: Math.floor(new Date(startTime[0], startTime[1], startTime[2], this.state.timeStore.live[1]).getTime() / 1000),
          career_photo: uploadImgUrl
        }
        // @ts-ignore
        activity.push(param);
      }
      if(onlineId){
        if(!this.state.timeStore.online.length){
          message.info("请选择参加在线义诊的活动时间");
          return;
        }
        let param = {
          type: 1,
          activity_id,
          activity_content_id: onlineId,
          start_time: Math.floor(new Date(startTime[0], startTime[1], startTime[2], this.state.timeStore.online[0]).getTime() / 1000),
          end_time: Math.floor(new Date(startTime[0], startTime[1], startTime[2], this.state.timeStore.online[1]).getTime() / 1000),
          career_photo: uploadImgUrl
        }
        // @ts-ignore
        activity.push(param);
      }
      if(scienceId){
        //跳转至科普作品页
        this.props.history.push({
          pathname: "/content/editor/jkb_article",
          state: {
            activity,
            activity_id,
            activity_content_id: scienceId,
            title,
            career_photo: uploadImgUrl
          }
        })
      } else {
        if(activity.length){
          // @ts-ignore
          let res = await participateActivity({activity})
          // @ts-ignore
          if(res){
            message.success("报名成功");
          } else {
            message.error("报名失败，请稍后重试！");
            return
          }
        }
        this.props.history.push("/activity")
      }
    }
  }

  // @ts-ignore
  turnDateFormat = (date) => {
    if(date < 10){
      return "0" + date
    } else {
      return date
    }
  }

  getTimeData = () => {
    const resList :Array<{label :string, value :number}> = [];
    for(let i = 0;i <= 24;i++){
      resList.push({
        label: `${i < 10 ? "0" + i : i}:00`,
        value: i,
      })
    }
    return [[...resList], [...resList]];
  }

  render() {
    const {
      uploadImgUrl,
    } = this.store
    const {
      liveId,
      onlineId,
      scienceId,
      startTime,
    } = this.paramStore

    return <div className={style.commitContainer}>
      <div className={style.main}>
        <div className={style.timeBox}>
          活动时间
          <span>{startTime[0] + "." + this.turnDateFormat(startTime[1]+1) + "." + this.turnDateFormat(startTime[2])}</span>
        </div>
        <div>
          <div className={style.title}><span>*</span>全身职业照</div>
          <label key={"upload"} className={style.uploadContainer} htmlFor={"upload"}>
            {uploadImgUrl
              ? (
                <div className={style.uploadImgBox}>
                  <img src={uploadImgUrl} />
                </div>
              )
              : <div className={style.uploadBox}>
                <div className={style.uploadAddIcon}>
                  <span className={style.addIconTxt}>+</span>
                </div>
                <div className={style.clickUpTxt}>点击上传</div>
              </div>
            }
            <input
              style={{ display: 'none' }}
              className={style.inputUpload}
              type="file"
              id={"upload"}
              accept="image/*"
              onChange={(e) => this.domEvents.uploadAvatar(e)}
            />
          </label>
        </div>
        {liveId && <div>
          <div className={style.title}><span>*</span>请选择可参加权威直播活动时间</div>
          <div className={style.timeContainer} onClick={() => {
            this.domEvents.showModal(0)
          }}>{this.state.timeStore.live.length ? <span style={{color: "black"}}>{this.state.timeStore.live[0]}:00 - {this.state.timeStore.live[1]}:00</span> : "例：8:00 - 14:00"}</div>
        </div>}
        {onlineId && <div>
          <div className={style.title}><span>*</span>请选择可参加在线义诊活动时间</div>
          <div className={style.timeContainer} onClick={() => {
            this.domEvents.showModal(1)
          }}>{this.state.timeStore.online.length ? <span style={{color: "black"}}>{this.state.timeStore.online[0]}:00 - {this.state.timeStore.online[1]}:00</span> : "例：8:00 - 14:00"}</div>
        </div>}
      </div>
      <div className={style.confirmBtn} onClick={this.domEvents.confirm}>{scienceId ? "下一步，补充健康科普" : "确认"}</div>
      <Modal
        popup
        visible={this.state.modalVisible}
        onClose={this.domEvents.closeModal}
        animationType="slide-up"
        className={style.modalContainer}
      >
        <div>
          <div>
            <span className={style.tagLeft} onClick={this.domEvents.closeModal}>取消</span>
            <span className={style.tagRight} onClick={this.domEvents.modalConfirm}>确定</span>
            <p className={style.modalTitle}>选择时间</p>
          </div>
          <div className={style.timePickerContainer}>
            <div className={style.line}/>
            <PickerView
              onChange={this.domEvents.onTimeChange}
              value={this.state.timeValue}
              data={this.timeData}
              cascade={false}
            />
          </div>
        </div>
      </Modal>
    </div>;
  }
}

export default CommitActivity
