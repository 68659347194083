import React, { Component, useCallback } from 'react';
import { observable, action, computed } from 'mobx';
import { observer } from 'mobx-react';
import message from '@/components/message';
import Modal from '@/components/modal';
import LoginService from '@/services/loginService';
import DoctorInfoService from '@/services/doctorInfoService';
import { loginRedirectPath, prefix, appid } from '@/config/conf';
import util from '@/utils';
import styles from './index.module.css';
interface InputProps {
  type: 'phone' | 'code';
  name: string;
  value: string;
  placeholder?: string;
  onChange(arg0: string, arg1: string): void;
}

function checkWxCode(code: string) {
  if (!code) {
    return false;
  }
  const key = `${prefix}wxcode`;

  const localCode = window.localStorage.getItem(key);
  if (code === localCode) {
    // 检查当前code有没有被使用过；
    return false;
  }
  return true;
}
function redirect() {
  const redirectURI = encodeURIComponent(loginRedirectPath);
  const href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirectURI}&response_type=code&scope=snsapi_userinfo#wechat_redirect`;
  window.location.replace(href);
}
const Input: React.FC<InputProps> = ({ name, value, type, placeholder, children, onChange }) => {
  const changeHandler = useCallback((event) => {
    const { maxLength } = event.target;
    const newValue = event.target.value.slice(0, maxLength);
    onChange(newValue, name);
  }, []);
  const maxLength = type === 'phone' ? 11 : 6;
  
  return (
    <div className={styles.inputContainer}>
      <input
        type="number"
        placeholder={placeholder}
        maxLength={maxLength}
        value={value}
        className={styles.input}
        onChange={changeHandler}
      />
      {children && <div className={styles.inputChildren}>{children}</div>}
    </div>
  );
};

@observer
class Login extends Component {
  service = LoginService;

  @observable
  wxCode = '';

  @observable
  phone = '';

  @observable
  fp0 = '';

  @observable
  verifyCode = '';

  @observable
  agreed = false;

  @observable
  agreementOpen = false;

  @observable
  unknown = false;

  @observable
  timeLeft = 0; // 验证码下次可请求倒计时

  @observable timer: null | NodeJS.Timer = null;

  UNSAFE_componentWillMount() {
    // window.LoginTea('登录页','define_pageview');
  };

  componentDidMount() {
    this.wxCode = new URL(window.location.href).searchParams.get('code') || '';
    const isCodeAvailable = checkWxCode(this.wxCode);
    if (!isCodeAvailable && util.needWXCheckEnv) { // 只在生产、预览、测试环境下微信验证
      redirect();
    }
    message.config({
      maxCount: 1
    });
    try {
      const phone = window.localStorage.getItem(`${prefix}phone`);
      if (phone) {
        this.phone = phone;
      }
    } finally {
      document.title = '登录';
    }
  }

  @computed
  get buttonDisabled() {
    return this.phone.length !== 11;
  }

  @action
  inputPhone = (value) => {
    this.phone = value;
    return value;
  };

  @action
  inputVerifyCode = (value, event) => {
    console.log(event);
    this.verifyCode = value;
    return value;
  };

  @action
  showAgreement = () => {
    this.agreementOpen = true;
  };

  @action
  closeAgreement = () => {
    this.agreementOpen = false;
  };

  @action
  sendVerifyCode = () => {
    let messageText = '';
    if (!this.phone || this.phone.length !== 11) {
      messageText = '手机号格式不正确';
    }
    if (messageText) {
      this.showToast(messageText);
      return;
    }
    this.service
      .sendVerifyCode(this.phone)
      .then(() => {
        this.timeLeft = 60;
        this.timer && clearInterval(this.timer);
        this.timer = setInterval(() => {
          this.timeLeft -= 1;
          if (this.timeLeft === 0 && this.timer) {
            clearInterval(this.timer);
          }
        }, 1000);
        messageText = '验证码已发送';
        this.showToast(messageText);
      })
      .catch((err) => {
        console.log(err);
        if (err && err.message) {
          console.log(err.message);
          this.showToast(err.message);
        }
      });
  };

  @action
  checkAgreement = () => {
    this.agreed = !this.agreed;
  };

  @action
  showToast = (text: string) => {
    return message.info(text);
  };

  @action
  clickButton = () => {
    // window.LoginTea('登录页','sign_in');
    let messageText = '';
    if (!this.verifyCode || this.verifyCode.length !== 6) {
      messageText = '验证码格式不正确';
    }
    if (!this.phone || this.phone.length !== 11) {
      messageText = '手机号格式不正确';
    }
    window.localStorage.setItem(`${prefix}phone`, this.phone);

    if (messageText) {
      this.showToast(messageText);
      return;
    }
    this.login();
  };

  @action
  login = () => {
    this.service
      // .login(this.wxCode, this.phone, this.verifyCode)
      .login(this.phone, this.verifyCode,6)
      .then((res) => {
        if (res) {
          new DoctorInfoService()
            .getUserInfo()
            .then((res0) => {
              console.log('医生信息初始化--', res0);
              window.location.href = '/';
            })
            .catch((err) => {
              if (err && err.message === '20302') {
                this.unknown = true;
              }
            });
        }
      })
      .catch((err) => {
        console.log(err, err.message);
        if (err && err.message) {
          if (err.message === 'unknown') {
            this.unknown = true;
          } else if (err.message === 'later') {
            this.showToast('登录失败，请稍后重试');
          } else if (err.message) {
            this.showToast(err.message);
          } else {
            this.showToast(err.message).then(redirect, redirect);
          }
        }
      });
  };

  // @action
  // authlogin = () => {
  //   const code = new URL(window.location.href).searchParams.get('code') || '';
  //   console.log('code', code);
  //   this.service
  //     .authlogin(code)
  //     .then((res) => {
  //       console.log('第三方登录', res);
  //     })
  //     .catch((err) => {
  //       console.log(err, err.message);
  //       if (err && err.message) {
  //         if (err.message === 'later') {
  //           this.showToast('登录失败，请稍后重试');
  //         } else {
  //           this.showToast(err.message).then(redirect, redirect);
  //         }
  //       }
  //     });
  // };

  @action
  closeUnknow = () => {
    this.unknown = false;
  };

  renderGetCode = (timeLeft) =>
    timeLeft > 0 ? (
      <div className={styles.getCode}>{timeLeft}s后重试</div>
    ) : (
      <div className={styles.getCode} onClick={
        ()=>{
          this.sendVerifyCode();
        }
        }>
        获取验证码
      </div>
    );

  render() {
    if (util.needWXCheckEnv && !this.wxCode) { // 生产、预览、测试环境下微信验证码code必须存在，否则返回null
      return null;
    }
    return (
      <div className={`${styles.login} page`}>
        <div className={styles.title}>
          请输入您申报的手机号，若未申报，可在服务号中联系客服申报
        </div>
        <div>
          <div style={{fontSize:'88px'}} id='captcha_container'/>
        </div>
        <div className={styles.form}>
          <Input
            name="phone"
            type="phone"
            value={this.phone}
            placeholder="请输入手机号"
            onChange={this.inputPhone}
          />

          <Input
            name="code"
            type="code"
            value={this.verifyCode}
            placeholder="请输入验证码"
            onChange={this.inputVerifyCode}
          >
            {this.renderGetCode(this.timeLeft)}
          </Input>
          <div
            className={this.buttonDisabled ? `${styles.submit} disabled` : `${styles.submit}`}
            onClick={this.clickButton}
          >
            登录
          </div>
        </div>

        <Modal
          open={this.unknown}
          title="手机号未申报"
          content={[
            '您输入的手机号未被申报，可在服务号中「联系客服」，提供基础信息和联系方式，便可认领个人名片。'
          ]}
          onClose={this.closeUnknow}
        />
      </div>
    );
  }
}
export default Login;
