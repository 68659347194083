import React, { Component } from 'react';
// import classNames from 'classnames/bind';
import { Link } from 'react-router-dom';
import Checkbox, { CheckboxChangeEvent } from '@/components/checkbox';
import { ItemData } from '@/services/contentsService';
import styles from './contentItem.module.css';

// const cx = classNames.bind(styles);

interface ItemProps {
  data: ItemData;
  index: number;
  selected: boolean;
  onSelect(arg0: number, arg1: boolean): void;
  onClickDisabled(state: number): void;
}

class ContentItem extends Component<ItemProps> {
  // statusMap = ['', '未通过', '审核中'];

  statusMap = {
    article: '图文',
    jkb_note: '医生笔记',
  //  ask: '文字问答', voice: '语音问答',
    video: '视频' };

  handleSelectChange = (event: CheckboxChangeEvent) => {
    const { value, checked } = event.target;
    console.log(value, checked);
    const { onSelect } = this.props;
    onSelect(value, checked);
  };

  onCheckboxClick = () => {
    const {
      data: { state },
      onClickDisabled
    } = this.props;
    if (state > 1 && state < 4) {
      onClickDisabled(state);
      console.log('click disabled', state);
    }
  };

  render() {
    const { index, selected, data } = this.props;
    const { title, state, snippet, update_time, fromsite, cate, doc_id, file } = data;
    // 作品列表 state 枚举  1=>草稿，2=>审核失败，3=>审核中，4=>审核失败，5=>屏蔽
    const selectDisabled = state !== 1; // state > 1 && state < 4;
    const footerArr: string[] = [];
    if (update_time) {
      footerArr.push(update_time);
    }
    if (fromsite) {
      footerArr.push(fromsite);
    }
    const statusLabel = this.statusMap[cate];
    const footer = footerArr.join(' | ');
    return (
      <div className={styles.container}>
        <div className={styles.checkbox} />
        {/* <Checkbox
            value={index}  onClick={this.onCheckboxClick}
            checked={selected}
            disabled={selectDisabled}
            onChange={this.handleSelectChange}
          /> */}

        <Link className={styles.content} to={`/preview/${cate}/${doc_id}`}>
          <div className={styles.header}>
            <span className={styles.title}>{title}</span>
            {statusLabel && <span className={styles.status}>{statusLabel}</span>}
          </div>
          {file && file.name && (
            <div className={styles.fileContainer}>
              <div className={styles.fileIcon} />
              <div className={styles.fileName}>{file.name}</div>
            </div>
          )}

          <div className={styles.abstact}>{snippet}</div>
          <div className={styles.footer}>{footer}</div>
        </Link>
      </div>
    );
  }
}

export default ContentItem;
