import React, { Component } from 'react';
import styles from './index.module.css';

type Props = {
  open: boolean;
  title: string;
  content: string[];
  onClose();
};

class Agreement extends Component<Props> {
  close = () => {
    this.props.onClose();
  };

  render() {
    const { open, title, content } = this.props;
    return open ? (
      <div className={styles.modal}>
        <div className={styles.container}>
          <div className={styles.close} onClick={this.close} />
          <div className={styles.title}>{title}</div>
          <div className={styles.content}>
            {content.map((para, index) => {
              const keyStr = `${index}-para`;
              return (
                <div className="paragraph" dangerouslySetInnerHTML={{__html:para}} key={keyStr}/>
              );
            })}
            <div style={{ height: '30px' }} />
          </div>
        </div>
      </div>
    ) : null;
  }
}
export default Agreement;
