/**
 * @file signInService.ts 医者B端服务号登录、注册接口
 */

 import axios from '@/utils/interceptors';
 import Token from '@/utils/token';
import { APIPath, prefix, HostAPIPath } from '@/config/conf';

axios.defaults.withCredentials = true;
interface LoginResponse {
  code: number;
}

class LoginService {
  /**
   * 发送验证码
   *
   * @param {string} phone 电话
   * @param {string} loginSource 登录来源
   * @returns {Promise}
   * @memberof SignInService
   */
  static sendVerifyCode(mobile: string): Promise<string> {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${HostAPIPath}mobile/sendVerifyCode`, //TODO APIPath
          { phonenum: mobile }
        )
        .then((res) => {
          console.log(res);
          if (res.data.code === 200) {
            resolve('success');
          } else {
            reject(new Error(res.data.message));
          }
        })
        .catch(() => {
          console.log('出错');
          reject(new Error('验证码发送失败，请稍后重试'));
        });
    });
  }

  static checkPhoneAvailable(phone: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${APIPath}doctorauthapi/checkphone`, {
          params: {
            phone_num: phone,
            login_source: 9
          }
        })
        .then((res) => {
          if (res.data.code === 0 && res.data?.data?.data?.state === '1') {
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch(() => {
          reject(new Error('验证码发送失败，请稍后重试'));
        });
    });
  }

  // code: string, phone: string, verifyCode: string
  static login(phonenum: string, code: string, source: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      axios
        .post(`${HostAPIPath}mobile/user/login`, {
          phonenum,
          code,
          source
        })
        .then((res) => {
          if (res.data.code === 200) {
            Token.setToken(res.data.data.token)
            resolve(true);
          } else if (res.data.code === 30001){
            reject(new Error('unknown'));
          } else {
            reject(new Error(res?.data?.message));
          }
        })
        .catch(() => {
          reject(new Error('later'));
        });
    });
  }

  // 第三方登录
  // static authlogin(code: string ): Promise<boolean> {
  //   try {
  //     const key = `${prefix}wxcode`;
  //     // window.localStorage.setItem(key, code);
  //   } finally {
  //     // do nothing
  //   }
  //   const formdata= new FormData();
  //   formdata.append('code',code);
  //   formdata.append('platform_app_id', '1253');
  //   formdata.append('platform','weixin');
  //   formdata.append('aid', '193767');
  //   return new Promise((resolve, reject) => {
  //     axios
  //       .post(
  //         `${APIPath}passport/web/auth/login/`,
  //         formdata,
  //         {headers: { 'Content-Type': 'application/x-www-form-urlencoded' }}
  //       )
  //       .then((res) => {
  //         // if (res.data.message==='success') {
  //         //   resolve(true);
  //         // } else {
  //         //   resolve(false);
  //         // }
  //         console.log(res);
  //         resolve(true);
  //       })
  //       .catch(() => {
  //         reject(new Error('later'));
  //       });
  //   });
  // }
}

export default LoginService;
