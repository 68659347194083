import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import DoctorInfoService from '@/services/doctorInfoService';
import HomeStore from '@/stores/homeStore';
import GuidMask from '@/components/guidMaskOfOldUser';
import defaultAvatar from '@/static/images/avatar.png';
import message from '@/components/message';
import styles from './index.module.css';
import { Carousel,Modal,} from 'antd-mobile';
import ContentsService from '../../services/contentsService'
import doctor_diary from "../../static/images/doctor_diary.png";
import activity from "../../static/images/activity.png"
import new_topic from '../../static/images/new_topic.png'
import tw_img from '../../static/images/tw.png'
import sp_img from '../../static/images/sp.png'
import my_art_img from '../../static/images/my_art.png'
import yy_img from '../../static/images/yy.png'
const lsgDoctorUrl =
  'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzU0NDU4OTEwMw==#wechat_redirect';
const healthChinaUrl =
  'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzI1MTczMDk2Nw==#wechat_redirect';

interface ButtonProps {
  name: string;
  text: string;
}

const Button: React.FC<ButtonProps> = ({ name, text }) => (
  <div className={styles.button}>
    <div className={styles.buttonName}>{name}</div>
    <div className={styles.buttonText}>{text}</div>
  </div>
);

@observer
class Home extends Component {
  service: DoctorInfoService;
  contentService:any;
  store: HomeStore;
  state:any;
  name = '医生';

  constructor(props: any) {
    super(props);
    this.service = new DoctorInfoService();
    this.store = new HomeStore(this.service);
    this.state = {
      // hasNewTopic:false, 无用，议题列表需要即时获取。
      hasRedDot:false
    }
    this.contentService = new ContentsService();
  }

  componentDidMount() {
    // this.service.checkNewMsg().then(res=>{
    //     this.setState({
    //       hasRedDot:res.has_new
    //     })
    // })

  }

  showNotOpen = () => {
    message.info('暂未开放，尽请期待');
  };

  // && doctor.state > 1

  render() {
    const{ hasRedDot } = this.state;
    const { doctor, doctorFlag, setDoctorFlag, oldUserNeedAuth } = this.store;
    if (doctor) {
      const isCompleteAuth = +doctor.state > 1 && !oldUserNeedAuth; // 完成认证
      const { headimg_url = '' } = doctor || {};
      const avatar = headimg_url || defaultAvatar;
      const carouselData = [{
        bannerImg: 'https://image.jkzgxd.cn/tos-cn-i-a3h8qa8x9l/54a70318770c4e17b8533fb50f895834~tplv-a3h8qa8x9l-png.png?',
        href: 'https://b.u.mgd5.com/c/5hwz/zas0/index.html',
      }, { // 底部轮播图组件
        bannerImg: 'http://image.jkzgxd.cn/tos-cn-i-a3h8qa8x9l/f840adc04e974a0b90ea28e83f6f16a0~tplv-a3h8qa8x9l-png.png',
        href: 'https://a.app.qq.com/o/simple.jsp?pkgname=com.aurora.xiaohe.app_doctor',
        },
      //   {
      //   bannerImg: 'http://image.jkzgxd.cn/tos-cn-i-a3h8qa8x9l/62746d2d62616e6e6572.png~tplv-a3h8qa8x9l-png.png',
      //   href: '/grow',
        // },
        {
        bannerImg: 'http://image.jkzgxd.cn/tos-cn-i-a3h8qa8x9l/62616e6e657233.jpeg~tplv-a3h8qa8x9l-jpg.jpeg',
        href: 'https://www.wjx.cn/jq/96451717.aspx',
      }];
      return (
        <div className={`${styles.home} page`}>
          {doctorFlag && (
            <div
              onClick={setDoctorFlag}
            >
              {
                oldUserNeedAuth
                  ? <GuidMask />
                  : <img
                    className={styles.start_zoom}
                    src="http://image.jkzgxd.cn/tos-cn-i-a3h8qa8x9l/c053f8dae6984c2d80f868b3512c971e~tplv-a3h8qa8x9l-png.png"
                    alt="请先编辑名片"
                  />
              }
            </div>
          )}
          <div className={styles.head}>
            <div className={styles.head_info}>
              <div
                className={styles.head_score}
                onClick={() => {
                  if (isCompleteAuth) {
                    localStorage.setItem('doctor_id', doctor.doctor_id);
                    const url = '/score?doctor';
                    window.location.href = url;
                  } else {
                    setDoctorFlag();
                  }
                }}
              >
                <div className={styles.headScoreIcon} />
                <div>{doctor.score || 0}积分</div>
                <div className={styles.headRightIcon} />
              </div>
              <div className={styles.head_right}>
                <Link className={styles.buttonLink} to="/edit">
                  <div className={styles.head_edit}>
                    <div className={styles.headEditIcon} />
                    编辑
                  </div>
                </Link>
                <div
                  className={styles.head_share}
                  onClick={() => {
                    if (isCompleteAuth) {
                      const url = `/share/${doctor.doctor_id}`;
                      window.location.href = url;
                    } else {
                      setDoctorFlag();
                      // window.location.href = 'edit';
                    }
                  }}
                >
                  <div className={styles.headShareIcon} />
                  分享
                </div>
              </div>
            </div>
            <div className={styles.avatar}>
              <img className={styles.image} src={avatar} alt="avatar" />
            </div>
            <div className={styles.name}>{doctor.name}</div>

            <div>
              {doctor.recommend_institutions_id > 0 && (
                <div hidden={doctor.recommend_institutions_name.trim() === ''} className={styles.organ}>
                  推荐机构：{doctor.recommend_institutions_name || ''}
                </div>
              )}
            </div>
          </div>
          <div className={styles.body}>
            <div className={styles.works_card}>
              <div className={styles.my_title}>创作</div>
              <div className={styles.works_box}>
                <div
                  className={styles.works_item}
                  onClick={() => {
                    if (isCompleteAuth) {
                      const url = '/content/editor/article';
                      window.location.href = url;
                    } else {
                      setDoctorFlag();
                      // window.location.href = 'edit';
                    }
                  }}
                >
                  <img
                    className={styles.works_icon}
                    src={tw_img}
                    alt="tw"
                  />
                  <div className={styles.works_title}>新建图文</div>
                </div>
                {/*
                <div
                  className={styles.works_item}
                  onClick={() => {
                    if (doctor.state > 1) {
                      const url = '/voice-ask';
                      window.location.href = url;
                    } else {
                      setDoctorFlag();
                      // window.location.href = 'edit';
                    }
                  }}
                >
                  <img
                    className={styles.works_icon}
                    src={yy_img}
                    alt="yy"
                  />
                  <div className={styles.works_title}>语音问答</div>
                </div> */}

                <div
                  className={styles.works_item}
                  onClick={() => {
                    if (isCompleteAuth) {
                      const url = '/video';
                      window.location.href = url;
                    } else {
                      setDoctorFlag();
                      // window.location.href = 'edit';
                    }
                  }}
                >
                  <img
                    className={styles.works_icon}
                    src={sp_img}
                    alt="sp"
                  />
                  <div className={styles.works_title}>上传视频</div>
                </div>
                <div
                  className={styles.works_item}
                  onClick={() => {
                    if (isCompleteAuth) {
                      const url = '/content/editor/jkb_note';
                      window.location.href = url;
                    } else {
                      setDoctorFlag();
                      // window.location.href = 'edit';
                    }
                  }}
                >
                  <img
                    className={styles.works_icon}
                    src={doctor_diary}
                    alt="sp"
                  />
                  <div className={styles.works_title}>医生笔记</div>
                </div>
                {/* 新建议题 */}
                {/* <div
                  className={styles.works_item}
                  onClick={async () => {
                    // 新议题状态需要即时获取最新状态。
                    const topicRes = await this.contentService.getDocTemplate();
                    const hasNewTopic = Boolean(topicRes.template_list?.length);
                    const redDotRes = await this.service.postNewMsg();
                    this.setState({
                      hasRedDot:redDotRes.has_new
                    })
                    if (isCompleteAuth) {
                      if(hasNewTopic){
                        const url = '/content/editor/sc_topic';
                        window.location.href = url;
                      }else{
                        message.info('您未收到定向议题，不能使用此功能');
                      }

                    } else {
                      setDoctorFlag();
                      // window.location.href = 'edit';
                    }
                  }}
                >
                  <img
                    className={styles.works_icon}
                    src={new_topic}
                    alt="sp"
                  />
                  <div className={styles.works_title}>科普议题</div>
                  {
                    hasRedDot && <div className={styles.dot}></div>
                  }
                </div> */}
                {/*<div/>*/}
              </div>
            </div>
            <div className={styles.blod_line} />
            <div className={styles.works_card}>
              <div className={styles.my_title}>管理</div>
              <div className={styles.works_box}>
                <div
                  className={styles.works_item}
                  onClick={() => {
                    if (isCompleteAuth) {
                      const url = '/content/manage';
                      window.location.href = url;
                    } else {
                      setDoctorFlag();
                      // window.location.href = 'edit';
                    }
                  }}
                >
                  <img
                    className={styles.works_icon}
                    src={my_art_img}
                    alt="tw"
                  />
                  <div className={styles.works_title}>我的作品</div>
                </div>
                <div />
                <div />
              </div>
            </div>
            <div className={styles.blod_line} />
            {/* <div className={styles.works_card}>
              <div className={styles.my_title}>健康科普公益行</div>
              <div className={styles.works_box}>
                <div
                  className={styles.works_item}
                  onClick={() => {
                    if (isCompleteAuth) {
                      const url = '/activity';
                      window.location.href = url;
                    } else {
                      setDoctorFlag();
                      // window.location.href = 'edit';
                    }
                  }}
                >
                  <img
                    className={styles.works_icon}
                    src={activity}
                    alt="tw"
                  />
                  <div className={styles.works_title}>每月活动</div>
                </div>
                <div />
                <div />
              </div>
            </div> */}

            <div className={styles.btm0}>
              <div className={styles.btm_box}>
                <div className={styles.btm_leftline} />
                <span className={styles.btm_title}>健康中国医者名片</span>
                <div className={styles.btm_rightline} />
              </div>
            </div>
            <div className={styles.bottom_banner_box}>
              <Carousel
                autoplay={true}
                autoplayInterval={2000}
                infinite
              >
                {
                  carouselData.map((item, index) => {
                    return <div className={styles.banner}
                      key={item.bannerImg + index}
                      onClick={() => {
                  
                     
                        setTimeout(() => {
                          if (isCompleteAuth) {
                            window.location.href = item.href;
                          } else {
                            setDoctorFlag();
                          }
                        }, 300)
                      }}>
                      <img src={item.bannerImg} alt="banner" />
                    </div>
                  })
                }
              </Carousel>
            </div>
          </div>
        </div>
      );
    }
    return null;
  }
}

export default Home;
