import { observable, action, computed } from 'mobx';
// import message from '../components/message';
// 必须引入的app核心文件，换成require也是一样的。注意：app.js会自动往window下挂载名称为RecordApp对象，全局可调用window.RecordApp，也许可自行调整相关源码清除全局污染
import RecordApp from 'recorder-core/src/app-support/app';

// // 可选开启IOS上微信录音支持，需要引入此文件
import 'recorder-core/src/app-support/app-ios-weixin-support';

// 这里放置可选的独立配置文件，提供这些文件时可免去修改app.js源码。这些配置文件需要自己编写，参考https://github.com/xiangyuecn/Recorder/tree/master/app-support-sample 目录内的这两个测试用的配置文件代码。
// import '你的配置文件目录/native-config.js' //可选开启native支持的相关配置
import '../config/ios-weixin-config'; // 可选开启ios weixin支持的相关配置

/* ********然后加载Recorder需要的文件********** */
// 必须引入的核心。所有文件都需要自行引入，否则app.js会尝试用script来请求需要的这些文件，进而导致错误，引入后会检测到组件已自动加载，就不会去请求了
import 'recorder-core';

// 需要使用到的音频格式编码引擎的js文件统统加载进来 ----漏洞
// import 'recorder-core/src/engine/mp3';
// import 'recorder-core/src/engine/mp3-engine';

// 由于大部分情况下ios-weixin的支持需要用到amr解码器，应当把amr引擎也加载进来
import 'recorder-core/src/engine/beta-amr';
import 'recorder-core/src/engine/beta-amr-engine';
import message from '@/components/message';
import ContentsService, {
  VoiceQuestion,
  NewVoiceContent,
  SaveContent
} from '../services/contentsService';

declare global {
  interface Window {
    RecordApp: any;
  }
}
function secondsToMinutes(s: number): string {
  // 计算分钟
  // 算法：将秒数除以60，然后下舍入，既得到分钟数
  let h;
  let second;
  h = Math.floor(s / 60);
  // 计算秒
  // 算法：取得秒%60的余数，既得到秒数
  second = s % 60;
  // 将变量转换为字符串
  h += '';
  second += '';
  h = h.length === 1 ? `0${h}` : h;
  second = second.length === 1 ? `0${second}` : second;
  return `${h}:${second}`;
}

class VoiceRecorderStore {
  service: ContentsService;

  @observable
  id: string;

  @observable
  question?: VoiceQuestion;

  @observable
  reCreate = false;

  @observable
  mediaId?: string;

  @observable
  voiceUrl?: string;

  @observable
  fromTab: string;

  @observable
  fromPageNum = 1;

  @observable
  fromPosition = 0;

  @observable
  showGuide = 0;

  @observable
  time = 0;

  @observable timer: null | NodeJS.Timer = null;

  @observable
  status = 0; // 0 停止，1录音中

  @observable
  showDialog = false;

  @observable
  showPlayer = false;

  @observable
  loaded = false;

  constructor(id: string) {
    this.service = new ContentsService();
    this.id = id;
    const { searchParams } = new URL(window.location.href);
    const isRecreate = searchParams.get('recreate');
    this.fromTab = searchParams.get('from') || '';
    this.reCreate = !!isRecreate;
    this.init(this.id);
  }

  @action
  init = (id: string) => {
    this.id = id;
    if (this.reCreate) {
      this.service
        .getVoiceContent(this.id)
        .then((res) => {
          this.question = {
            title: res.title,
            qid: ''
          };
          this.loaded = true;
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      const { searchParams } = new URL(window.location.href);
      const fromPageNum = parseInt(searchParams.get('pn') || '1', 10);
      const fromPosition = parseInt(searchParams.get('position') || '0', 10);
      const pageSize = parseInt(searchParams.get('page_size') || '10', 10);
      this.service
        .getVoiceQuestionInfo(id, fromPageNum, fromPosition, pageSize, this.fromTab)
        .then((question) => {
          this.question = question;
          this.loaded = true;
          this.setShowGuide();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  @computed
  get duration() {
    return secondsToMinutes(this.time);
  }

  @computed
  get isVoiceExist() {
    return this.time > 0;
  }

  @action
  restartRecord = () => {
    this.closeDialog();
    this.time = 0;
    this.startRecord();
  };

  @action
  startRecord = () => {
    this.hidePlayer();
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    RecordApp.RequestPermission(
      function() {
        // dialog&&dialog.Cancel(); 如果开启了弹框，此处需要取消

        RecordApp.Start(
          {
            // 如果需要的组件还在延迟加载，Start调用会等待这些组件加载完成后才会调起底层平台的Start方法，可通绑定RecordApp.Current.OnLazyReady事件来确定是否已完成组件的加载，或者设置RecordApp.UseLazyLoad=false来关闭延迟加载（会阻塞Install导致RequestPermission变慢）
            type: 'mp3',
            sampleRate: 16000,
            bitRate: 16 // mp3格式，指定采样率hz、比特率kbps，其他参数使用默认配置；注意：是数字的参数必须提供数字，不要用字符串；需要使用的type类型，需提前把支持文件到Platforms.Default内注册
            // onProcess(
            //   buffers,
            //   powerLevel,
            //   bufferDuration,
            //   bufferSampleRate,
            //   newBufferIdx,
            //   asyncEnd
            // ) {
            //   console.log(
            //     buffers,
            //     powerLevel,
            //     bufferDuration,
            //     bufferSampleRate,
            //     newBufferIdx,
            //     asyncEnd
            //   );
            //   // 如果当前环境支持实时回调（RecordApp.Current.CanProcess()），收到录音数据时就会实时调用本回调方法
            //   // 可利用extensions/waveview.js扩展实时绘制波形
            //   // 可利用extensions/sonic.js扩展实时变速变调，此扩展计算量巨大，onProcess需要返回true开启异步模式
            // }
          },
          function() {
            that.status = 1;
            that.timer = setInterval(() => {
              that.time += 1;
            }, 1000);
          },
          function(msg) {
            console.log(`开始录音失败：${msg}`);
          }
        );
      },
      function(msg, isUserNotAllow) {
        // 用户拒绝未授权或不支持
        // dialog&&dialog.Cancel(); 如果开启了弹框，此处需要取消

        console.log(`${isUserNotAllow ? 'UserNotAllow，' : ''}无法录音:${msg}`);
      }
    );
  };

  @action
  stopRecord = () => {
    this.status = 0;
    if (this.timer) {
      clearInterval(this.timer);
    }
    message.loading('保存录音文件中', 0);
    RecordApp.Stop(
      (blob, duration) => {
        this.uploadFile(blob);
        console.log(blob, duration);
      },
      (err) => {
        console.log(err);
      }
    );
  };

  @action
  openPlayer = () => {
    this.showPlayer = true;
  };

  @action
  hidePlayer = () => {
    this.showPlayer = false;
  };

  @action
  uploadFile = (fileData: Blob) => {
    const formData = new FormData();
    const file = new window.File([fileData], `${this.id}-${Date.now()}.mp3`, {
      type: fileData.type
    });
    formData.append('file', file);
    this.service
      .uploadFile(formData)
      .then((res) => {
        if (
          res &&
          res.data.code === 0 &&
          res.data.data &&
          res.data.data[0] &&
          res.data.data[0].url
        ) {
          this.voiceUrl = res.data.data[0].url;
          message.success('录音完成');
        } else {
          message.info('录音失败，请稍后重试录制'); //
        }
      })
      .catch(() => {
        message.info('录音失败，请稍后重试录制');
      });
  };

  @action
  openDialog = () => {
    this.showDialog = true;
  };

  @action
  closeDialog = () => {
    this.showDialog = false;
  };

  handleButtonClick = () => {
    if (this.status === 0) {
      // 已停止 -> 重录/开始录
      if (this.isVoiceExist) {
        // 提示是否重录
        this.openDialog();
      } else {
        this.startRecord();
      }
    } else {
      // 录音中 -> 停止
      this.stopRecord();
    }
  };

  @action
  handlePublishClick = () => {
    const { question, fromTab, voiceUrl, reCreate, createContent, publish, saveContent, id } = this;
    if (!voiceUrl) {
      message.info('尚未完成录制');
    } else if (this.time < 20) {
      message.info('您的语音录制时长较短，大于20秒方可提交');
    } else {
      message.loading('预发布中', 0);
      if (reCreate) {
        saveContent()
          .then(() => {
            publish(id)
              .then(() => {
                message.info('已提交审核，审核通过后发布', 1.5, () => {
                  window.location.href = '/content/manage';
                });
                // message.info('已提交审核，审核通过后发布').then(
                //   () => {
                //     if (question && question.next_qid) {
                //       window.location.href = `/voice-recorder/${
                //         question.next_qid
                //       }?from=${fromTab}&pn=${question.page_num || 1}&position=${question.position ||
                //         0}&page_size=${question.page_size || 10}`;
                //     } else {
                //       window.location.href = '/content/manage';
                //     }
                //   },
                //   () => {
                //     if (question && question.next_qid) {
                //       window.location.href = `/voice-recorder/${
                //         question.next_qid
                //       }?from=${fromTab}&pn=${question.page_num || 1}&position=${question.position ||
                //         0}&page_size=${question.page_size || 10}`;
                //     } else {
                //       window.location.href = '/content/manage';
                //     }
                //   }
                // );
              })
              .catch(() => {
                message.info('预发布失败');
              });
          })
          .catch(() => {
            message.info('保存失败');
          });
      } else {
        const voiceContent: NewVoiceContent = {
          cate: 'voice',
          title: question && question.title ? question.title : '',
          voice_url: voiceUrl,
          voice_time: secondsToMinutes(this.time),
          qid: this.id
        };
        createContent(voiceContent)
          .then((docId) => {
            publish(docId)
              .then(() => {
                message.info('已提交审核，审核通过后发布', 1.5, () => {
                  window.location.href = '/content/manage';
                });
                // message.info('预发布成功，审核通过后，可在「已发布」列表中查看').then(
                //   () => {
                //     if (question && question.next_qid) {
                //       window.location.href = `/voice-recorder/${
                //         question.next_qid
                //       }?from=${fromTab}&pn=${question.page_num || 1}&position=${question.position ||
                //         0}&page_size=${question.page_size || 10}`;
                //     } else {
                //       window.location.href = '/content/manage';
                //     }
                //   },
                //   () => {
                //     if (question && question.next_qid) {
                //       window.location.href = `/voice-recorder/${
                //         question.next_qid
                //       }?from=${fromTab}&pn=${question.page_num || 1}&position=${question.position ||
                //         0}&page_size=${question.page_size || 10}`;
                //     } else {
                //       window.location.href = '/content/manage';
                //     }
                //   }
                // );
              })
              .catch(() => {
                message.info('预发布失败');
              });
          })
          .catch(() => {
            message.info('预发布失败');
          });
      }
    }
  };

  @action
  createContent = (voiceContent: NewVoiceContent): Promise<string> => {
    return new Promise((resolve, reject) => {
      this.service
        .createContent(voiceContent)
        .then((res) => {
          if (res && res.data.code === 0 && res.data?.data?.data) {
            const { doc_id } = res.data.data.data;
            resolve(doc_id);
          } else {
            reject(new Error('fail1'));
          }
        })
        .catch(() => {
          reject(new Error('fail2'));
        });
    });
  };

  @action
  saveContent = () => {
    const { id, question, voiceUrl, time } = this;
    const params: SaveContent = {
      cate: 'voice',
      uid: id,
      title: question && question.title ? question.title : '',
      voice_url: voiceUrl,
      voice_time: secondsToMinutes(time)
    };

    return new Promise((resolve, reject) => {
      this.service
        .saveContent(params)
        .then((res) => {
          if (res && res.data.code === 0 && res.data.data) {
            resolve('success');
          } else {
            reject(new Error('fail'));
          }
        })
        .catch(() => {
          reject(new Error('fail'));
        });
    });
  };

  @action
  publish = (docId: string) => {
    return new Promise((resolve, reject) => {
      this.service
        .changeContentsStatus(docId, 'publish', '')
        .then((res) => {
          if (res && res.data.code === 0 && res.data.data) {
            resolve('success');
          } else {
            reject(new Error('fail'));
          }
        })
        .catch(() => {
          reject(new Error('fail'));
        });
    });
  };

  @action
  setShowGuide = () => {
    if (
      document.cookie.split(';').filter((item) => item.includes('lsg_doctor_guide_show=')).length
    ) {
      console.log('The cookie "lsg_doctor_guide_show" exists');
    } else {
      this.showGuide = 1;
      document.cookie = 'lsg_doctor_guide_show=1';
    }
  };

  @action
  nextGuideStep = () => {
    if (this.showGuide > 5) {
      this.showGuide = 0;
    } else {
      this.showGuide = this.showGuide + 1;
    }
  };
}

export default VoiceRecorderStore;
