import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { action, computed } from 'mobx';
import styles from './index.module.css';
import addIcon from '../../static/images/add.png';

interface MultiInputProps {
  name: string;
  type: string;
  values: string[];
  title: string;
  placeholder: string;
  onChange(arg0: string[], arg1: string): void;
  // limit?: number;
}

@observer
class MultiInput extends Component<MultiInputProps> {
  @computed
  get localRefs() {
    return this.props.values.map(() => {
      return React.createRef<HTMLInputElement>();
    });
  }

  @action
  handleInput = (event) => {
    const { value, name } = event.target;
    const index = parseInt(name, 10);
    const newValue = this.props.values.slice();
    newValue[index] = value;
    this.props.onChange(newValue.slice(), this.props.name);
  };

  @action
  add = () => {
    const currentLength = this.props.values.length;
    if (this.props.values[currentLength - 1] || currentLength === 0) {
      const newValues = this.props.values.slice();
      newValues.push('');
      this.props.onChange(newValues.slice(), this.props.name);
    }
    // this.localRefs[0].current.focus();
    // if (currentLength > 1 && this.localRefs[currentLength - 1] !== null) {
    //   this.localRefs[currentLength - 1].current.focus();
    // }
  };

  @action
  handleClear = (event) => {
    const { index } = event.target.dataset;
    if (index === undefined) {
      return;
    }
    const newValues = this.props.values.slice();
    newValues.splice(index, 1);
    if (newValues.length === 0) {
      newValues.push('');
    }
    this.props.onChange(newValues.slice(), this.props.name);
  };

  handleBlur = () => {
    // blur时滚动回顶部，防止ios键盘收起后webview底部高度多一截
    setTimeout(() => {
      window.scrollTo(0, 0);
      // document.body.scrollTop = oldScroll;
    }, 50);
  };

  render() {
    const { title, placeholder, values } = this.props;
    return (
      <div className={styles.multiInput}>
        <div className={styles.head}>
          <div className={styles.title}>{title}</div>
        </div>
        <div className={styles.inputGroup}>
          {values.map((value, index) => {
            const inputPlaceHolder = `第${index + 1}${placeholder}`;
            return (
              <div key={inputPlaceHolder} className={styles.wrapper}>
                {/* <input
                  type="text"
                  name={`${index}`}
                  className={styles.input}
                  value={value}
                  placeholder={inputPlaceHolder}
                  onChange={this.handleInput}
                  onBlur={this.handleBlur}
                  ref={this.localRefs[index]}
                /> */}
                <textarea
                  name={`${index}`}
                  className={styles.input}
                  value={value}
                  placeholder={inputPlaceHolder}
                  onChange={this.handleInput}
                />
                <div className={styles.forclose} onClick={this.handleClear}>
                  <div data-index={index} className={styles.clear} />
                </div>
              </div>
            );
          })}

          <div className={styles.action} onClick={this.add}>
            <img src={addIcon} alt="新增" className={styles.action_icon} />
            <span>新增{placeholder}</span>
          </div>
        </div>
      </div>
    );
  }
}

export default MultiInput;
