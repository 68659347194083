import React, { Component } from 'react';
import { observable } from 'mobx';
import classNames from 'classnames/bind';
import { observer } from 'mobx-react';
import DoctorInfoService, { Score } from '@/services/doctorInfoService';
// import signInAgreement from '@/services/signInAgreement';
import styles from './index.module.css';

const cx = classNames.bind(styles);

@observer
class ScorePage extends Component {
  service = new DoctorInfoService();

  @observable
  score?: Score;

  UNSAFE_componentWillMount(){
    //window.TEA('主页', 'integral');
  }
  
  componentDidMount() {
    this.service.getDoctorScore().then((score) => {
      this.score = score;
    });
  }

  render() {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();

    if (this.score) {
      const { score, doc_add_score, doc_release_score } = this.score;
      return (
        <div className={`${styles.score} page`}>
          <div className={styles.date}>
            截至{year}年{month}月{day}日，您的积分如下：
          </div>
          <div className={styles.scoreBoard}>
            <div className={styles.scoreItem}>
              <div className={styles.scoreItemName}>个人信息</div>
              <div className={styles.scoreItemNumber}>
                {score}
                <span className={styles.suffix}>积分</span>
              </div>
            </div>
            <div className={styles.scoreItem}>
              <div className={styles.scoreItemName}>发布内容</div>
              <div className={styles.scoreItemNumber}>
                {doc_release_score}
                <span className={styles.suffix}>积分</span>
              </div>
            </div>
            <div className={styles.scoreItem}>
              <div className={styles.scoreItemName}>新建内容</div>
              <div className={styles.scoreItemNumber}>
                {doc_add_score}
                <span className={styles.suffix}>积分</span>
              </div>
            </div>
          </div>
          <div className={styles.block}>
            <div className={styles.blockTitle}>积分说明</div>
            <div className={styles.blockText}>
              为了更好地整合科普资源，更全面地发挥医生专业产出内容的作用，鼓舞科普创作工作者，医者名片实行【积分机制】，帮助扩大医生科普影响力，触达更多受众人群，打造科普明星。
            </div>
          </div>
          <div className={styles.block}>
            <div className={styles.blockTitle}>积分计算规则</div>
            <div
              className={cx({
                table: true,
                verticalTable: true,
                flex: true
              })}
            >
              <div
                className={cx({
                  tableHead: true,
                  verticalTableHead: true
                })}
              >
                个<br />人<br />信<br />息
              </div>
              <div className={styles.tableBody}>
                <div
                  className={cx({
                    tableRow: true,
                    flex: true
                  })}
                >
                  <div className={styles.tableCell}>
                    <span>基本信息</span>
                  </div>
                  <div className={styles.tableCell}>单条</div>
                  <div className={styles.tableCell}>3分</div>
                </div>
                <div
                  className={cx({
                    tableRow: true,
                    flex: true
                  })}
                >
                  <div className={styles.tableCell}>
                    <span>其他信息</span>
                  </div>
                  <div className={styles.tableCell}>单条</div>
                  <div className={styles.tableCell}>5分</div>
                </div>
              </div>
            </div>
            <div
              className={cx({
                table: true,
                verticalTable: true,
                flex: true
              })}
            >
              <div
                className={cx({
                  tableHead: true,
                  verticalTableHead: true
                })}
              >
                科<br />普<br />内<br />容
              </div>
              <div className={styles.tableBody}>
                <div
                  className={cx({
                    tableRow: true,
                    flex: true
                  })}
                >
                  <div className={styles.tableCell}>
                    <span>
                      原有内容
                      <br />
                      （发布并通过）
                    </span>
                  </div>
                  <div
                    className={cx({
                      tableCell: true,
                      twoFlex: true,
                      flexColumn: true
                    })}
                  >
                    <div
                      className={cx({
                        tableRow: true,
                        flex: true
                      })}
                    >
                      <div className={styles.tableCell}>问答/图文/音频/视频</div>
                      <div className={styles.tableCell}>5分/条</div>
                    </div>
                  </div>
                </div>
                <div
                  className={cx({
                    tableRow: true,
                    flex: true
                  })}
                >
                  <div className={styles.tableCell}>
                    <span>
                      新建内容
                      <br />
                      （发布并通过）
                    </span>
                  </div>
                  <div
                    className={cx({
                      tableCell: true,
                      twoFlex: true,
                      flexColumn: true
                    })}
                  >
                    <div
                      className={cx({
                        tableRow: true,
                        flex: true
                      })}
                    >
                      <div className={styles.tableCell}>问答</div>
                      <div className={styles.tableCell}>10分/条</div>
                    </div>
                    <div
                      className={cx({
                        tableRow: true,
                        flex: true
                      })}
                    >
                      <div className={styles.tableCell}>图文</div>
                      <div className={styles.tableCell}>10分/条</div>
                    </div>
                    <div
                      className={cx({
                        tableRow: true,
                        flex: true
                      })}
                    >
                      <div className={styles.tableCell}>音频</div>
                      <div className={styles.tableCell}>30分/条</div>
                    </div>
                    <div
                      className={cx({
                        tableRow: true,
                        flex: true
                      })}
                    >
                      <div className={styles.tableCell}>视频</div>
                      <div className={styles.tableCell}>50分/条</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.block}>
            <div className={styles.blockTitle}>积分统计规则</div>
            <div
              className={cx({
                table: true,
                flex: true,
                flexColumn: true
              })}
            >
              <div
                className={cx({
                  tableHead: true
                })}
              >
                <div
                  className={cx({
                    tableRow: true,
                    flex: true
                  })}
                >
                  <div className={styles.tableCell}>LV1</div>
                  <div className={styles.tableCell}>LV2</div>
                  <div className={styles.tableCell}>LV3</div>
                </div>
              </div>
              <div
                className={cx({
                  tableBody: true,
                  borderTop: true
                })}
              >
                <div
                  className={cx({
                    tableRow: true,
                    flex: true
                  })}
                >
                  <div className={styles.tableCell}>0-299</div>
                  <div className={styles.tableCell}>300-999</div>
                  <div className={styles.tableCell}>1000-3999</div>
                </div>
              </div>
            </div>
            <div
              className={cx({
                table: true,
                flex: true,
                flexColumn: true
              })}
            >
              <div
                className={cx({
                  tableHead: true
                })}
              >
                <div
                  className={cx({
                    tableRow: true,
                    flex: true
                  })}
                >
                  <div className={styles.tableCell}>LV4</div>
                  <div className={styles.tableCell}>LV5</div>
                  <div className={styles.tableCell}>LV6</div>
                </div>
              </div>
              <div
                className={cx({
                  tableBody: true,
                  borderTop: true
                })}
              >
                <div
                  className={cx({
                    tableRow: true,
                    flex: true
                  })}
                >
                  <div className={styles.tableCell}>4000-6999</div>
                  <div className={styles.tableCell}>7000-9999</div>
                  <div className={styles.tableCell}>10000+</div>
                </div>
              </div>
            </div>
            <div className={styles.blockText}>
              医者名片等级由<strong>用户信息的完整度</strong>
              ，用户积累的<strong>发文量</strong>，<strong>创作量</strong>
              等综合决定，您只需通过手机访问医者名片，登录个人名片，完善个人信息，上传或发布科普内容（问答、文章、语音科普等），即可累积对应的积分以获取相应的等级。
            </div>
          </div>
          <div className={styles.block}>
            <div className={styles.blockTitle}>积分使用</div>
            <div className={styles.blockText}>
              ·定期发布科普之星名单，可在健康中国APP等平台滚动展示；
            </div>
            <div className={styles.blockText}>
              ·优先参与平台开展多项精彩活动，如优秀科普达人评选等；
            </div>
            <div className={styles.blockText}>·将邀请参加“大咖”云集会议，进行面对面学术交流。</div>
          </div>
          <div className={styles.end}>
            升等级，创作更多科普内容，增加科普内容曝光率，将会有大批特权来袭，等级越高可尊享更多特权哦，敬请期待。
          </div>
        </div>
      );
    }
    return null;
  }
}
export default ScorePage;
