import React, { Component } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import classNames from 'classnames/bind';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import Doctor from '@/types/doctor';
import PreviewStore from '@/stores/previewStore';
import DoctorInfoService from '@/services/doctorInfoService';
import VideoPlay from '../../static/images/play.png';
import styles from './index.module.css';
import initWechatConfig from "@/utils/wxShare";

const cx = classNames.bind(styles);

interface RouteParams {
  id: string;
  cate: string;
}

@observer
class Preview extends Component<RouteComponentProps<RouteParams>> {
  store: PreviewStore;
  domEvents: any;

  @observable
  doctor: Doctor | undefined;

  service: DoctorInfoService;

  constructor(props) {
    super(props);
    const { id, cate } = this.props.match.params;
    console.log(id);
    this.store = new PreviewStore(id, cate);
    this.service = new DoctorInfoService();
    this.domEvents = this.getDomEvents();
  }

  componentDidMount() {
    document.title = '预览';
    try {
      initWechatConfig();
    } catch (error) {
      console.error('初始化微信配置错误', error);
    }
  }

  componentDidUpdate(prevProps) {
    const navigated = prevProps.location !== this.props.location;

    if (navigated) {
      const { id, cate } = this.props.match.params;
      this.store.update(id, cate);
      console.log(id, cate);
      // load data while the old screen remains
    }
  }

  getDomEvents() {
    return {
      goDetail: () => {
        const { id, cate, } = this.store;
        //window.TEA('预览', 'editors');
        let pathName = `/content/editor/${cate}/${id}`;
        if (cate === 'video') {
          pathName = `/video/${id}`;
        }
        this.props.history.push(pathName);
      }
    }
  }

  startvideo = () => {
    const tt = document.getElementById('play_video');
  };

  render() {
    const { domEvents } = this;
    const {
      id,
      loaded,
      source,
      doctor,
      department,
      hospital,
      sourceUrl,
      updateTime,
      cate,
      next_doc,
      state,
      title,
      voice,
      video,
      question,
      content,
      play_flag,
      playVedio,
      closeDialog,
      fail_reason,
      publish,
      block,
      cancelBlock
    } = this.store;
    const sub: string[] = [];
    if (doctor && doctor.grade) {
      sub.push(doctor.grade);
    }
    if (department && department.name && department.name !== '' && department.name !== ' ') {
      sub.push(department.name);
    }
    const subStr = sub.join('/');
    const htmlContent = {
      __html: content || ''
    };
    if (loaded) {
      return (
        <div className={styles.preview}>
          {fail_reason && (
            <div className={styles.dialogWrapper}>
              <div className={styles.dialogBox}>
                <div className={styles.dialogClose} onClick={closeDialog} />
                <div className={styles.dialogTitle}>审核不通过原因</div>
                <div className={styles.dialogContent}>{fail_reason}</div>
                {cate === 'voice' && (
                  <a className={styles.dialogBtn} href={`/voice-recorder/${id}?recreate=1`}>
                    重新录制
                  </a>
                )}
              </div>
            </div>
          )}
          <div className={styles.head}>
            <div className={styles.title}>{title}</div>
            {cate === 'ask' && question && <div className={styles.question}>{question}</div>}
          </div>
          <div className={styles.main}>
            {doctor && (
              <div className={styles.doctor}>
                {doctor.headimg_url && (
                  <div className={styles.avatarContainer}>
                    <img className={styles.avatar} alt="" src={doctor.headimg_url} />
                  </div>
                )}
                <div className={styles.doctorInfo}>
                  <div className={styles.doctorTitle}>
                    <span className={styles.doctorName}>{doctor.name}</span>
                    <span className={styles.doctorSub}>（{subStr}）</span>
                  </div>
                  {hospital && <div className={styles.doctorHospital}>{hospital.name}</div>}
                </div>
              </div>
            )}
            {cate !== 'voice' && cate !== 'video' && <div className={styles.divider} />}
            {cate === 'voice' && voice && voice.src && (
              <div className={styles.audioPlayer}>
                <audio src={voice.src} controls />
              </div>
            )}
            {cate === 'video' && video && video.src && (
              <div className={styles.videoContainer}>
                <video
                  src={video.src}
                  poster={`${video.poster}`}
                  className={styles.videoPlayer}
                  playsInline
                  controls
                />
              </div>
            )}

            {(cate === 'voice' || cate === 'video') && (
              <div className={styles.contentTitle}>
                {cate === 'voice' ? '音频' : '视频'}文字摘要
              </div>
            )}
            <div className={styles.contentContainer} dangerouslySetInnerHTML={htmlContent} />
            <div className={styles.bottomInfo}>
              <div className={styles.time}>时间：{updateTime}</div>
              {source && source !== 'OwnResource' && (
                <a href={sourceUrl}>
                  <div className={styles.source}>来源：{source}</div>
                </a>
              )}
            </div>
          </div>
          {state === 2 && (
            <div className={styles.actionGroup}>
              <div className={cx('actionButton', 'diabledButton')}>审核中</div>
            </div>
          )}
          {state !== 2 && (
            <div className={styles.actionGroup}>
              {/* {state === 7 ? (
                <div className={styles.actionButton} onClick={cancelBlock}>
                  取消屏蔽
                </div>
              ) : (
                <div className={styles.actionButton} onClick={block}>
                  屏蔽
                </div>
              )} */}
              {(state === 1) && (
                <div className={styles.actionButton} onClick={publish}>
                  提交审核
                </div>
              )}
              {((cate !== 'voice' && cate !== 'video')
                || (cate === 'video' && (state == 1 || state == 4)))
                && state !== 3 // 草稿、駁回
                ? (
                  <span onClick={domEvents.goDetail} className={styles.actionButton}>
                    编辑
                  </span>
                ) : null}
            </div>
          )}
          <Link onClick={() => {
            //window.TEA('预览', 'return_to_the_list');
          }} className={cx('prev', 'jumpPill')} to="/content/manage">
            <span className={cx('arrow')} />
            <span className={cx('arrow')} />
            返回列表
          </Link>
          {next_doc && (
            <Link onClick={() => {
              //window.TEA('预览', 'the_next_one');
            }} className={cx('next', 'jumpPill')} to={`/preview${next_doc}`}>
              下一篇
              <span className={cx('arrow')} />
            </Link>
          )}
        </div>
      );
    }
    return null;
  }
}

export default Preview;
