import { observable, action, toJS } from 'mobx';
import message from '../components/message';
import VideoService from '../services/videoService';
import ContentService from '../services/contentsService';

interface VideoUrl {
  CoverUrl: string;
  // video_img: string;
  VideoID: string;
  MainPlayUrl: string;
  BackupPlayUrl: string;
}

interface VideoInfo {
  name: string;
  CoverUrl: string;
  BackupPlayUrl: string;
  MainPlayUrl: string;
  VideoID: string;
}


class VideoStore {
  service: VideoService;
  contentService: ContentService;

  constructor(docId: string) {
    this.service = new VideoService();
    this.contentService = new ContentService();
    this.docId = docId;
    if (docId) {
      this.contentService.getVideoContent(docId).then((data: any) => {
        this.uplaodState = 2;
        console.log('视频信息～～，', data);
        
        this.videoInfo = {
          VideoID: data.video_id,
          MainPlayUrl: data.video_url,
          BackupPlayUrl: data.video_url,
          CoverUrl: data.thumbnail,
          name: data.title,
        };
        if (toJS(this.title) === '') {
          this.title = data.title;
        }
      });
    }
  }
  // 视频播放地址
  videoUrl: string = '';

  // 上传后的数据
  @observable
  videoInfo: VideoInfo | undefined;

  // 上传的进度条
  @observable
  uploadLen = '0';

  // 上传的状态  0待上传 1上传中 2上传完成 3开始播放
  @observable
  uplaodState = 0;

  // 视频title
  @observable
  title = '';

  // 视频ID
  @observable
  docId = '';

  // 修改视频title
  changeTitle = (txt: string) => {
    this.title = txt;
  };

  // 视频删除
  @action
  delVideo = () => {
    this.uplaodState = 0;
    this.videoInfo = undefined;
  };

  // 播放视频
  @action
  playVideo = () => {
    this.uplaodState = 3;
  };

  // 存草稿
  @action
  addVideo = () => {
    if (!this.title) {
      message.info('请输入标题');
      return;
    }
    console.log('this.videoInfo', this.videoInfo);
    
    const video_id = this.videoInfo ? this.videoInfo.VideoID : '';
    const video_img = this.videoInfo ? this.videoInfo.CoverUrl: '';
    if (video_id === '') {
      message.info('请上传视频文件');
      return;
    }
    if (this.docId) {
      this.contentService.saveContent({
        cate: 'video',
        uid: this.docId,
        title: this.title,
        video_img,
        video_id,
      }).then(() => {
        window.location.replace('/content/manage');
      });
    } else {
      this.service.addVideoArticle(video_img, video_id, this.title).then((res) => {
        console.log('草稿保存成功', res);
        message.info('草稿保存成功', 1.5, () => {
          window.location.replace('/content/manage');
        });
      });
    }
  };

  //
  @action
  addAudit = () => {
    if (!this.title) {
      message.info('请输入标题');
      return;
    }
    const url = this.videoInfo ? this.videoInfo : '';
    if (url === '') {
      message.info('请上传视频文件');
      return;
    }
    const video_img = this.videoInfo ? this.videoInfo?.CoverUrl : '';
    const video_id = this.videoInfo ? this.videoInfo?.VideoID : '';
    this.service.addVideoArticle(video_img, video_id, this.title).then((res) => {
      this.service.changeContentsStatus(res?.data?.data?.data, 'publish').then(() => {
        message.info('已提交审核，审核通过后发布', 1.5, () => {
          window.location.replace('/content/manage');
        });
      });
    });
  };

  @action
  upload(formData: FormData, ext: string) {
    this.uplaodState = 1;

    let num = 10;
    const timer = setInterval(() => {
      num += 1;
      if (num > 90) {
        num = 90;
      }
      this.uploadLen = num.toString();
    }, 1500);
    this.service
      .uploadVideo(formData, ext)
      .then((res) => {
        clearInterval(timer);
        console.log('视频上传----', res);
        if (res.data && res.data.code === 200 && res.data?.data) {
          const videoUrl = res.data?.data?.play_url;
          const videoID = res.data?.data?.video_id || '';
          const videoCover = res.data?.data?.thumbnail || '';
          this.videoInfo = {
            name: '',
            CoverUrl: videoCover,
            BackupPlayUrl: videoUrl,
            MainPlayUrl: videoUrl,
            VideoID: videoID,
          };
          this.uploadLen = '100';
          this.uplaodState = 2;
          // console.log('视频文件---', this.videoInfo);
          // if (toJS(this.title) === '') {
          //   this.title = tmp.VideoID;
          // }
        }
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }
}

export default VideoStore;
