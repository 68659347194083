import React, { Component } from 'react';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import classNames from 'classnames/bind';

import styles from './remindCard.module.css';

const cx = classNames.bind(styles);

interface RemindCardProps {
  className?: string;
  index: number;
  title: string;
  content: string;
}

@observer
class RemindCard extends Component<RemindCardProps> {
  @observable
  fold = true;

  @action
  handleToggleClick = () => {
    this.fold = !this.fold;
  };

  render() {
    const { index, title, content, className } = this.props;
    return (
      <div
        className={cx('card', className, {
          fold: this.fold
        })}
      >
        <div className={styles.cardHead}>
          <div className={styles.cardIndex}>样例参考{index + 1}</div>
          <div className={styles.cardTitle}>{title}</div>
        </div>
        <div className={styles.cardBody}>{content}</div>
        <div className={styles.toggle} onClick={this.handleToggleClick}>
          <span>{this.fold ? '展开' : '收起'}全部内容</span>
          <span className={cx('arrow', { up: !this.fold })} />
        </div>
      </div>
    );
  }
}

export default RemindCard;
