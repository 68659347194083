import React, { Component } from 'react';
// import classNames from 'classnames/bind';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import message from '@/components/message';
import { ItemData } from '@/services/contentsService';

import ContentItem from './contentItem';

import styles from './page.module.css';

// const cx = classNames.bind(styles);

interface ContentPageProps {
  pageIndex: number;
  pageData: ItemData[];
  selectedRows: number[];
  onSelectChange(arg0: number, arg1: boolean, agr2: number): void;
}

@observer
class ContentPage extends Component<ContentPageProps> {
  @observable
  listLength: number;

  constructor(props) {
    super(props);
    this.listLength = this.props.pageData.length;
  }

  handleItemSelectChange = (itemIndex: number, checked: boolean) => {
    const { onSelectChange, pageIndex } = this.props;
    onSelectChange(itemIndex, checked, pageIndex);
  };

  handleClickDisabled = (state) => {
    const text = state === 2 ? '审核过程中不可操作' : '审核未通过，请重新编辑发布';
    message.info(text);
  };

  render() {
    const { pageData, selectedRows } = this.props;
    return (
      <div className={styles.layout}>
        {pageData &&
          pageData.length > 0 &&
          pageData.map((itemData, index) => {
            const keyStr = `${index}-item`;
            const itemSelected = selectedRows.indexOf(index) > -1;
            return (
              <div className={styles.item} key={keyStr}>
                <ContentItem
                  selected={itemSelected}
                  data={itemData}
                  index={index}
                  onSelect={this.handleItemSelectChange}
                  onClickDisabled={this.handleClickDisabled}
                />
              </div>
            );
          })}
      </div>
    );
  }
}

export default ContentPage;
