import React, { Component } from 'react';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router-dom';
import ReactQuill from 'react-quill';
import { List, InputItem } from 'antd-mobile';
import { Menu, ActivityIndicator, NavBar } from 'antd-mobile';
import DoctorInfoService from '@/services/doctorInfoService';
import { createForm } from 'rc-form';
import classNames from 'classnames/bind';
import EditorStore from '@/stores/editorStore';
import ContentsService from '../../services/contentsService';
// import Modal from '@/components/modal';
// import message from '@/components/message';
import Dialog from '@/components/dialog';
import message from '@/components/message';
import 'react-quill/dist/quill.snow.css';
import styles from './editor.module.css';
import './editorCommon.css';
import ActivityStore from '@/stores/activityStore';
import ActivityService from '@/services/activityService';
import Selector from './components/selector';
import Login from '../login';
const cx = classNames.bind(styles);
interface RouteParams {
  id?: string;
  cate: string;
}

interface TextareaProps {
  name?: string;
  title: string;
  value: string;
  info?: string;
  required?: boolean;
  placeholder?: string;
  maxLength?: number;
  disable?: boolean;
  onChange(value: string): void;
  onBlur(): void;
}
const Textarea: React.FC<TextareaProps> = ({
  name,
  title,
  value,
  info,
  required,
  placeholder,
  disable,
  maxLength = 500,
  onChange,
  onBlur
}) => (
  <div className={styles.textareaContainer}>
    <div className={styles.head}>
      <div
        className={cx({
          title: true,
          required
        })}
      >
        {title}
        {info && <span className={styles.textAreaInfo}>{info}</span>}
      </div>
      <div className={styles.maxHint}>{`最多输入${maxLength}字`}</div>
    </div>
    <div className={styles.textareaWrapper}>
      <textarea
        className={styles.textarea}
        name={name}
        id={name}
        value={value}
        placeholder={placeholder}
        maxLength={maxLength}
        disabled={disable}
        onChange={(event) => {
          console.log(event.target.value);
          onChange(event.target.value);
        }}
        onBlur={onBlur}
      />
    </div>
  </div>
);

@observer
class ContentEditor extends Component<any, any> {
  // service: DoctorInfoService;
  store: EditorStore;
  form: any;
  paramStore: any;
  contentService: any;
  activityStore: ActivityStore;

  activityService: ActivityService;
  autoFocusInst: any;
  @observable
  checked = false;

  quillRef: any;
  service: any;
  reactQuillRef: any;

  quillEditorModules = {
    clipboard: {
      matchVisual: false
    },
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['image', 'clean']
    ]
  };

  quillEditorFormats = ['bold', 'italic', 'underline', 'list', 'bullet', 'image'];

  constructor(props) {
    super(props);
    const { id, cate } = this.props.match.params;
    if (cate === 'jkb_article') {
      this.paramStore = this.props.location.state;
    }
    this.state = {
      cate: cate,
      placeholderInfo: '请输入正文内容',
      initData: {},
      //initdata = currentdata
      tplList: [],
      template_id: 0,
      id: id
    };
    console.log(id, cate, this.paramStore);
    this.activityService = new ActivityService();
    this.activityStore = new ActivityStore(this.activityService);
    this.store = new EditorStore(id, cate); // 传入 id 和 cate（文章类型） 
    this.service = new DoctorInfoService();
    this.quillRef = null; // Quill instance
    this.reactQuillRef = React.createRef(); // ReactQuill component
    this.contentService = new ContentsService();
  }

  // TODO 增长分析接入
  // UNSAFE_componentWillMount() {
  //   window.TEA('主页', 'create_content');
  // }

  componentDidMount() {
    const { cate } = this.state;
    switch (cate) {
      case 'jkb_note':
        document.title = '新建医生笔记';
        break;
      case 'sc_topic':
        document.title = '新建科普议题';
        break;
      default:
        document.title = '新建图文';
        break;
    }
    this.attachQuillRefs();
    if (cate === 'sc_topic') {
      // 如果不存在ID 请求议题模板列表 并初始化为第一个模板内容和标题
      if (!this.state.id) {
        this.contentService.getDocTemplate().then((res) => {
          const tplList: any = [];
          if (res.template_list === null || res.template_list.length === 0) {
            message.info('暂无新议题', 1);
          } else {
            res.template_list.forEach((item) => {
              let tmp = {
                label: item.title,
                value: item.template_id,
                content: item.content,
                word_cnt: item.word_cnt
              };
              tplList.push(tmp);
            });
            this.setState(
              {
                tplList
              },
              () => {
                this.setState({
                  initData: this.state.tplList[0],
                  template_id: this.state.tplList[0].value
                });
                if (!this.state.id) {
                  this.quillRef.setText(this.state.tplList[0].content);
                  this.store.changeTitle(this.state.tplList[0].label);
                }
              }
            );
          }
        });
      }
    }
    if (cate === 'jkb_note') {
      this.setState({
        placeholderInfo:
          '以故事的形式:\n1.记录诊疗过程\n2.记录出诊的一次经历\n3.记录有所感触的病例分析…'
      });
    }
  }

  componentDidUpdate() {
    this.attachQuillRefs();
  }

  componentWillUnmount() {
    message.destroy();
  }

  attachQuillRefs = () => {
    if (!this.reactQuillRef.current) return;
    if (typeof this.reactQuillRef.current.getEditor !== 'function') return;
    this.quillRef = this.reactQuillRef.current.getEditor();
    const toolbar = this.quillRef.getModule('toolbar');
    toolbar.container.addEventListener('mousedown', (event) => {
      // 防止点击toolbar时blur，导致文本输入后blur高度不能正常恢复
      event.preventDefault();
    });
    toolbar.addHandler('image', this.imageHandler);
  };

  imageHandler = () => {
    const input = document.createElement('input');
    const { uploadImage } = this.store;
    input.setAttribute('style', 'display:none');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    // document.body.append 在windows 微信内置浏览器内 不存在
    document.body.appendChild(input);
    input.click();
    input.addEventListener('change', () => {
      // eslint-disable-next-line
      if (!input.files) {
        return;
      }
      const file = input.files[0];
      const formData = new FormData();

      formData.append('file', file);

      // Save current cursor state
      const range = this.quillRef.getSelection(true);

      // Insert temporary loading placeholder image
      const hide = message.loading('上传图片中', 0);
      // this.quillRef.insertEmbed(range.index, 'image', imagePlaceHolder);

      uploadImage(formData)
        .then((url: any) => {
          console.log('---图片---：', url);
          this.quillRef.insertEmbed(range.index, 'image', url);
          // Move cursor to right side of image (easier to continue typing)
          this.quillRef.setSelection(range.index + 1);
          hide();
          input.remove();
        })
        .catch((err) => {
          console.log('err', err);
          message.info('上传失败，请稍后重试');
          input.remove();
        });
    });
  };

  @action
  onTitleChange = (value) => {
    this.store.changeTitle(value);
  };

  @action
  onQuestionChange = (value) => {
    this.store.changeQuestion(value);
  };

  @action
  onContentChange = (value: string) => {
    this.store.changeContent(value);
  };

  handleBlur = () => {
    // blur时滚动回顶部，防止ios键盘收起后webview底部高度多一截
    // setTimeout(() => {
    //   window.scrollTo(0, 0);
    // }, 50);
  };

  // 预览
  @action
  onPreview = () => {
    const editTxt = this.quillRef.getText();
    if (editTxt === '') {
      message.info('请填写正文内容');
      return;
    }

    const {
      check,
      create,
      createContentPrev,
      saveContentPrev,
      showDialog,
      closeDialog,
      state
    } = this.store;

    const checkResult = check();
    if (checkResult !== '') {
      message.info(checkResult);
      return;
    }

    if (create) {
      message.loading('保存中', 0);
      let template_id = this.state.template_id;
      if (this.state.cate !== 'sc_topic') {
        template_id = undefined;
      }

      createContentPrev(template_id)
        .then((res) => {
          console.log('debug', res);
        })
        .catch((err) => {
          console.log('debug2', err);
          message.info('保存失败');
        });
    } else if (state === 4) {
      // 已发布的需要二次确认
      showDialog('save').then(() => {
        closeDialog();
        saveContentPrev()
          .then(() => {})
          .catch(() => {
            message.info('保存失败');
          });
      });
    } else {
      // 非审核中
      saveContentPrev()
        .then(() => {})
        .catch(() => {
          message.info('保存失败');
        });
    }
  };
  @action
  getCurTopic = (cur) => {
    console.log(cur);
  };
  @action
  onSaveClick = () => {
    const editTxt = this.quillRef.getText();
    if (editTxt === '') {
      message.info('请填写正文内容');
      return;
    }
    const {
      check,
      create,
      createContent,
      saveContent,
      showDialog,
      closeDialog,
      state
    } = this.store;
    const checkResult = check();
    if (checkResult !== '') {
      message.info(checkResult);
      return;
    }
    console.log(this.store);

    if (create) {
      console.log('创建保存草稿');
      message.loading('保存中', 0);
      
      // 模板 只有在新建议题时使用， 作用是传给后端进行删除。（单个模板只能保存一次）
      // let template_id = this.state.template_id;
      // if (this.state.cate !== 'sc_topic') {
      //   template_id = undefined;
      // }
      createContent(1, editTxt)
        .then((res) => {
          console.log('debug', res);
          message.success('保存成功', 1.5, () => {
            window.location.replace('/content/manage');
          });
        })
        .catch((err) => {
          console.log('debug2', err);
          message.info('保存失败');
        });
    } else if (state === 4) {
      console.log('已发布的需要二次确认');
      // 已发布的需要二次确认
      showDialog('save').then(() => {
        closeDialog();
        message.loading('保存中', 0);
        saveContent(editTxt)
          .then(() => {
            message.success('保存成功', 1.5, () => {
              window.location.replace('/content/manage');
            });
          })
          .catch((err) => {
            console.log('debug3', err);
            message.info('保存失败');
          });
      });
    } else {
      // 非审核中 保存草稿
      saveContent(editTxt)
        .then(() => {
          console.log('非审核中 保存草稿');
          message.success('保存成功', 1.5, () => {
            window.location.replace('/content/manage');
          });
        })
        .catch((err) => {
          console.log('debug4', err);
          message.info('保存失败');
        });
    }
  };

  publicScienceArticle = () => {};

  @action
  onPublishClick = () => {
    console.log('change', this.quillRef.getText());
    const editTxt = this.quillRef.getText();
    if (editTxt === '') {
      message.info('请填写正文内容');
      return;
    }
    const {
      check,
      create,
      createContent,
      saveContent,
      showDialog,
      closeDialog,
      state,
      publish,
      cate
    } = this.store;
    const checkResult = check();
    if (checkResult !== '') {
      message.info(checkResult);
      return;
    }
    if (create) {
      // 新建内容
      if (cate !== 'jkb_article') {
        message.loading('预发布中', 0);
      }
      // let template_id = undefined;
      // if (this.state.cate === 'sc_topic') {
      //   template_id = this.state.template_id;
      // }
      createContent(1, editTxt)
        .then(async (data) => {
          console.log('新建图文', data);
          const doc_id = data;
          if (cate === 'jkb_article') {
            const { activity, activity_id, activity_content_id, career_photo } = this.paramStore;
            // const activity = [];
            let param = {
              activity_id,
              activity_content_id,
              career_photo,
              type: 2,
              article_id: doc_id
            };
            // @ts-ignore
            activity.push(param);
            if (!(await this.activityStore.publicArticle(doc_id))) {
              message.error('文章提交失败！');
              return;
            }
            let res = await this.activityStore.participateActivity({
              activity
            });
            if (res) {
              message.success('报名成功').then(
                () => {
                  this.props.history.push('/activity');
                },
                () => {}
              );
            } else {
              message.error('报名失败，请稍后重试！');
            }
          } else {
            const url = `${window.location.href}/${this.store.id}`;
            window.history.replaceState({}, '', url);
            publish(doc_id)
              .then((res) => {
                console.log(res);

                message.info('预发布成功，审核通过后，可在「已发布」列表中查看').then(
                  () => {
                    window.location.replace('/content/manage');
                  },
                  () => {
                    window.location.replace('/content/manage');
                  }
                );
              })
              .catch((err) => {
                console.log('===i', err);
                message.info('预发布失败');
              });
          }
        })
        .catch(() => {
          message.info('预发布失败');
        });
    } else if (state === 4) {
      // 已发布的需要二次确认
      showDialog('publish').then(() => {
        closeDialog();
        message.loading('预发布中', 0);
        saveContent(editTxt)
          .then(() => {
            publish('')
              .then(() => {
                message.info('预发布成功，审核通过后，可在「已发布」列表中查看').then(
                  () => {
                    window.location.replace('/content/manage');
                  },
                  () => {
                    window.location.replace('/content/manage');
                  }
                );
              })
              .catch(() => {
                message.info('预发布失败');
              });
          })
          .catch(() => {
            message.info('保存失败');
          });
      });
    } else {
      saveContent(editTxt)
        .then(() => {
          publish('')
            .then(() => {
              message.info('预发布成功，审核通过后，可在「已发布」列表中查看');
              window.location.replace('/content/manage');
            })
            .catch(() => {
              message.info('预发布失败3');
            });
        })
        .catch(() => {
          message.info('保存失败');
        });
    }
  };

  onDialogConfirm = () => {};

  onDialogClose = () => {
    this.store.closeDialog();
  };
  // 新建议题 切换模版 内容赋值
  getChildrenMsg = (topic) => {
    let cur;
    this.state.tplList.forEach((el) => {
      if (el.value === topic[0]) {
        cur = el;
        this.quillRef.setText(el.content);
        this.store.changeTitle(el.label);
      }
    });
    this.setState({
      template_id: topic[0],
      initData: cur
    });
  };
  render() {
    const {
      loaded,
      source,
      sourceUrl,
      updateTime,
      cate,
      state,
      topHint,
      title,
      video,
      file,
      question,
      content,
      dialog,
      fail_reason
    } = this.store;
    const { tplList, placeholderInfo, initData } = this.state;

    const labelArr = ['待发布', '未通过', '审核中', '已发布', '已屏蔽'];
    const label = labelArr[state - 1] || '';

    const { getFieldProps } = this.props.form;
    if (loaded) {
      return (
        <div className={styles.layout}>
          <Dialog
            visible={dialog.open}
            className={styles.dialog}
            onOk={dialog.onConfirm}
            onCancel={this.onDialogClose}
            title={dialog.title}
            okText={dialog.confirmText}
          >
            <div>{dialog.message}</div>
          </Dialog>
          {/* <div className={styles.hint}>
            {label && <span className={styles.hintLabel}>{label}</span>}
            <span>{topHint}</span>
            {fail_reason && <div>未通过原因：{fail_reason}</div>}
          </div> */}
          {/* <div className={styles.headInfo}>
            {source && source !== 'OwnResource' && (
              <a href={sourceUrl}>
                <div className={styles.source}>来源:{source}</div>
              </a>
            )}
            <div className={styles.time}>上次更新时间：{updateTime}</div>
          </div> */}
          {cate === 'jkb_article' && (
            <div className={styles.scienceTitle}>
              主题日名称
              <span className={styles.activityName}>{this.paramStore.title}</span>
            </div>
          )}
          {cate !== 'sc_topic' && (
            <div className={styles.block}>
              <Textarea
                name="title"
                required
                title="编辑标题"
                value={title}
                maxLength={20}
                placeholder="请输入标题..."
                onChange={this.onTitleChange}
                onBlur={this.handleBlur}
              />
            </div>
          )}
          {/* 新建科普议题 标题部分 当进行编辑时标题不可选 */}
          {cate === 'sc_topic' && (
            <div className={"select-box " + styles.selectBox}>
              <div className="top">
                <div className={'left'}>{this.state.id ? '标题' : '选择标题'}</div>
              </div>
              <div className="select">
                {this.state.id ? (
                  <InputItem className={styles.selectBoxTitle} defaultValue={title} label="" editable={false} disabled></InputItem>
                ) : (
                  <Selector
                    getMsg={this.getChildrenMsg}
                    getCur={this.getCurTopic}
                    tplList={tplList}
                    initObj={initData}
                  ></Selector>
                )}
              </div>
            </div>
          )}
          {cate === 'ask' && (
            <div className="questionEdit">
              <Textarea
                name="question"
                title="编辑问题描述"
                value={question}
                onChange={this.onQuestionChange}
                onBlur={this.handleBlur}
              />
            </div>
          )}
          {file && file.name && (
            <div className={styles.block}>
              <div className={styles.head}>
                <div className={styles.title}>文件内容</div>
              </div>
              <a className={styles.fileContainer} href={file.url}>
                <div className={styles.fileIcon} />
                <div className={styles.fileName}>{file.name}</div>
              </a>
            </div>
          )}
          {video && (
            <div className={styles.block}>
              <div className={styles.hint}>视频资源暂不可编辑</div>
              <video className={styles.video} controls src={video.src} poster={video.poster} />
            </div>
          )}
          {content !== null && cate !== 'file' && (
            <div className={`${styles.block} ${styles.richText}`} onBlur={this.handleBlur}>
              <div className={styles.head}>
                <div
                  className={cx({
                    title: true,
                    required: cate !== 'video'
                  })}
                >
                  {/* 编辑{cate === 'ask' ? '回答' : '内容'} */}
                  编辑正文
                </div>
              </div>
              <ReactQuill
                value={content}
                placeholder={
                  this.state.cate === 'jkb_note'
                    ? '以故事的形式:\n1.记录诊疗过程\n2.记录出诊的一次经历\n3.记录有所感触的病例分析…'
                    : '请输入正文内容'
                }
                modules={this.quillEditorModules}
                formats={this.quillEditorFormats}
                onChange={this.onContentChange}
                ref={this.reactQuillRef}
              />
            </div>
          )}
          {cate === 'jkb_article' && (
            <div className={styles.scienceBtn} onClick={this.onPublishClick}>
              确认
            </div>
          )}
          {cate !== 'jkb_article' && state === 2 && (
            <div className={styles.actionGroup}>
              <div className={cx('actionButton', 'diabledButton')}>审核中</div>
            </div>
          )}
          {cate !== 'jkb_article' && state !== 2 && (
            <div className={styles.actionGroup}>
              <div
                className={styles.actionButton}
                onClick={() => {
                 // window.TEA('新建图文', 'preview');
                  this.onPreview();
                }}
              >
                预览
              </div>
              <div
                className={styles.actionButton}
                onClick={() => {
                  //window.TEA('新建图文', 'save_draft');
                  this.onSaveClick();
                }}
              >
                存草稿
              </div>
              <div
                className={styles.actionButton}
                onClick={() => {
                  //window.TEA('新建图文', 'submit_for_review');
                  this.onPublishClick();
                }}
              >
                提交审核
              </div>
            </div>
          )}
        </div>
      );
    }
    return null;
  }
}
const ContentEditorl = createForm()(ContentEditor);
export default ContentEditorl;
