import axios from '@/utils/interceptors';
import { shareApi } from '@/config/conf';

class WechatService {
  public getTicket(url) {
    return new Promise((resolve, reject) => {
      axios.get(
        `${shareApi}wx_ticket?url=${url}`,
      )
        .then((res) => {
          console.log(res);
          
          if (res.data.code === 0 && res.data?.data) {
            resolve(res.data.data);
          } else {
            resolve(undefined);
          }
        })
        .catch((err) => {
          reject(err);
        })
    })
  }
}

export default WechatService;
