import React, { Component } from 'react';
import { observer } from 'mobx-react';
import DoctorInfoService from '@/services/doctorInfoService';
import HomeStore from '@/stores/homeStore';
import warnicon from '@/static/images/warning.png';
import waiticon from '@/static/images/waiting.png';
import successicon from '@/static/images/success.png';
import { downloadApp } from './utils';
import styles from './index.module.css';

@observer
class Home extends Component {
  service: DoctorInfoService;

  store: HomeStore;

  name = '医生';

  constructor(props: Readonly<{}>) {
    super(props);
    this.service = new DoctorInfoService();
    this.store = new HomeStore(this.service);
  }
  // componentDidMount() {
  //   document.title = '授权开通';
  // };

  UNSAFE_componentWillMount(){
    // window.TEA('主页', 'banner');
  }
  
  render() {
    const message = {
      1: {
        icon: warnicon,
        title: '未授权',
        message: '未授权',
        btn: '未授权'
      },
      4: {
        icon: warnicon,
        title: '审核未通过',
        message: '很遗憾，您的小荷医生账号未能成功开通，详情请咨询公众号客服。',
        btn: '知道了'
      },
      3: {
        icon:successicon,
        title: '审核通过',
        message: '您的小荷医生账号已开通，可立即下载小荷医生App开始线上问诊。',
        btn: '立即下载'
      },
      2: {
        icon: waiticon,
        title: '申请审核中',
        message: '您的开通申请正在审核中，预计需要1-3个工作日，审核结果将以短信方式通知您，请耐心等待！',
        btn: '关闭'
      }
    };
    const { doctor, doctorFlag, setDoctorFlag } = this.store;
    if(doctor) {
      const authstate = doctor.auth_to_xiaohe.toString();
      if(authstate!=='1'){
        return (   
          <div className={styles.card}>
            <img className={styles.icon} src={message[authstate].icon} alt=""/>
            <div className={styles.title}>{message[authstate].title}</div>
            <div className={styles.message}>{message[authstate].message}</div>
            <div className={styles.btn}
              onClick={()=>{
                if (message[authstate].btn === '立即下载') {
                  downloadApp();
                } else {
                  window.location.href = '/';
                }
              }}
            >{message[authstate].btn}</div>
          </div>
        );
      }
    }
    return null;
  };
};

export default Home;
