import { RouteConfig } from 'react-router-config';
import Home from '../pages/home';
import Login from '../pages/login';
import Schedule from '../pages/schedule';
import Preview from '../pages/preview';
import Error from '../pages/error';
import ContentManage from '../pages/content/manage';
import ContentEditor from '../pages/content/editor';
import Score from '../pages/score';
import VoiceAsk from '../pages/voiceAsk';
import VoiceRecorder from '../pages/voiceAsk/recorder';
import Share from '../pages/share/index';
import Video from '../pages/video/index';
import VideoDetial from '../pages/video/detial';
import Doctor from '../pages/doctor/index';
import Card from '../pages/edit/card';
import Openxh from '../pages/openxh/index';
import Openres from '../pages/openres/index';
import Grow from "../pages/grow";
import SignatureList from '../pages/sigList'
import Activity from "../pages/activity"
import CommitActivity from "@/pages/activity/commit";

import App from '../App';

const routes: RouteConfig[] = [
  {
    component: App,
    routes: [
      {
        path: '/',
        exact: true,
        component: Home
      },
      {
        path: '/login',
        component: Login
      },
      {
        path: '/edit',
        component: Card
      },

      {
        path: '/schedule',
        component: Schedule
      },
      {
        path: '/preview/:cate/:id',
        component: Preview
      },

      {
        path: '/error',
        component: Error
      },
      {
        path: '/content/manage',
        component: ContentManage
      },
      {
        path: '/content/editor/:cate/:id?',
        component: ContentEditor
      },
      {
        path: '/score',
        component: Score
      },
      {
        path: '/voice-ask',
        component: VoiceAsk
      },
      {
        path: '/voice-recorder/:id',
        component: VoiceRecorder
      },
      {
        path: '/share/:id',
        component: Share
      },
      {
        path: '/video/:id?',
        component: Video
      },
      {
        path: '/video-detial',
        component: VideoDetial
      },
      {
        path: '/doctor',
        component: Doctor
      },
      {
        path: '/openxh',
        component: Openxh
      },
      {
        path: '/openres',
        component: Openres
      },
      {
        path: '/grow',
        component: Grow,
      },
      {
        path:'/siglist',
        component:SignatureList
      },
      {
        path: '/activity',
        component: Activity,
      },
      {
        path: '/commitactivity',
        component: CommitActivity,
      },
    ]
  }
];

export default routes;
