import React, {Component} from 'react';
import {Modal} from "antd-mobile";
import { Toast, WhiteSpace, WingBlank, Button } from 'antd-mobile';
import {RouteComponentProps} from "react-router-dom";
// import styles from "./index.module.css"
import header_bg from "../../static/images/poster_header.png"
import Util from '../../../utils/util'
import DoctorInfoService from '@/services/doctorInfoService';
import McnStore from "@/stores/mcnStore";
import HomeStore from "@/stores/homeStore";
import {observer} from "mobx-react";
import styles from './index.module.css'
@observer
class Card extends Component<any, {cur_statu:number,doctor_id:string}> {
service: DoctorInfoService;
  constructor(props) {
    super(props);
    this.service = new DoctorInfoService();
    this.state = {
        cur_statu:0,
        doctor_id:''
    }
  }
      async componentDidMount()  {
            const{info} = this.props;
            const doctor_id = localStorage.getItem('doctor_id')||'';
            this.setState({
                doctor_id
            })
            const {status} = await this.service.getSignState(info.contract_code,doctor_id)||'';
            this.setState({
                cur_statu:status
            })     
      } 
      toDetail= (info) => {
          console.log(info);
          
      }
      toSign = async ()=>{
        //跳转
        console.log(1234);
        try{
          const {sign_url} = await this.service.commitSign(this.state.doctor_id)||'';
          this.setState({
          
        },()=>{
          window.open(sign_url,'_parent')
        }) 
        }catch(err){
          console.log('err',err,'err');
          if(err.code === 50000 ){
            this.setState({
                  
                })
          }else{
            const msg = err.msg;
            Toast.fail(msg, 1);
          }
          
        }    
}
      nextStep = async (item,statu) => {
        console.log(item);
       // 签约状态 1.未签约 2.已签约 3.已拒签
       // 未签约已经拒签都去签约 
       if(statu === 1 || statu === 3){
           this.toSign()
        //  const {sign_url} =await this.service.commitSign(this.state.doctor_id)
        //  window.open(sign_url,'_parent')
       }else{
         //查看合同 去新页面
         const download_url = item.download_url
         const a = document.createElement('a'); // 创建a标签
         a.setAttribute('download', '');// download属性
         a.setAttribute('href', download_url);// href链接
         a.click();// 自执行点击事件
       }

       
   }
  render() {
    const {info,nextStep} = this.props;
    const {cur_statu} = this.state;
    return(
        // 签约状态 1.未签约 2.已签约 3.已拒签
        <div className={styles.card} onClick={this.props.onClick}>
        <div className={styles.cardHeader}>
            <div className={styles.title}>{info.contract_name}</div>
            <div className={styles.statu}>{this.service.coverStatus(cur_statu)}</div>
        </div>
        <div className={styles.cardBody}>
            <div className={styles.item}>
                <div className={styles.label}>合同号 ： </div>
                <div className="content">{info.contract_code}</div>
            </div>
            <div className={styles.item}>
                <div className={styles.label}>签约主体 ： </div>
                <div className="content">{info.contract_subject}</div>
            </div>
            {
                cur_statu!==1&&
                <div className={styles.item}>
                    <div className={styles.label}>{cur_statu===2?"签约日期 ：":"拒签日期 ："} </div>
                    <div className="content">{info.sign_date}</div>
                </div>
            }
             {
                 cur_statu === 3 && (
                    <div className={styles.item}>
                        <div className={styles.label}>拒签原因 ：</div>
                        <div className="content">{info.refuse_reason}</div>
                    </div>
                 )
             }
        </div>
        <div className={styles.cardFooter}>
            {/* 签约状态 1.未签约 2.已签约 3.已拒签 */}
            {
                cur_statu===1 && (
                    <div className={styles.btnB} onClick={Util.debouncePrev(()=>this.nextStep(info,cur_statu),1500)}>去签约</div>
                )
            }
            {
                cur_statu===2 && (
                    <div className={styles.btnB} onClick={Util.debouncePrev(()=>this.nextStep(info,cur_statu),1500)}>查看合同</div>
                )
            }
            {
                cur_statu===3 && (
                    <div className={styles.btnB} onClick={Util.debouncePrev(()=>this.nextStep(info,cur_statu),1500)}>重新签署</div>
                )
            }
            {/* <div className={styles.btnB}>去签约</div> */}
        </div>
      
    </div>
    )


  }
}

export default  Card;
