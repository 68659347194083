import { observable, action } from 'mobx';
import Doctor from '../types/doctor';
import ActivityService from "@/services/activityService";

class ActivityStore {
  service: ActivityService;

  // @observable
  // public isMcn: string | null = localStorage.getItem("isMcn");

  @observable
  public activityList: any = [] ;

  @observable
  public finishActivityList: any = [];

  @observable
  public activityInfo: any = {};

  @observable
  public uploadImgUrl: any;

  constructor(service: ActivityService) {
    this.service = service
  }

  @action
  participateActivity = async (params) => {
    let res = await this.service.joinActivity(params);
    if(res.data && res.data.code === 0){
      return true
    } else {
      return false
    }
  }

  @action
  publicArticle = async (id) => {
    let res = await this.service.changeContentsStatus(id, 'publish', '');
    if(res.data && res.data.code === 0){
      return true
    } else {
      return false
    }
  }

  @action
  upload(formData: FormData) {
    this.service
      .uploadImage(formData)
      .then((res) => {
        if (
          res.data &&
          res.data.code === 200 &&
          res.data?.data?.url
        ) {
          this.uploadImgUrl = res.data.data.url;
        }
        console.log(res);
      })
      .catch((err) => {
        console.log('12312312312312', err);
      });
  }

  @action
  getActivityInfo(id): void{
    const params = {
      id
    }
    this.service.getActivityDetail(params).then(res => {
      if(res.data && res.data.code === 0){
        this.activityInfo = res.data?.data?.data
      }
    }).catch()
  }

  @action
  getActiveList(): void{
    const params = {
      page_size: 10000,
    }
    this.service.getActivityList(params).then(res => {
      if(res.data && res.data.code === 0) {
        if(!res.data?.data?.data.list){
          res.data.data.data.list = [];
        }
        const list = [...res.data.data.data.list];
        list.sort(((a, b) => {
          return a.start_time - b.start_time;
        }))
        const activeList = [];
        const finishList = [];
        const currDate = new Date(new Date(new Date().toLocaleDateString()).getTime());  //获取当天0点时间戳
        const currentTimestamp = currDate.getTime();
        list.forEach(item => {
          if(currentTimestamp > item.start_time * 1000) {
            // @ts-ignore
            finishList.unshift(item);
          } else {
            // @ts-ignore
            activeList.unshift(item)
          }
        })
        let activeResList = []
        let currList = [];
        let currYear = currDate.getFullYear();
        let currMonth = currDate.getMonth();
        if(!activeList.length){
          const resObj = {
            date: [currYear, currMonth],
            list: [],
          }
          // @ts-ignore
          activeResList = [resObj]
        } else {
          while(activeList.length){
            currList = [];
            for(let i = 0;i<activeList.length;){
              // @ts-ignore
              const itemDate = new Date(activeList[i].start_time * 1000);
              const itemYear = itemDate.getFullYear();
              const itemMonth = itemDate.getMonth();
              if(itemYear === currYear && itemMonth === currMonth){
                currList.push(activeList[i]);
                activeList.splice(i, 1);
              } else {
                i++;
              }
            }
            // @ts-ignore
            const resObj = {
              list: [...currList],
              date: [currYear, currMonth],
            }
            // @ts-ignore
            activeResList.push(resObj)
            if(currMonth === 11){
              currMonth = 0;
              currYear += 1;
            }else{
              currMonth += 1;
            }
          }
        }
        let finishResList = [];
        currYear = currDate.getFullYear();
        currMonth = currDate.getMonth();
        if(!finishList.length){
          const resObj = {
            date: [currYear, currMonth],
            list: [],
          }
          // @ts-ignore
          finishResList = [resObj]
        } else {
          while(finishList.length){
            currList = [];
            for(let i = 0;i<finishList.length;){
              // @ts-ignore
              const itemDate = new Date(finishList[i].start_time * 1000);
              const itemYear = itemDate.getFullYear();
              const itemMonth = itemDate.getMonth();
              if(itemYear === currYear && itemMonth === currMonth){
                currList.push(finishList[i]);
                finishList.splice(i, 1);
              } else {
                i++;
              }
            }
            const resObj = {
              list: [...currList],
              date: [currYear, currMonth],
            }
            // @ts-ignore
            finishResList.push(resObj)
            if(currMonth === 0){
              currMonth = 11;
              currYear -= 1;
            }else{
              currMonth -= 1;
            }
          }
        }
        this.activityList = [...activeResList];
        this.finishActivityList = [...finishResList]

      }
    }).catch()
  }

}

export default ActivityStore
