import axios from '@/utils/interceptors';
import { APIPath, uploadAPIPath } from '@/config/conf';
import CryptoJS from 'crypto-js';
import {isArray} from "lodash";

const PaddingLeft = (key: any, length: any) => {
  let pkey = key.toString();
  const l = pkey.length;
  if (l < length) {
    pkey = new Array(length - l + 1).join('0') + pkey;
  } else if (l > length) {
    pkey = pkey.slice(length);
  }
  return pkey;
};

const aseEncrypt = (msg: any, key: any) => {
  // key = PaddingLeft(key, 16); // 保证key的长度为16byte,进行'0'补位
  // key = CryptoJS.enc.Utf8.parse(PaddingLeft(key, 16));
  const key0 = CryptoJS.enc.Utf8.parse(PaddingLeft(PaddingLeft(key, 16), 16));
  const encrypted = CryptoJS.AES.encrypt(msg, key0, {
    iv: key0,
    mode: CryptoJS.mode.CBC, // CBC算法
    padding: CryptoJS.pad.Pkcs7 // 使用pkcs7 进行padding 后端需要注意
  });
  return encrypted.ciphertext.toString(CryptoJS.enc.Hex); // 后端必须进行相反操作
};


const getToken = () => {
  const timestamp = Math.round(new Date().getTime() / 1000);
  const msg = `${timestamp};e22674779c464244`;
  return aseEncrypt(msg, '186133958051');
};

class ActivityService {
  getActivityList = (params):Promise<any> => {
    return new Promise((resolve, reject) => {
      axios.get(`${APIPath}doctorauth/activity_list`, {
        params
      }).then(res => {
        resolve(res)
      }).catch(ex => {
        reject(ex)
      })
    })
  }

  getActivityDetail = (params):Promise<any> => {
    return new Promise(((resolve, reject) => {
      axios.get(`${APIPath}doctorauth/activity_detail`,{
        params
      }).then(res => {
        resolve(res);
      }).catch(ex => {
        reject(ex)
      })
    }))
  }

  uploadImage = (formData: FormData) => {
    return axios.post(`${uploadAPIPath}mobile/upload/image`, formData);
  };

  joinActivity = (params):Promise<any> => {
    return new Promise((resolve, reject) => {
      axios.post(`${APIPath}doctorauth/add_activity`, params).then(res => {
        resolve(res);
      }).catch(ex => {
        reject(ex)
      })
    })
  };

  changeContentsStatus = (id: string | string[], action: string, doc_id: string) => {
    let idList;
    if(id){
      idList = id;
      if (!isArray(id)) {
        idList = [id];
      }
    } else {
      idList = [doc_id];
    }
    return axios.put(
      `${APIPath}doctorauth/docinfo`,
      JSON.stringify({
        action,
        uid_list: idList,
        login_source: 9
      }),
      {
        headers: { 'Content-Type': 'application/json' }
      }
    );
  };
}

export default ActivityService
